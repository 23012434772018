import React, { useEffect } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isFinite';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import RenderRangeField from '../RenderRangeField';
import styles from '../../allocations.scss';
import { normalizeMinValue, normalizeMaxValue } from '../normalizers';

interface IProps {
  fieldComponent: React.ElementType,
  onChangeRange: (filterByName: string, value: any) => void,
}

const multiplyByMillion = (value) => {
  const multipliedValue = Math.round(value * 1000000);
  if (isNumber(multipliedValue)) {
    return multipliedValue;
  }
  return value;
};

const divideOnMillion = (value) => {
  const dividedValue = Math.round(value / 1000000);
  if (isNumber(dividedValue)) {
    return dividedValue;
  }
  return value;
};

const filterByName = 'allocationRange';

const Allocation = ({
  fieldComponent: Field,
  onChangeRange,
}: IProps) => {
  const { allocationFilterRange, allocationFiltersPayload } = useGlobalAnalyticsFiltersContext();

  useEffect(() => {
    // @ts-ignore
    if (!allocationFiltersPayload?.allocationFrom && !allocationFiltersPayload?.allocationTo) {
      onChangeRange(filterByName, normalizeValue);
    }
  }, [allocationFilterRange?.range]);

  if (isEmpty(allocationFilterRange?.range)) {
    return null;
  }

  const normalizeValue = {
    range: {
      min: normalizeMinValue(allocationFilterRange?.range.min),
      max: normalizeMaxValue(allocationFilterRange?.range.max),
    },
    minValue: normalizeMinValue(allocationFilterRange?.range.min),
    maxValue: normalizeMaxValue(allocationFilterRange?.range.max),
  };

  const initialValue = {
    range: {
      min: allocationFilterRange?.range.min,
      max: allocationFilterRange?.range.max,
    },
    minValue: allocationFilterRange?.range.min,
    maxValue: allocationFilterRange?.range.max,
  };

  return (
    <>
      <div className={ cn(styles.fieldLabel, styles.firstField) }>Allocation size (M)</div>
      <Field
        name={ filterByName }
        disabled={ !allocationFilterRange?.range.canChangeResponse }
        render={ RenderRangeField }
        minValue={ normalizeMinValue(divideOnMillion(initialValue.minValue)) }
        maxValue={ normalizeMaxValue(divideOnMillion(initialValue.maxValue)) }
        step={ 1 }
        format={ ({ range = {}, ...rest }) => ({
          ...rest,
          range: {
            // @ts-ignore
            min: range?.min || range?.min === 0 ? divideOnMillion(range?.min) : 0,
            // @ts-ignore
            max: range?.max || range?.max === 0 ? divideOnMillion(range?.max) : 0,
          },
        }) }
        parse={ (value) => ({
          range: {
            min: value?.min || value?.min === 0 ? multiplyByMillion(value?.min) : null,
            max: value?.max || value?.max === 0 ? multiplyByMillion(value?.max) : null,
          },
          minValue: multiplyByMillion(value?.minValue),
          maxValue: multiplyByMillion(value?.maxValue),
        }) }
      />
    </>
  );
};

export default Allocation;
