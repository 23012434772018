import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';

@Dependencies(RpcDispatcher)
class BlacklistRepository {
  constructor(rpcDispatcher) {
    /** @type {Dispatcher} */
    this.rpc = rpcDispatcher;
  }

  /**
   * @return {Promise.<*>}
   */
  getAdminApplicationBlacklist = async (params) => {
    let req = new Request('admin.roadshow.get_application_blacklist_list', params);
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  };

  /**
   * @return {Promise.<*>}
   */
  addAdminApplicationBlacklist = async (params) => {
    let req = new Request('admin.roadshow.add_application_blacklist_list', { blacklist: params });
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  };

  /**
   * @return {Promise.<*>}
   */
  toggleActionAdminApplicationBlacklist = async (params) => {
    let req = new Request('admin.roadshow.toggle_application_blacklist_actions', params);
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  };

  getApplicationIsBlacklisted = async () => {
    let req = new Request('dm_portal.queries.is_email_blacklisted');
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  };
}

export default BlacklistRepository;
