import { connect } from 'react-redux';
import { UploadPreview } from '../components';
import * as constants from '../constants';
import { uploaDisclaimerdLogo, uploadDisclaimerLogoCancel } from '../actions';

function mapStateToProps(state) {
  return {
    uploadFile: state.upload.disclaimerLogo,
    formFieldName: 'disclaimerLogo',
    uploadName: 'logo',
    allowedFileTypes: constants.UPLOAD_LOGO_ALLOWED_FILES,
    allowedFileText: constants.UPLOAD_LOGO_ALLOWED_FILES_TEXT,
  };
}

const mapDispatchToProps = {
  onUpload: uploaDisclaimerdLogo,
  onUploadCancel: uploadDisclaimerLogoCancel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadPreview);
