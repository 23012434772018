import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';

@Dependencies(RpcDispatcher)
class AccountsRepository {
  constructor(private readonly rpc: typeof RpcDispatcher) {
  }

  async requestToCreateAccount(payload: {
    id: number | string,
    suggestNames?: string[],
    suggestFor?: string,
    name: string,
    tenant: string,
  }) {
    const req = new Request('dm_portal.request_to_create_account', payload);
    const response = await this.rpc.call<RpcSuccess>(req);
    return response.getResult();
  }
}

export default AccountsRepository;
