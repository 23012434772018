import React from 'react';
import { startOfDay } from 'date-fns';
import SharedFiltersBtn from '@/ui/shared/modules/Filters/FiltersPanel/FiltersBtn';
import { getFiltersByKeysWithValues } from '@/ui/shared/modules/Filters/helpers';
import { filtersKeys } from '@/dmPortal/application/GlobalAnalytics/filters/constants';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import { GLOBAL_ANALYTICS_TIMEZONE } from '@/dmPortal/application/GlobalAnalytics/config';

interface IProps {
  onClick: () => void,
}

const FiltersBtn = ({ onClick }: IProps) => {
  const { appliedFilters, firstLoginAt } = useGlobalAnalyticsFiltersContext();

  const dateFromMin =
    firstLoginAt && getUnixTimestamp(startOfDay(utcToZonedDate(GLOBAL_ANALYTICS_TIMEZONE, firstLoginAt)));

  const filtersCount = Object.keys(getFiltersByKeysWithValues(appliedFilters, filtersKeys, dateFromMin)).length;

  return <SharedFiltersBtn filtersCount={ filtersCount } onClick={ onClick } />;
};

export default FiltersBtn;
