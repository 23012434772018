import React, { useState, createContext, useContext, ReactNode } from 'react';

interface IProps {
  children: ReactNode,
}

const useRoadshowLayout = () => {
  const [tabMenuContent, setTabMenuContent] = useState<ReactNode>(null);

  return {
    tabMenuContent,
    setTabMenuContent,
  };
};

type RoadshowLayoutContextType = ReturnType<typeof useRoadshowLayout>;

export const RoadshowLayout = createContext(null);

export const useRoadshowLayoutContext = (): RoadshowLayoutContextType => {
  const context = useContext(RoadshowLayout);
  if (!context) {
    throw new Error('useRoadshowLayoutContext must be used within a RoadshowLayout');
  }
  return context;
};

const RoadshowLayoutProvider = ({ children }: IProps) => {
  return <RoadshowLayout.Provider value={ useRoadshowLayout() }>{ children }</RoadshowLayout.Provider>;
};

export default RoadshowLayoutProvider;
