export const CURRENT_FACE_RANGE_PRESET_FILTER_NAME = 'currentFaceRangeIds';

export const CURRENT_FACE_RANGE_PRESET = [
  {
    currentFaceRangeId: 'CURRENT_FACE_RANGE_ID_1',
    currentFaceRangeName: '< $250,000',
    currentFaceRangeFromValue: null,
    currentFaceRangeToValue: 249999,
  },
  {
    currentFaceRangeId: 'CURRENT_FACE_RANGE_ID_2',
    currentFaceRangeName: '$250,000 - $999,999',
    currentFaceRangeFromValue: 250000,
    currentFaceRangeToValue: 999999,
  },
  {
    currentFaceRangeId: 'CURRENT_FACE_RANGE_ID_3',
    currentFaceRangeName: '$1,000,000 - $4,999,999',
    currentFaceRangeFromValue: 1000000,
    currentFaceRangeToValue: 4999999,
  },
  {
    currentFaceRangeId: 'CURRENT_FACE_RANGE_ID_4',
    currentFaceRangeName: '$5,000,000 - $9,999,999',
    currentFaceRangeFromValue: 5000000,
    currentFaceRangeToValue: 9999999,
  },
  {
    currentFaceRangeId: 'CURRENT_FACE_RANGE_ID_5',
    currentFaceRangeName: '> $10,000,000',
    currentFaceRangeFromValue: 10000000,
    currentFaceRangeToValue: null,
  },
];

export const filtersKeys = [
  'includedStatus',
  'includedSubsectorIds',
  'includedIssuerIds',
  'includedDealerIds',
  'currentFaceRange',
  'currentFaceRangeIds',
  'walRange',
  'includedRatingGroupIds',
  'includedRatingAgencyIds',
  'includedBenchmarkGroupIds',
  'spreadRange',
  'includedSpeedTypeIds',
  'speedRange',
  'dates',
];

export const allowedToRemoveFilters = [
  'includedStatus',
  'includedSubsectorIds',
  'includedIssuerIds',
  'includedDealerIds',
  'currentFaceRange',
  'currentFaceRangeIds',
  'walRange',
  'includedRatingGroupIds',
  'includedRatingAgencyIds',
  'includedBenchmarkGroupIds',
  'spreadRange',
  'includedSpeedTypeIds',
  'speedRange',
  'dates',
];
