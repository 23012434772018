import { fieldsArrayValidator } from './helpers';
import { IRule } from './interfaces/Rule';
import { IValidationError } from './interfaces/ValidationError';

export const validateFieldsArrayForFinalForm = (
  fieldName: string,
  rules: IRule[],
  fields: { collection: any[] },
): { [key: number]: IValidationError[] } => {
  if (!fields || !fields.collection || !fields.collection.length) {
    return null;
  }

  const membersArrayErrors = fieldsArrayValidator(rules, fields);
  if (membersArrayErrors.length) {
    return { [fieldName]: membersArrayErrors };
  }
  return null;
};
