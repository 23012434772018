import { ICollectionItem, IInitialCollectionItem, IMappedLei } from '@/allocate/domain/vo/Allocations/Upload/LinkAccounts';

export const transformInitialCollection = (
  collection: IInitialCollectionItem[],
): ICollectionItem[] => collection.map((item) => {
  const { lei, accounts, mappedAccountId } = item;
  let mappedAccount = null;
  let isAutoSelected = false;

  if (mappedAccountId) {
    const account = accounts.find((account) => account.id === mappedAccountId);
    if (account) {
      mappedAccount = {
        id: account.id,
        name: account.name,
      };
    }
  } else if (!mappedAccountId && accounts.length === 1) {
    const [account] = accounts;
    isAutoSelected = true;
    mappedAccount = {
      id: account.id,
      name: account.name,
    };
  }

  return {
    lei,
    accounts,
    mappedAccount,
    isAutoSelected,
  };
});

export const transformCollectionForMapping = (
  collection: ICollectionItem[],
): IMappedLei[] => collection.map((item) => {
  const { lei, mappedAccount } = item;

  return {
    leiName: lei.name,
    accountId: mappedAccount ? mappedAccount.id : null,
  };
});
