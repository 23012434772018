import isEmpty from 'lodash/isEmpty';
import { IReducerState, FilterRangeType } from './types';

const isRangeFilterEmpty = ({ min, max }: FilterRangeType['range']) => max === 0 && min === 0;

export const getTransactionTypeFilterList = (state: IReducerState) => state.filter.transactionTypeFilterList;
export const getIndustryFilterList = (state: IReducerState) => state.filter.industryFilterList;
export const getSubIndustryFilterList = (state: IReducerState) => state.filter.subIndustryFilterList;
export const getAbsSectorFilterList = (state: IReducerState) => state.filter.absSectorFilterList;
export const getAbsSubSectorFilterList = (state: IReducerState) => state.filter.absSubSectorFilterList;
export const getAccountTypeFilterList = (state: IReducerState) => state.filter.accountTypeFilterList;
export const getAccountFilterList = (state: IReducerState) => state.filter.accountFilterList;
export const getCurrencyFilterList = (state: IReducerState) => state.filter.currencyFilterList;
export const getRatingGroupFilterList = (state: IReducerState) => state.filter.ratingGroupFilterList;
export const getRatingAgencyFilterList = (state: IReducerState) => state.filter.ratingAgencyFilterList;
export const getDebtCouponTypeFilterList = (state: IReducerState) => state.filter.debtCouponTypeFilterList;
export const getSponsorFilterList = (state: IReducerState) => state.filter.sponsorFilterList;
export const getUnderwriterFilterList = (state: IReducerState) => state.filter.underwriterFilterList;
export const getAllocationFilterRange = (state: IReducerState) => state.filter.allocationFilterRange;
export const getSpreadFilterRange = (state: IReducerState) => state.filter.spreadFilterRange;
export const getTenorWalYearsFilterRange = (state: IReducerState) => state.filter.tenorWalYearsFilterRange;
export const getYieldFilterRange = (state: IReducerState) => state.filter.yieldFilterRange;
export const getDebtUpsizedFilter = (state: IReducerState) => state.filter.debtUpsizedFilter;

export const getFirstLoginAt = (state: IReducerState) => state.firstLoginAt;

export const isFetching = (state: IReducerState) => state.isFetching;

export const getAppliedFilters = (state: IReducerState) => state.appliedFilters;

export const getFiltersPayload = (state: IReducerState) => state.filtersPayload;

export const getAllocationFiltersPayload = (state: IReducerState) => state.allocationFiltersPayload;
export const isAccountFetching = (state: IReducerState) => state.isAccountFetching;
export const isSponsorFetching = (state: IReducerState) => state.isSponsorFetching;
export const isUnderwritersFetching = (state: IReducerState) => state.isUnderwritersFetching;

export const isAllocationZerocase = (state: IReducerState) => isEmpty(getCurrencyFilterList(state).allIds)
    && isEmpty(getRatingGroupFilterList(state).allIds)
    && isEmpty(getRatingAgencyFilterList(state).allIds)
    && isEmpty(getDebtCouponTypeFilterList(state).allIds)
    && isRangeFilterEmpty(getAllocationFilterRange(state).range)
    && isRangeFilterEmpty(getSpreadFilterRange(state).range)
    && isRangeFilterEmpty(getTenorWalYearsFilterRange(state).range)
    && isRangeFilterEmpty(getYieldFilterRange(state).range);
