import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, Field } from 'redux-form';
import { UploadTable } from '../components';
import * as constants from '../constants';
import {
  uploadDocuments,
  uploadDocumentCancel,
  uploadDocumentOrder,
} from '../actions';

import {
  setCustomDisclaimer,
  setDisclaimer,
  setViewOnly,
} from '@/dealroadshow/application/actions/roadshowForm/dealDocuments/actions';

const selector = formValueSelector('roadshowForm');

function mapStateToProps(state) {
  return {
    fieldComponent: Field,
    uploadFiles: state.upload.documents,
    uploadList: selector(state, 'uploadDocumentsList'),
    formFieldName: 'uploadDocumentsList',
    columns: constants.UPLOAD_TABLE_COLUMNS,
    widths: constants.UPLOAD_TABLE_WIDTHS,
    supportsDisclaimer: true,
    allowedFileTypes: constants.UPLOAD_DOCUMENTS_ALLOWED_FILES,
    viewOnlyFileTypes: constants.UPLOAD_VIEW_ONLY_ALLOWED_FILES,
    allowedFileText: constants.UPLOAD_DOCUMENTS_ALLOWED_FILES_TEXT,
    uploadName: 'deal files',
    limit: 20,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onUpload: uploadDocuments,
    onUploadCancel: uploadDocumentCancel,
    onUploadOrder: uploadDocumentOrder,
    setCustomDisclaimer,
    setDisclaimer,
    setViewOnly,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadTable);
