import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from '@/ui/shared/components/Next/Link';
import useRouter from '@/ui/shared/hooks/useNextRouter';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import { DataTable, Icon, IconType, Spinner, Tooltip } from '@dealroadshow/uikit';
import SelectAccount from '@/allocate/ui/components/shared/Upload/LinkAccounts/SelectAccount';
import LinkAccountModals from '@/allocate/ui/components/shared/Upload/LinkAccounts/LinkAccountModals';
import StepWrapper from '@/allocate/ui/components/shared/Upload/StepWrapper';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import { getRoadshowName } from '@/dealroadshow/application/actions/analytics/metadata/selectors';
import useLinkAccounts from '@/allocate/ui/components/shared/Upload/LinkAccounts/useLinkAccounts';
import useLinkAccountModals from '@/allocate/ui/components/shared/Upload/LinkAccounts/LinkAccountModals/useLinkAccountModals';
import { steps, getCancelEditingUrl, getUploadUrl } from '@/allocate/application/Allocations/Upload/config';
import { tenantDealroadshow } from '@/allocate/application/config';
import styles from '@/allocate/ui/components/shared/Upload/LinkAccounts/linkAccounts.scss';

const LinkAccounts = () => {
  const { query: { from } } = useRouter();
  const roadshowName = useSelector(getRoadshowName);

  const {
    tenant,
    isEditing,
    dealAllocationId,
    linkedRoadshowId,
    checkForUploadedAllocations,
  } = useUploadContext();

  const {
    isFetching,
    isMobile,
    sortOrder,
    handleSortChange,
    leisCollection,
    createAccountForLeiMapping,
    isAllocationsImporting,
    setIsAllocationsImporting,
    mapAllocations,
    getCompaniesList,
    companiesList,
    handleLinkAccount,
  } = useLinkAccounts();

  const {
    linkAccountModalSelectedAccount,
    setLinkAccountModalSelectedAccount,
    linkAccountModalSelectedLei,
    isImportModalVisible,
    setIsImportModalVisible,
    isLinkAccountModalVisible,
    handleOpenLinkAccountModal,
    onLinkAccountModalSubmit,
    onLinkAccountModalCancel,
    onImportModalConfirm,
    onImportModalCancel,
  } = useLinkAccountModals(
    leisCollection,
    createAccountForLeiMapping,
    mapAllocations,
    roadshowName,
  );

  const columns = [
    {
      name: 'lei',
      title: 'Account (Orig)',
      width: 300,
      sortable: true,
      cellCallback: (cellProps) => leiCellCallback(cellProps),
    },
    {
      name: 'accounts',
      title: 'Finsight Account',
      cellCallback: (cellProps) => selectCellCallback(cellProps),
    },
  ];

  const leiCellCallback = (cellProps) => (
    <div className={ styles.leiCell }>
      <Tooltip
        containerClassName={ styles.leiName }
        content={ cellProps.cellData.name }
        disabled={ !isMobile }
      >
        { cellProps.cellData.name }
      </Tooltip>
      <span className={ styles.to }>to</span>
    </div>
  );

  const selectCellCallback = (cellProps) => (
    <SelectAccount
      row={ cellProps.row }
      rowIndex={ cellProps.rowIndex }
      linkAccount={ handleLinkAccount }
      showModal={ handleOpenLinkAccountModal }
      getCompaniesList={ getCompaniesList }
      companiesList={ companiesList }
    />
  );

  const onSubmit = async () => {
    if (tenant === tenantDealroadshow) {
      setIsAllocationsImporting(true);
      const hasAllocations = await checkForUploadedAllocations();

      if (hasAllocations) {
        setIsImportModalVisible(true);
      } else {
        mapAllocations(false, roadshowName);
      }
    } else {
      mapAllocations(false);
    }
  };

  const onRemapSubmit = () => {
    mapAllocations(false);
  };

  const submitButtonText = useMemo(() => {
    if (isEditing) {
      return 'Re-map';
    }

    if (tenant === tenantDealroadshow) {
      return 'Import';
    }

    return 'Continue';
  }, [isEditing, tenant]);

  const footerContent = useMemo(() => (
    <>
      { isEditing ? (
        <Link
          to={ getCancelEditingUrl(tenant, from?.toString(), dealAllocationId, linkedRoadshowId) }
        >
          <Button
            variant={ variantTypes.text }
            dataTest="allocationUploadLinkAccountCancelButton"
          >
            Cancel
          </Button>
        </Link>
      ) : (
        <Link to={ getUploadUrl(tenant, steps.confirmAllocations, linkedRoadshowId) }>
          <Button
            variant={ variantTypes.text }
            dataTest="allocationUploadLinkAccountBackButton"
          >
            Back
          </Button>
        </Link>
      ) }
      <Button
        variant={ variantTypes.action }
        onClick={ isEditing ? onRemapSubmit : onSubmit }
        disabled={ isAllocationsImporting }
        dataTest={ isEditing ? 'allocationUploadLinkAccountRemapButton' : 'allocationUploadLinkAccountImportButton' }
      >
        { submitButtonText }
      </Button>
    </>
  ), [leisCollection, isEditing, isAllocationsImporting]);

  return (
    <>
      <StepWrapper
        isFetching={ isFetching }
        footer={ footerContent }
      >
        <div className={ styles.tableHeader }>
          <div className={ styles.title }>
            Link to FINSIGHT Accounts
            <span className={ styles.titleHint }>
              <Tooltip
                content="FINSIGHT maintains a list of LEIs and will suggest corresponding matches to our FINSIGHT Account database for consolidated reporting."
              >
                <Icon type={ IconType.question } />
              </Tooltip>
            </span>
          </div>
        </div>
        <div className={ styles.horizontalScrollContainer }>
          <DataTable
            containerClassName={ styles.tableContainer }
            dataTest="linkAccountsDataTable"
            columns={ columns }
            data={ leisCollection }
            isFetching={ isFetching }
            sortBy="lei"
            sortOrder={ sortOrder }
            onSortChange={ handleSortChange }
            loadingComponent={ Spinner }
            isScrollable
          />
        </div>
      </StepWrapper>
      <LinkAccountModals
        isImportModalVisible={ isImportModalVisible }
        isLinkAccountModalVisible={ isLinkAccountModalVisible }
        linkAccountModalSelectedLei={ linkAccountModalSelectedLei }
        linkAccountModalSelectedAccount={ linkAccountModalSelectedAccount }
        setLinkAccountModalSelectedAccount={ setLinkAccountModalSelectedAccount }
        onLinkAccountModalSubmit={ onLinkAccountModalSubmit }
        onLinkAccountModalCancel={ onLinkAccountModalCancel }
        onImportModalConfirm={ onImportModalConfirm }
        onImportModalCancel={ onImportModalCancel }
        getCompaniesList={ getCompaniesList }
        companiesList={ companiesList }
      />
    </>
  );
};

export default LinkAccounts;
