import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, Field } from 'redux-form';
import { UploadTable } from '../components';
import * as constants from '../constants';
import { uploadCommercialVideo, uploadCommercialVideoCancel, uploadCommercialVideoOrder } from '../actions';

const selector = formValueSelector('roadshowForm');

function mapStateToProps(state) {
  return {
    fieldComponent: Field,
    uploadFiles: state.upload.commercialVideo,
    uploadList: selector(state, 'uploadCommercialVideoList'),
    formFieldName: 'uploadCommercialVideoList',
    columns: constants.UPLOAD_TABLE_COLUMNS,
    fileFieldTitle: 'Video files',
    nameFieldTooltipContent: 'Video name seen by investors',
    widths: constants.UPLOAD_TABLE_WIDTHS,
    allowedFileTypes: constants.UPLOAD_COMMERCIAL_VIDEO_ALLOWED_FILES,
    allowedFileText: constants.UPLOAD_COMMERCIAL_VIDEO_ALLOWED_FILES_TEXT,
    uploadName: 'video media',
    limit: 20,
    maxSize: constants.BYTES_IN_TWO_GIGABYTES,
    maxSizeUnits: 'GB',
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onUpload: uploadCommercialVideo,
    onUploadCancel: uploadCommercialVideoCancel,
    onUploadOrder: uploadCommercialVideoOrder,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadTable);
