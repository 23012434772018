import * as actionTypes from '../actionTypes';
import * as uploadTypes from '../uploadTypes';
import moveElementInArray from '@/Framework/dataHelpers/array/moveElementInArray';
import getFileExtension from '@/Framework/dataHelpers/string/getFileExtension';

const initialState = {
  sort: [],
  files: {},
};

export default function documents(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPLOAD_DOCUMENT_ADD:
      let nameSplit = action.payload.uploadFile.name.split('.');
      const extension = `.${ getFileExtension(action.payload.uploadFile.name) }`;
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.uuid]: {
            initialFile: null,
            process: {
              progress: 0,
              status: uploadTypes.UPLOAD_START,
              uuid: action.payload.uuid,
            },
            uploadFile: action.payload.uploadFile,
            callback: action.payload.callback,
            name: nameSplit.join('.'),
            originalName: action.payload.uploadFile.name,
            extension,
            url: '',
            uploaded: false,
            canSave: false,
            viewOnly: false,
          },
        },
        sort: [
          ...state.sort,
          { uuid: action.payload.uuid },
        ],
      };

    case actionTypes.UPLOAD_DOCUMENT_CHUNK_SUCCESS:
      if (state.files[action.payload.uuid]) {
        return {
          ...state,
          files: {
            ...state.files,
            [action.payload.uuid]: {
              ...state.files[action.payload.uuid],
              process: {
                ...state.files[action.payload.uuid].process,
                progress: parseInt(action.payload.progress),
                status: uploadTypes.UPLOAD_CHUNK_SUCCESS,
              },
            },
          },
        };
      }
      return state;

    case actionTypes.UPLOAD_DOCUMENT_ERROR:
      if (state.files[action.payload.uuid]) {
        return {
          ...state,
          files: {
            ...state.files,
            [action.payload.uuid]: {
              ...state.files[action.payload.uuid],
              process: {
                ...state.files[action.payload.uuid].process,
                status: uploadTypes.UPLOAD_ERROR,
              },
              uploaded: false,
              canSave: false,
            },
          },
        };
      }
      return state;

    case actionTypes.UPLOAD_DOCUMENT_SUCCESS:
      if (state.files[action.payload.uuid]) {
        return {
          ...state,
          files: {
            ...state.files,
            [action.payload.uuid]: {
              ...state.files[action.payload.uuid],
              process: {
                ...state.files[action.payload.uuid].process,
                status: uploadTypes.UPLOAD_SUCCESS,
              },
              uploaded: true,
            },
          },
        };
      }
      return state;

    case actionTypes.UPLOAD_DOCUMENT_PROCESSING:
      if (state.files[action.payload.uuid] &&
        state.files[action.payload.uuid].process.status !== uploadTypes.UPLOAD_DONE) {
        return {
          ...state,
          files: {
            ...state.files,
            [action.payload.uuid]: {
              ...state.files[action.payload.uuid],
              process: {
                ...state.files[action.payload.uuid].process,
                progress: 0,
                status: uploadTypes.UPLOAD_PROCESSING_START,
              },
            },
          },
        };
      }
      return state;
    case actionTypes.UPLOAD_DOCUMENT_DONE:
      if (state.files[action.payload.uuid]) {
        return {
          ...state,
          files: {
            ...state.files,
            [action.payload.uuid]: {
              ...state.files[action.payload.uuid],
              process: {
                ...state.files[action.payload.uuid].process,
                progress: 100,
                status: uploadTypes.UPLOAD_DONE,
              },
              url: `/${ action.payload.data[0] }`,
              uploaded: true,
              canSave: true,
            },
          },
        };
      }
      return state;

    case actionTypes.UPLOAD_DOCUMENT_CANCEL_SUCCESS:
      if (state.files[action.payload.uuid]) {
        let newState = { ...state };
        delete newState.files[action.payload.uuid];

        for (let i = 0; i < newState.sort.length; i++) {
          if (newState.sort[i].uuid === action.payload.uuid) {
            newState.sort.splice(i, 1);
            break;
          }
        }

        return newState;
      }
      return state;

    case actionTypes.UPLOAD_DOCUMENT_ORDER:
      const newSortOrder = moveElementInArray(state.sort.slice(), action.payload.oldIndex, action.payload.newIndex);
      return {
        ...state,
        sort: newSortOrder,
      };

    case actionTypes.UPLOAD_CLEAR:
      return initialState;

    case actionTypes.UPLOAD_DOCUMENT_INIT:
      let newState = {
        sort: [],
        files: {},
      };

      action.payload.forEach((file) => {
        const extension = `.${ getFileExtension(file.originalName) }`;
        newState.files[file.id] = {
          initialFile: file,
          process: {
            progress: 100,
            status: uploadTypes.UPLOAD_DONE,
            uuid: file.id,
          },
          uploadFile: null,
          callback: null,
          name: file.name,
          originalName: file.originalName,
          extension,
          url: file.url,
          uploaded: true,
          canSave: true,
        };
        newState.sort.push({ uuid: file.id });
      });
      return newState;

    default:
      return state;
  }
}
