import debounce from 'lodash/debounce';
import cn from 'classnames';
import noop from 'lodash/noop';
import Autosuggest from 'react-autosuggest';
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import omit from 'lodash/omit';
import { Manager, Popper, Reference } from 'react-popper';
import Highlighter from 'react-highlight-words';
import Input from '@/ui/shared/components/Form/Input';
import PortalWrp, { PortalId } from '@/ui/shared/components/Layout/PortalWrp';
import usePrevious from '@/ui/shared/hooks/usePrevious';
import inputStyles from '@/ui/shared/components/Form/Input/input.scss';
import styles from './styles.scss';

interface IProps {
  suggestions?: any[],
  suggestionMinLength?: number,
  minLength?: number,
  value?: string,
  name?: string,
  valueKey?: string,
  input?: any,
  label?: string,
  id?: string,
  useAsyncSuggestions?: (() => void) | boolean,
  fetchAsyncSuggestions?: (value: any) => any,
  // if suggestion object value field is different from object.name - pass your handler func
  getSuggestionValue?: () => void,
  onSuggestionSelected?: (suggestionValue: any, suggestion: any) => void,
  onChange?: (value: any, suggestion: any, event: any) => void,
  onBlur?: (value: any) => void,
  dataTest?: string,
  modalCall?: boolean,
  modalCallText?: string,
  modalShow?: (payload: any) => void,
  clearOnBlur?: boolean,
  isClearable?: boolean,
  usePortal?: boolean,
  handleAsyncSuggestions?: (value: any, callback?: any) => void,
  inputClassName?: string,
  renderSuggestion?: (suggestion: any, options: any) => any,
  meta?: any,
}

/**
 * @deprecated This component has been chosen for migration to UIKit, meaning any further changes or updates
 * must be completed within the migration process.
 * @see https://www.notion.so/finsight-group/Component-Migration-Process-f4475950481d429ba0dc450d0bb0cb8b
 */
const AutocompleteInput = ({
  value = '',
  suggestionMinLength = 3,
  minLength = 1,
  suggestions: propSuggestions = [],
  useAsyncSuggestions = false,
  dataTest = 'autoCompleteInput',
  isClearable = false,
  usePortal = false,
  onChange: propOnChange = noop,
  ...otherProps
}: IProps) => {
  const props = {
    value,
    suggestionMinLength,
    minLength,
    suggestions: propSuggestions,
    useAsyncSuggestions,
    dataTest,
    isClearable,
    usePortal,
    onChange: propOnChange,
    ...otherProps,
  };
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const prevInputValue = usePrevious(inputValue);
  const [inputSearchValue, setInputSearchValue] = useState(null);
  const selectedValue = useRef('');
  const popperModifier = useMemo(() => [{
    name: 'popperSize',
    enabled: true,
    phase: 'main',
    fn: (data) => {
      data.state.rects.popper.width = data.state.rects.reference.width;
      data.state.elements.popper.style.width = `${ data.state.rects.reference.width }px`;
  } }], []);

  useEffect(() => {
    setInputValue(props.input.value);
  }, [props.input.value]);

  /**
   * Default suggestion's getter by name field
   * @param {Object} Suggestion
   * @return {String}
   */
  const getSuggestionValue = useCallback((suggestion) => suggestion.name, []);

  /**
   * Getter of suggestion's value field taken from props or local func
   * @return {Function}
   */
  const valueGetter = props.getSuggestionValue || getSuggestionValue;

  /**
   * @param {Object} event
   * @param {Object} form
   */
  const onChange = (event, form) => {
    let value = form ? form.newValue : event.target.value;
    if (props.modalCall && form.newValue === props.modalCallText) {
      props.modalShow({ status: true, inputName: props.input.name, inputValue: prevInputValue });
      props.input.onChange('');
      return;
    }

    const suggestion = suggestions.find((suggestion) => (suggestion.name === value));
    setInputSearchValue(value);
    setInputValue(value);
    props.onChange(value, suggestion, event);
    props.input.onChange(value);
  };

  /**
   * @param {Object} event
   * @param {Object} highlightedSuggestion
   */
  const onBlur = useCallback((event, { highlightedSuggestion }) => {
    if (
      (props.clearOnBlur || props.modalCall) &&
      !highlightedSuggestion &&
      props.meta.initial !== inputValue &&
      selectedValue.current !== inputValue
    ) {
      selectedValue.current = '';
      return props.input.onBlur('');
    }

    let value = highlightedSuggestion
      ? valueGetter(highlightedSuggestion)
      : event.target?.value;
    if (props.modalCall && highlightedSuggestion?.name === props.modalCallText) value = prevInputValue;

    if (props.input && props.input.onBlur) {
      return props.input.onBlur(value, highlightedSuggestion);
    }

    if (props.onBlur) {
      return props.onBlur(value);
    }

    return undefined;
  }, [props.input?.onBlur, props.onBlur]);

  // Trigger Redux onFocus event (if used inside Field)
  const onFocus = useCallback(() => {
    props.input?.onFocus?.();
  }, []);

  /**
   * @param {String} value
   * @return {String[]}
   */
  const getSuggestions = useCallback((value) => {
    const inputValue = value.trim().toLowerCase();

    if (value.length < props.suggestionMinLength || inputValue.length === 0) {
      return [];
    }

    return props.suggestions.filter(
      (item) => valueGetter(item)
        .trim()
        .toLowerCase()
        .includes(inputValue),
    );
  }, [props.suggestionMinLength, props.suggestions]);

  /**
   * @param {{ value: String }}
   */
  const onSuggestionsFetchRequested = useCallback(({ value }) => {
    if (props.useAsyncSuggestions) {
      if (value.length >= props.suggestionMinLength && inputValue === value && props.suggestions) {
        setSuggestions(props.suggestions);
        return;
      }
      getAsyncSuggestions(value, setSuggestions);
    } else {
      setSuggestions(getSuggestions(value));
    }
  }, [getSuggestions, inputValue, props.fetchAsyncSuggestions]);

  const onSuggestionsClearRequested = useCallback(() => {
    setSuggestions([]);
  }, []);

  /**
   * @param {String} value
   */
  const getAsyncSuggestions = props.handleAsyncSuggestions ?? debounce((value) => {
    if (value.length < props.suggestionMinLength || value.length === 0) {
      setSuggestions([]);
      return;
    }

    (async () => {
      try {
        const response = await props.fetchAsyncSuggestions(value);
        setSuggestions(response.collection);
      } catch (e) {
        setSuggestions([]);
      }
    })();
  }, 250);

  /**
   * @param {Object} event
   * @param {{
   * suggestion,
   * suggestionValue,
   * suggestionIndex,
   * sectionIndex,
   * method: "click" | "enter"
   * }}
   */
  const handleSuggestionSelected = useCallback((event, { method, suggestionValue, suggestion }) => {
    selectedValue.current = suggestionValue;

    props.onSuggestionSelected?.(suggestionValue, suggestion);

    if (method === 'enter') {
      event.preventDefault();
    }
  }, [props.input.onChange]);

  const autoSuggestInputProps = {
    ...props,
    onChange,
    onFocus,
    onBlur,
    value: inputValue,
    name: props.input?.name,
  };

  const autoSuggestInnerInputProps = omit(
    autoSuggestInputProps,
    'isNarrow',
    'isClearable',
    'suggestionMinLength',
    'useAsyncSuggestions',
    'fetchAsyncSuggestions',
    'getSuggestionValue',
    'inputClassName',
    'suggestions',
    'companies',
    'input',
    'meta',
    'dataTest',
    'formFieldClassName',
    'modalCall',
    'modalCallText',
    'modalShow',
    'isEqual',
    'onSuggestionSelected',
    'usePortal',
  );

  /**
   * @param {Object} suggestion - one of suggestions array objects
   * @param {Object} details
   * @param {String} details.query - Used to highlight the matching string. As user types in the input, query will be
   * equal to the trimmed value of the input. Then, if user interacts using the Up or Down keys, the input will get the
   * value of the highlighted suggestion, but query will remain to be equal to the trimmed value of the input prior to
   * the Up and Down interactions.
   * @param {Boolean} details.isHighlighted - Whether or not the suggestion is highlighted.
   */
  const renderSuggestion = useCallback((suggestion) => (
    <Highlighter
      autoEscape
      searchWords={ [inputSearchValue] }
      textToHighlight={ valueGetter(suggestion) }
      highlightClassName={ inputStyles.matchingText }
    />
  ), [inputSearchValue]);

  const renderSuggestionsContainer = ({ containerProps, children }) => (
    <div { ...containerProps }>
      { props.usePortal ? (
        <PortalWrp
          usePortal={ props.usePortal }
          portalId={ PortalId.PORTAL_OVERLAY_ID }
        >
          <Popper
            placement="bottom-start"
            // @ts-ignore
            modifiers={ popperModifier }
          >
            { ({ placement, ref, style }) => (
              <div
                ref={ ref }
                style={ style }
                data-placement={ placement }
                className={ cn(styles.popperDropdownWrp, styles.reactAutosuggestEncapsulation) }
              >
                { children }
              </div>
            ) }
          </Popper>
        </PortalWrp>
        ) :
        <> { children } </> }
    </div>
);

  const suggestionsWithModalCall = [
    {
      suggestions,
    },
    ...[(props.input.value.length >= props.suggestionMinLength && inputValue === props.input.value) ? {
      suggestions: [{
        name: props.modalCallText,
      }],
    } : { suggestions: [] }],
  ];

  const shouldRenderSuggestions = () => {
    return true;
  };

  // TODO: need to implement x button logic to Autosuggest with Input
  return (
    <Manager>
      <Reference>
        { ({ ref }) => (
          <div
            ref={ ref }
            className={ cn(styles.popperWrp, styles.reactAutosuggestEncapsulation) }
          >
            <Autosuggest
              label={ props.label }
              shouldRenderSuggestions={ shouldRenderSuggestions }
              id={ props.id ? props.id : undefined }
              multiSection={ props.modalCall }
              renderSectionTitle={ () => undefined }
              getSectionSuggestions={ (section) => section.suggestions }
              suggestions={ props.modalCall ? suggestionsWithModalCall : suggestions }
              onSuggestionsClearRequested={ onSuggestionsClearRequested }
              getSuggestionValue={ valueGetter }
              onSuggestionsFetchRequested={ onSuggestionsFetchRequested }
              onSuggestionSelected={ handleSuggestionSelected }
              focusInputOnSuggestionClick={ false }
              data-test={ props.name || props.input?.name }
              inputProps={ autoSuggestInnerInputProps }
              renderSuggestion={ props.renderSuggestion ?? renderSuggestion }
              renderSuggestionsContainer={ renderSuggestionsContainer }
              renderInputComponent={ (inputProps) => (
                // @ts-ignore
                <Input
                  { ...autoSuggestInputProps }
                  input={ inputProps }
                  isClearable={ props.isClearable }
                  dataTest={ props.dataTest }
                  autoCompleteInput
                />
              ) }
            />
          </div>
        ) }
      </Reference>
    </Manager>
  );
};

export default AutocompleteInput;
