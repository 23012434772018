import { IValidationError } from './interfaces/ValidationError';

interface IParams {
  value: string,
  fieldName: string,
  fieldCaption: string,
}

export const validateAngleBracketsPresence = (params: IParams): IValidationError => {
  if (/[<>]/.test(params.value)) {
    return { [params.fieldName]: `Angle brackets aren't allowed in ${ params.fieldCaption }` };
  }
  return null;
};
