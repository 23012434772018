import cn from 'classnames';
import noop from 'lodash/noop';
import React, { useEffect } from 'react';
import { Spinner, ImgFetching, ProgressBar, Modal, Icon, IconType } from '@dealroadshow/uikit';
import files from '@/Framework/url/filesUrl';

import {
  convertUploadTypesToString,
  isShowProgressByUploadType,
} from '../helpers/convertUploadTypes';
import UploadDropzoneOneFile from './UploadDropzoneOneFile';
import styles from '../upload.scss';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import zerocaseIcon from '@/dealroadshow/ui/components/Viewer/Zerocase/assets/zerocase-icon.svg';

export interface IProps {
  urlHelper?: { [key: string]: any },
  isShowError?: boolean,
  isSubmitDraft?: boolean,
  isShowConfirmModal?: boolean,
  prevUploadFile?: { [key: string]: any },
  uploadFile?: { [key: string]: any },
  formFieldName?: string,
  entityName?: string,
  uploadForType?: string,
  onUpload?: (acceptedFiles: any, onUploadProcessingDone: any) => void,
  onUploadCancel?: (e: any) => void,
  setConfirmModalVisible?: (isVisible: boolean, isConfirmed: boolean) => void,
  setConfirmModalConfirmed?: () => void,
  onUploadProcessingDone?: () => void,
  onChange?: (e: any) => void,
  allowedFileTypes?: string,
  allowedFileText?: string,
  uploadName?: string,
  uuid?: string,
  slideType?: string,
  canRemove?: boolean,
  maxSize?: number,
  maxSizeUnits?: string,
  disabled?: boolean,
  disabledText?: string,
  disabledComponent?: React.ElementType,
  description?: string,
  maxSizeErrorMessage?: string,
  dropzoneClassName?: string,
  uploadPreviewClassName?: string,
  previewNameClassName?: string,
  modalFooter?: (e: any) => void | any,
  isVideoOnly?: boolean,
}

const UploadPreview = ({
  urlHelper,
  isShowError = false,
  isSubmitDraft = false,
  isShowConfirmModal = false,
  prevUploadFile = null,
  uploadFile = {},
  formFieldName,
  entityName = 'this new Roadshow',
  uploadForType = 'other',
  onUpload,
  onUploadCancel,
  setConfirmModalVisible,
  setConfirmModalConfirmed,
  onUploadProcessingDone,
  onChange = noop,
  allowedFileTypes,
  allowedFileText,
  uploadName,
  uuid = '',
  slideType = '',
  canRemove = true,
  maxSize = Infinity,
  maxSizeUnits = 'B',
  disabled,
  disabledText,
  disabledComponent: DisabledComponent,
  description,
  maxSizeErrorMessage = '',
  dropzoneClassName = '',
  uploadPreviewClassName = '',
  previewNameClassName = '',
  modalFooter,
  isVideoOnly,
}: IProps) => {
  // Prevent PDF file caching when canceling and creating new DRS
  useEffect(() => {
   return () => {
     if (uploadForType !== 'slide') {
       handleRemove();
     }
   };
  }, []);

  const getFile = () => {
    if (uploadForType === 'other') {
      return uploadFile;
    }
    if (uploadForType === 'slide') {
      return uploadFile[uuid].types[slideType];
    }

    return undefined;
  };

  const getPrevFile = () => {
    if (uploadForType === 'other') {
      return prevUploadFile;
    }
    if (uploadForType === 'slide' && prevUploadFile) {
      return prevUploadFile[uuid].types[slideType];
    }

    return undefined;
  };

  const getFilePreviewUrl = (params) => {
    if (urlHelper) {
      return urlHelper.getFileUrl(params);
    }
    return files.getFileUrl(params);
  };

  const handleUpload = (acceptedFiles) => {
    if (uploadForType === 'other') {
      onUpload(acceptedFiles, onUploadProcessingDone);
    }
    if (uploadForType === 'slide') {
      onChange(uuid);
      onUpload({
        files: acceptedFiles,
        uuidOriginal: uuid,
        slideType,
      }, onUploadProcessingDone);
    }
  };

  const handleModalConfirm = () => {
    setConfirmModalVisible(false, true);
    setConfirmModalConfirmed();
  };

  const handleModalDiscard = () => {
    setConfirmModalVisible(false, false);
  };

  const handleRemove = () => {
    if (uploadForType === 'other') {
      onUploadCancel(getFile().process.uuid);
    }
    if (uploadForType === 'slide') {
      onUploadCancel({
        uuid: getFile().process.uuid,
        uuidOriginal: uuid,
        slideType,
      });
    }
  };

    const file = getFile();
    const prevFile = getPrevFile();
    const modalPublicationModalTitle = prevFile ? 'Please confirm presentation upload' : 'Please confirm publishing roadshow';
    const modalDraftModalTitle = 'Please confirm presentation upload';
    const modalTitle = isSubmitDraft ? modalDraftModalTitle : modalPublicationModalTitle;

    const modalDraftDescription = `You are ${ prevFile ? 'about to change' : 'setting' } the primary presentation for `;
    const modalPublishDescription = `You are about to ${ prevFile ? 'change the primary presentation for ' : 'publish ' }`;
    const modalDescription = isSubmitDraft ? modalDraftDescription : modalPublishDescription;
    const dropZoneCls = cn(
      dropzoneClassName,
      { [styles.isDropZoneError]: isShowError && !file.canSave && !file.process.uuid },
    );

    if (disabled && !isShowConfirmModal) {
      if (DisabledComponent) {
        return <DisabledComponent />;
      }
      return (
        <div className={ styles.disabled }>
          <div className={ styles.disabledText }>
            { disabledText }
          </div>
        </div>
      );
    }

  return (
    <>
      <div className={ styles.formFileUpload }>
        { !!file.process.uuid && (
          <div className={ cn(styles.preview, uploadPreviewClassName) } data-test="uploadPreview">
            { canRemove && (
              <span
                className={ styles.previewRemoveIcon }
                onClick={ handleRemove }
              >
                <Icon type={ IconType.close } />
              </span>
            ) }
            { !file.preview.url && (
              <span className={ cn(styles.previewName, previewNameClassName) }>
                { file.name }
              </span>
            ) }
            { !file.preview.url && (
              <Spinner overlay />
            ) }

            { !file.preview.url && (
              <span className={ styles.previewStatus }>
                { convertUploadTypesToString(file.process.status) }
              </span>
            ) }
            { isShowProgressByUploadType(file.process.status) && (
              <ProgressBar
                className={ styles.previewProgressBar }
                filledClassName={ styles.previewProgressBarFilled }
                value={ file.process.progress }
              />
            ) }
            { !!file.preview.url && (
              <ImgFetching
                className={ styles.previewImg }
                src={ getFilePreviewUrl(file.preview.url) }
                spinnerProps={ {
                  overlay: true,
                } }
              />
            ) }
          </div>
        ) }
        <div className={ styles.dropZoneWrp } data-test="uploadOneFileDropzone">
          <UploadDropzoneOneFile
            onUpload={ handleUpload }
            allowedFileTypes={ allowedFileTypes }
            allowedFileText={ allowedFileText }
            uploadName={ uploadName }
            maxSize={ maxSize }
            maxSizeUnits={ maxSizeUnits }
            formFieldName={ formFieldName }
            dropzoneHelperClassName={ dropZoneCls }
            maxSizeErrorMessage={ maxSizeErrorMessage }
          />
          { description && (
            <div className={ cn(styles.tip, styles.videoTip) }>
              { description }
            </div>
          ) }
        </div>
      </div>
      <Modal
        isVisible={ isShowConfirmModal }
        className={ styles.confirmModal }
        title={ modalTitle }
        onCloseClicked={ handleModalDiscard }
        dataTest="uploadPreviewConfirmModal"
        footer={ modalFooter ?
          modalFooter({
            prevFile,
            handleModalConfirm,
            handleModalDiscard,
            isConfirmDisable: !file.preview.url && !isVideoOnly,
          }) : (
            <>
              <Button
                onClick={ handleModalConfirm }
                variant={ variantTypes.success }
                title="Confirm"
                disabled={ !file.preview.url }
                dataTest="uploadPreviewConfirmModalButtonConfirm"
              />
              <Button
                onClick={ handleModalDiscard }
                variant={ variantTypes.text }
                title="Cancel"
                dataTest="uploadPreviewConfirmModalButtonCancel"
              />
            </>
        ) }
      >
        { modalDescription }
        <strong>{ entityName }</strong>.
        <div className={ styles.confirmContent }>
          { isVideoOnly && (
            <div className={ cn(styles.contentColumn, styles.previewIcon) }>
              <img src={ zerocaseIcon } />
              <div className={ styles.previewText }>Preview is not available for Video Only Roadshow</div>
            </div>
          ) }
          { !isVideoOnly && prevFile && (
            <>
              <div className={ styles.contentColumn }>
                <div className={ styles.columnTitle }>Original Version</div>
                <div className={ cn(styles.preview, styles.modalPreview) } data-test="uploadPreview">
                  { !prevFile.preview.url && (
                    <Spinner overlay />
                  ) }
                  { !!prevFile.preview.url && (
                    <ImgFetching
                      className={ styles.previewImg }
                      src={ getFilePreviewUrl(prevFile.preview.url) }
                      spinnerProps={ {
                        overlay: true,
                      } }
                    />
                  ) }
                </div>
                <div className={ styles.columnFilename }>{ prevFile.name }</div>
              </div>
              <Icon
                type={ IconType.arrowRight }
                className={ styles.arrowIcon }
              />
            </>
          ) }
          { !isVideoOnly && (
            <div className={ styles.contentColumn }>
              <div className={ styles.columnTitle }>{ prevFile ? 'New Version' : 'Initial Version' }</div>
              <div className={ cn(styles.preview, styles.modalPreview) } data-test="uploadPreview">
                { !file.preview.url && (
                  <Spinner overlay />
                ) }
                { !!file.preview.url && (
                  <ImgFetching
                    className={ styles.previewImg }
                    src={ getFilePreviewUrl(file.preview.url) }
                    spinnerProps={ {
                      overlay: true,
                    } }
                  />
                ) }
              </div>
              <div className={ styles.columnFilename }>{ file.name }</div>
            </div>
          ) }
        </div>
      </Modal>
    </>
  );
};

export default UploadPreview;
