import { useState, useMemo, useEffect } from 'react';
import {
  InvestorTargetingListFilterType,
  InvestorTargetingListFilterPeriodType,
  InvestorTargetingListFilterSectorType,
  InvestorTargetingListFilterTypeType,
  InvestorTargetingListFilterViewedType,
} from '@/dealroadshow/domain/vo/dmPortal/analytics/InvestorTargeting';

export type InvestorTargetingSelectOptionType = {
  type: InvestorTargetingListFilterTypeType,
  row: InvestorTargetingListFilterPeriodType | InvestorTargetingListFilterSectorType,
  cell: InvestorTargetingListFilterViewedType,
};

export const useInvestorTargetingLocalFilters = ({ isInvestorTargetingPage }: { isInvestorTargetingPage: boolean }) => {
  const [selectedOption, setSelectedOption] = useState<InvestorTargetingSelectOptionType>(null);

  const filter = useMemo<InvestorTargetingListFilterType>(() => {
    if (!selectedOption) {
      return null;
    }

    const { type, row, cell } = selectedOption;

    const period =
      row === InvestorTargetingListFilterPeriodType.ALL ||
      row === InvestorTargetingListFilterPeriodType.BEFORE_LAST_YEAR
        ? row
        : InvestorTargetingListFilterPeriodType.LAST_YEAR;

    const sector =
      row === InvestorTargetingListFilterSectorType.ALL || row === InvestorTargetingListFilterSectorType.COMPARABLES
        ? row
        : InvestorTargetingListFilterSectorType.ISSUER;

    return {
      type,
      period,
      sector,
      viewed: cell,
    };
  }, [selectedOption]);

  const isSelectedOptionActive = (targetSelectedOption: InvestorTargetingSelectOptionType) => {
    if (!selectedOption) {
      return false;
    }

    return (
      selectedOption.cell === targetSelectedOption.cell &&
      selectedOption.row === targetSelectedOption.row &&
      selectedOption.type === targetSelectedOption.type
    );
  };

  const clearFilter = () => setSelectedOption(null);

  useEffect(() => {
    if (!isInvestorTargetingPage) {
      clearFilter();
    }
  }, [isInvestorTargetingPage]);

  return {
    clearFilter,
    selectedOption,
    setSelectedOption,
    isSelectedOptionActive,
    filter,
  };
};
