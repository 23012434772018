import { ReactNode } from 'react';
import cn from 'classnames';
import { Icon, IconType, Tooltip } from '@dealroadshow/uikit';

import styles from './labelWithIcon.scss';

interface IProps {
  label: string,
  content: string | ReactNode,
  icon?: IconType,
  labelClassName?: string,
  tooltipClassName?: string,
}

const LabelWithIcon = ({
  label,
  content,
  icon: IconTypeComponent = IconType.info,
  labelClassName,
  tooltipClassName,
}: IProps) => (
  <>
    <span className={ labelClassName }>{ label }</span>
    <Tooltip content={ content } containerClassName={ cn(styles.tooltipIcon, tooltipClassName) }>
      <Icon type={ IconTypeComponent } />
    </Tooltip>
  </>
);

export default LabelWithIcon;
