import createAction from '@/Framework/State/Redux/createAction';
import RoadshowCrossDealAnalyticsRepository
  from '@/dealroadshow/infrastructure/repository/analytics/RoadshowCrossDealAnalyticsRepository';
import * as actionTypes from './actionTypes';
import * as hasAccessSelectors from './selectors';

/**
 * Check user access to accounts and contacts cross deal analytics.
 * @return {Promise}
 */
export function checkUserHasAccessToAccountsAndContactsAnalytics() {
  return async (dispatch, getState) => {
    dispatch(createAction(actionTypes.CHECK_CROSS_DEAL_ANALYTICS_USER_HAS_ACCESS));

    if (hasAccessSelectors.hasUserAccess(getState())) {
      return dispatch(createAction(actionTypes.CHECK_CROSS_DEAL_ANALYTICS_USER_HAS_ACCESS_SUCCESS, {
        userHasAccess: true,
      }));
    }

    try {
      const roadshowCrossDealAnalyticsRepository = getState().container.get(RoadshowCrossDealAnalyticsRepository);
      const response = await roadshowCrossDealAnalyticsRepository.hasUserAccessToCrossDealAnalytics();

      return dispatch(createAction(actionTypes.CHECK_CROSS_DEAL_ANALYTICS_USER_HAS_ACCESS_SUCCESS, {
        userHasAccess: response.access,
      }));
    } catch (error) {
      return dispatch(createAction(actionTypes.CHECK_CROSS_DEAL_ANALYTICS_USER_HAS_ACCESS_ERROR));
    }
  };
}

/**
 * Resets access to initial state.
 * @return {Object}
 */
export const resetUserHasAccessToAccountsAndContactsAnalytics = () => (
  createAction(actionTypes.RESET_CROSS_DEAL_ANALYTICS_USER_HAS_ACCESS)
);
