import React from 'react';
import ConsentContext, { useConsentContext } from '@/dataroom/application/ConsentContext';
import { Spinner } from '@dealroadshow/uikit';
import Consent from './Consent';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';

interface IProps {
  children: React.ReactElement,
}

function ConsentAccessLayer({ children }: IProps) {
  const { isConsentAccepted, isAcceptConsentFetching, isConsentStatusFetching } = useConsentContext();
  const { tenant } = useDataroomTenantContext();

  const isConsentForEDeliveryRequired = !DataroomTenantConfig.fromCode(tenant).isConsentForEDeliveryRequired;

  if (isConsentForEDeliveryRequired === undefined) {
    throw `Tenant ${ tenant } does not have isConsentForEDeliveryRequired config value.`;
  }

  if (isConsentForEDeliveryRequired) {
    return children;
  }

  if (isConsentAccepted === false) {
    return <Consent />;
  }

  if (isAcceptConsentFetching || isConsentStatusFetching) {
    return <Spinner />;
  }

  // The checks above is to prevent glitches on frontend
  // it is safe to render children as in case of error from backend isConsentAccepted will be set to false
  return children;
}

export default ({ children }: IProps) => {
  const { tenant } = useDataroomTenantContext();
  return (
    <ConsentContext tenant={ tenant }>
      <ConsentAccessLayer>
        { children }
      </ConsentAccessLayer>
    </ConsentContext>
  );
};
