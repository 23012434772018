import React from 'react';
import splitArray from '@/Framework/dataHelpers/array/splitArray';
import styles from '../filters.scss';

interface IProps {
  children: React.ReactNode,
  columnsCount?: number,
}

function FilterColumns({ children, columnsCount = 2 }: IProps) {
  // @ts-ignore
  let columns = splitArray(children, columnsCount);
  return (
    <div className={ styles.filterColumnsWrp }>
      { columns.map((column, index) => (
        <div
          /* eslint-disable-next-line react/no-array-index-key */
          key={ index }
          className={ styles.filterColumn }
        >
          { React.Children.map(column, (child) => child) }
        </div>
      )) }
    </div>
  );
}

export default React.memo(FilterColumns);
