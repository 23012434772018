import React, { createContext, ReactNode, useContext } from 'react';
import useUpload from './useUpload';
import { AllocateTenants } from '@/allocate/domain/vo/common/AllocateTenants';

type UploadContextType = ReturnType<typeof useUpload>;

export const UploadContext = createContext<UploadContextType>(null);

export const useUploadContext = () => {
  const context = useContext(UploadContext);
  if (!context) {
    throw new Error('useUploadContext must be used within a UploadContextProvider');
  }
  return context;
};

interface IProps {
  children: ReactNode,
  tenant: AllocateTenants,
  getRoadshowFilters?: (id: string) => void,
}

const UploadContextProvider = ({ children, tenant, getRoadshowFilters }: IProps) => (
  <UploadContext.Provider value={ useUpload({ tenant, getRoadshowFilters }) }>
    { children }
  </UploadContext.Provider>
);

export default UploadContextProvider;
