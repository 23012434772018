import React from 'react';
import cn from 'classnames';
import { Icon, IconType, Tooltip, IColumn } from '@dealroadshow/uikit';
import formatUnixtimestamp from '@/Framework/dataHelpers/formatters/formatUnixtimestamp';
import formatNumber from '@/Framework/dataHelpers/formatters/formatNumber';
import formatPercentage from '@/Framework/dataHelpers/formatters/formatPercentage';
import { roundUpValueToFraction } from '@/Framework/dataHelpers/number/roundUpValueToFraction';
import { getDigitsAfterDecimalCount } from '@/allocate/ui/components/shared/Tables/helpers';
import { getCurrencySymbol } from '@/allocate/ui/helpers/currencySymbols';
import { ALL_CLASSES_NAME } from '@/allocate/ui/constants';
import styles from '@/allocate/ui/components/shared/Tables/cellStyles.scss';

export const indexCellCallback = (cellProps) => (
  formatNumber(cellProps.rowIndex + 1)
);

export const displayNameCellCallback = (cellProps) => (
  cellProps.cellData?.name || '-'
);

export const numberCellCallback = (cellProps) => (
  cellProps.cellData ? formatNumber(cellProps.cellData) : '-'
);

export const numberWithTwoDecimalsCellCallback = (cellProps) => (
  cellProps.cellData ? formatNumber(cellProps.cellData, { decimals: 2 }) : '-'
);

export const percentageCellCallback = (cellProps) => (
  cellProps.cellData ? formatPercentage(cellProps.cellData) : '-'
);

export const currencyCellCallback = (cellProps) => (
  cellProps.cellData.displayName
);

export const benchmarkCellCallback = (cellProps) => (
  cellProps.cellData?.name || '-'
);

export const defaultOptionalCellCallback = (cellProps) => {
  const { cellData, cellName, row } = cellProps;
  if (cellData) return cellData;
  if (cellName in row) return '-';
  return '';
};

export const responsiveTextRender = (value) => (
  <Tooltip
    containerClassName={ styles.responsiveCellContent }
    content={ value }
  >
    { value }
  </Tooltip>
);

export const responsiveOptionalCellCallback = (cellProps) => {
  const { cellData } = cellProps;

  if (cellData) {
    return responsiveTextRender(cellData);
  }

  return '-';
};

export const dateCellCallback = (cellProps) => {
  const { cellData, cellName, row } = cellProps;

  if (cellData && cellName in row) {
    return formatUnixtimestamp(cellProps.cellData);
  }

  return '';
};

export const yieldCellCallback = (cellProps) => (
  cellProps.cellData ? formatPercentage(cellProps.cellData * 100, 3) : '-'
);

export const leiNameCellCallback = (cellProps) => (cellProps.cellData.length ? (
  <Tooltip
    containerClassName={ styles.responsiveCellContent }
    content={ cellProps.cellData.map((lei) => (
      <div
        className={ styles.leisTooltip }
        key={ lei }
      >
        { lei }
      </div>
    )) }
  >
    { cellProps.cellData.join('; ') }
  </Tooltip>
) : '-');

export const tenorCellCallback = (cellProps) => {
  const value = cellProps.cellData;
  if (!value) return '-';
  if (value < 0.01) return 0.01;
  return roundUpValueToFraction(value, 2).toFixed(2);
};

export const couponCellCallback = (cellProps) => {
  const value = cellProps.cellData;
  if (!value) return '-';
  if (/[^0-9,.%]/.test(value)) return value;
  const formattedValue = value.replaceAll(/[,%]/g, '');
  return `${ formatNumber(formattedValue, { decimals: 2 }) }%`;
};

export const couponTypeWithTooltipCellCallback = (cellProps) => {
  const { cellData } = cellProps;

  if (cellData) {
    return (
      <Tooltip content={ cellData.name }>
        { cellData.abbreviation }
      </Tooltip>
    );
  }

  return '-';
};

export const sharePriceCellCallback = (cellProps) => {
  const digitsAfterDecimal = getDigitsAfterDecimalCount(cellProps.cellData);

  return formatNumber(
    cellProps.cellData, { decimals: Math.max(2, digitsAfterDecimal) },
  );
};

export const ratingCellCallback = (cellProps, isAccountPage = false) => {
  const {
    ratingMoodys,
    ratingSP,
    ratingFitch,
    ratingDBRS,
    ratingKroll,
    ratingOther1,
    ratingOther2,
    ratingOther3,
  } = cellProps.row;

  const otherRating = [ratingDBRS, ratingKroll, ratingOther1, ratingOther2, ratingOther3]
    .filter((rating) => rating)
    .map((rating) => rating.abbreviation);

  const tooltip = (
    <>
      { ratingMoodys && (
        <div className={ styles.ratingTooltipItem }>
          <span>Moody's</span>
          { ratingMoodys.abbreviation }
        </div>
      ) }
      { ratingSP && (
        <div className={ styles.ratingTooltipItem }>
          <span>SP</span>
          { ratingSP.abbreviation }
        </div>
      ) }
      { ratingFitch && (
        <div className={ styles.ratingTooltipItem }>
          <span>Fitch</span>
          { ratingFitch.abbreviation }
        </div>
      ) }
      { ratingDBRS && (
        <div className={ styles.ratingTooltipItem }>
          <span>DBRS</span>
          { ratingDBRS.abbreviation }
        </div>
      ) }
      { ratingKroll && (
        <div className={ styles.ratingTooltipItem }>
          <span>Kroll</span>
          { ratingKroll.abbreviation }
        </div>
      ) }
      { ratingOther1 && (
        <div className={ styles.ratingTooltipItem }>
          <span>Other1</span>
          { ratingOther1.abbreviation }
        </div>
      ) }
      { ratingOther2 && (
        <div className={ styles.ratingTooltipItem }>
          <span>Other2</span>
          { ratingOther2.abbreviation }
        </div>
      ) }
      { ratingOther3 && (
        <div className={ styles.ratingTooltipItem }>
          <span>Other3</span>
          { ratingOther3.abbreviation }
        </div>
      ) }
    </>
  );

  return (
    <Tooltip
      content={ tooltip }
      disabled={ !ratingMoodys && !ratingSP && !ratingFitch && !otherRating.length }
      containerClassName={ cn(styles.ratingCell, {
        [styles.fixedWidth]: isAccountPage,
      }) }
    >
      {
        `${ ratingMoodys ? ratingMoodys.abbreviation : '-' } /
         ${ ratingSP ? ratingSP.abbreviation : '-' } /
         ${ ratingFitch ? ratingFitch.abbreviation : '-' } /
         ${ otherRating.length ? otherRating.join(' / ') : '-' }`
      }
    </Tooltip>
  );
};

export const ordersAtLaunchSumCellCallback = (cellProps, currency) => {
  const { ordersAtLaunchSum } = cellProps.row;
  return (ordersAtLaunchSum[currency] || ordersAtLaunchSum[currency] === 0)
    ? formatNumber(ordersAtLaunchSum[currency])
    : '-';
};

export const allocationsSumCellCallback = (cellProps, currency, shouldAddCurrencySymbol = false) => {
  const { allocationsSum } = cellProps.row;
  const value = allocationsSum[currency];

  if (value || value === 0) {
    const digitsAfterDecimal = getDigitsAfterDecimalCount(value);
    const symbol = shouldAddCurrencySymbol ? getCurrencySymbol(currency) : '';

    return formatNumber(value, {
      symbol,
      symbolOnLeft: !!symbol,
      spaceBetweenValueAndSymbol: !!symbol,
      decimals: Math.min(2, digitsAfterDecimal),
    });
  }

  return '-';
};

export const ordersAtLaunchFillCellCallback = (cellProps, currency) => {
  const { allocationsSum, ordersAtLaunchSum, ordersAtLaunchFill } = cellProps.row;
  if (ordersAtLaunchSum[currency] && allocationsSum[currency] === 0) return formatPercentage(0);
  return ordersAtLaunchFill[currency] ? formatPercentage(ordersAtLaunchFill[currency]) : '-';
};

export const sharesCellCallback = (cellProps) => (
  (cellProps.cellData || cellProps.cellData === 0) ? formatNumber(cellProps.cellData) : '-'
);

export const sharesAtLaunchFillCellCallback = (cellProps) => {
  const { noOfShares, sharesAtLaunchSum } = cellProps.row;
  if (sharesAtLaunchSum && noOfShares === 0) return formatPercentage(0);
  return cellProps.cellData ? formatPercentage(cellProps.cellData) : '-';
};

export const allocationsSumPercentageHeadCallBack = (currency, currencyDisplayNames, selectedClass) => {
  if (selectedClass === ALL_CLASSES_NAME && currencyDisplayNames.length > 1) {
    return `% Deal (${ currency })`;
  }
  if (selectedClass === ALL_CLASSES_NAME) {
    return '% Deal';
  }
  if (currencyDisplayNames.length > 1) {
    return `% Class (${ currency })`;
  }
  return '% Class';
};

export const allocationsSumPercentageCellCallback = (cellProps, currency) => {
  const { allocationsSumPercentage } = cellProps.row;
  return allocationsSumPercentage[currency] ? formatPercentage(allocationsSumPercentage[currency]) : '-';
};

export const upsizeCellCallback = (cellCallback: IColumn['cellCallback']) => (props) => {
  const {
    row: { upsized },
  } = props;

  return (
    <span className={ styles.upsizeCell }>
      <span className={ styles.upsizeCellContent }>{ cellCallback(props) }</span>
      { upsized && (
        <Tooltip wrpClassName={ styles.upsizeCellIcon } content="Deal Upsized">
          <Icon type={ IconType.triangleArrowTop } className={ styles.upsizedIcon } />
        </Tooltip>
      ) }
    </span>
  );
};
