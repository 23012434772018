import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UploadPreview } from '../components';
import * as constants from '../constants';
import {
  uploadPresentationMediaThumbnail,
  uploadPresentationMediaThumbnailCancel,
} from '../actions';

function mapStateToProps(state) {
  return {
    uploadFile: state.upload.presentationMediaThumbnail,
    formFieldName: 'logo',
    allowedFileTypes: constants.UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_ALLOWED_FILES,
    allowedFileText: constants.UPLOAD_PRESENTATION_MEDIA_THUMBNAIL_ALLOWED_FILES_TEXT,
    uploadName: 'Thumbnail',
    isShowError: false,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onUpload: uploadPresentationMediaThumbnail,
    onUploadCancel: uploadPresentationMediaThumbnailCancel,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadPreview);
