import { filterDealsBy } from '@/dmPortal/ui/components/GlobalAnalytics/common/Sidebar/Sections/Deals/constants';

export const filtersKeys = [
  'includeTransactionTypeIds',
  'includeSubIndustryIds',
  'includeAbsSubSectorIds',
  'includeAccountTypeIds',
  'includeAccountIds',
  'includeCurrencyIds',
  'includeRatingGroupIds',
  'includeRatingAgencyIds',
  'includeDebtCouponTypeIds',
  'includeSponsorIds',
  'includeUnderwriterIds',
  'allocationRange',
  'spreadSizeRange',
  'tenorWalYearsRange',
  'yieldSizeRange',
  'includeDealIds',
  'dates',
  'upsized',
  ...Object.values(filterDealsBy),
];
