const currencyToSymbolMapping = {
  USD: '$',
  AUD: '$',
  CAD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
};

export const getCurrencySymbol = (currency: string): string => {
  if (Object.keys(currencyToSymbolMapping).includes(currency)) {
    return currencyToSymbolMapping[currency];
  }

  return '';
};

export const replaceCurrencyWithSymbol = (currency: string): string => {
  if (Object.keys(currencyToSymbolMapping).includes(currency)) {
    return currencyToSymbolMapping[currency];
  }

  return currency;
};
