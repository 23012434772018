import React, { createContext, useContext, ReactNode } from 'react';
import usePermissions from './usePermissions';

export type PermissionsContextType = ReturnType<typeof usePermissions>;

export const PermissionsContext = createContext<PermissionsContextType>(null);

export const usePermissionsContext = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error('usePermissionsContext must be used within a PermissionsContextProvider');
  }
  return context;
};

interface IProps {
  children: ReactNode,
}

const PermissionsContextProvider = ({ children }: IProps) => (
  <PermissionsContext.Provider value={ usePermissions() }>
    { children }
  </PermissionsContext.Provider>
);

export default PermissionsContextProvider;
