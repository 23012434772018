import * as accountTypesSelectors from '@/dealroadshow/application/actions/accountType/selectors';
import createAction from '@/Framework/State/Redux/createAction';
import Logger from '@/Framework/browser/log/Logger';
import AccountTypeRepository from '@/users/infrastructure/repository/AccountTypeRepository';
import { getRandomColor } from '@/ui/shared/helpers/color';
import * as actionTypes from './actionTypes';
import * as constants from './constants';

/**
 * @param {?Object} clearCache
 *
 * @return {function(...[*]=)}
 */
export function getAccountTypes({ clearCache = false } = {}) {
  return async (dispatch, getState) => {
    dispatch(createAction(actionTypes.GET_ACCOUNT_TYPES));
    try {
      const accountTypeRepository = getState().container.get(AccountTypeRepository);
      const response = await accountTypeRepository.getAccountTypeList({ clearCache });
      dispatch(createAction(actionTypes.GET_ACCOUNT_TYPES_SUCCESS, response.collection));
    } catch (error) {
      dispatch(createAction(actionTypes.GET_ACCOUNT_TYPES_ERROR, error));
      let textError = error?.toString?.() || '';
      Logger.error('Can\'t get Account Types for analytics', { textError });
    }
  };
}

/**
 * @param {String} accountTypeName
 * @return {String}
 */
export function getColorByAccountTypeName(accountTypeName) {
  return (dispatch, getState) => {
    const state = getState();
    let accountTypes = accountTypesSelectors.getAccountTypes(state);

    if (!accountTypes.length) {
      return getRandomColor();
    }
    let accountTypesByName = accountTypesSelectors.getAccountTypesByName(state);

    return accountTypeName in accountTypesByName
           && !!accountTypesByName[accountTypeName]?.color
      ? accountTypesByName[accountTypeName].color
      : constants.COLOR_BY_ACCOUNT_TYPE_FALLBACK;
  };
}
