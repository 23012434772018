import React, { createContext, Reducer, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import * as filtersSelectors from './filters/selectors';
import { useActions } from './filters/actions';
import { initialState, reducer } from './filters/reducer';
import { IReducerState } from './filters/types';
import { useDealsFilters } from './filters/useDealsFilters';

type GlobalAnalyticsFiltersContextType = ReturnType<typeof useGlobalAnalyticsFilters>;

const useGlobalAnalyticsFilters = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const sidebarOpen = () => setSidebarOpen(true);
  const sidebarClose = () => setSidebarOpen(false);

  const [state, dispatch] = useReducer<Reducer<IReducerState, any>>(reducer, initialState);

  const actions = useActions(dispatch, state);

  const [isAppliedFilters, setAppliedFilters] = useState(false);

  useEffect(() => {
    actions.initCrossDealAnalytics();
  }, []);

  const { dealsFilters, getDealsFilters, isLoading: isDealsFetching } = useDealsFilters();

  const stateSelectors = useMemo(
    () => ({
      transactionTypeFilterList: filtersSelectors.getTransactionTypeFilterList(state),
      industryFilterList: filtersSelectors.getIndustryFilterList(state),
      subIndustryFilterList: filtersSelectors.getSubIndustryFilterList(state),
      absSectorFilterList: filtersSelectors.getAbsSectorFilterList(state),
      absSubSectorFilterList: filtersSelectors.getAbsSubSectorFilterList(state),
      accountTypeFilterList: filtersSelectors.getAccountTypeFilterList(state),
      accountFilterList: filtersSelectors.getAccountFilterList(state),
      currencyFilterList: filtersSelectors.getCurrencyFilterList(state),
      ratingGroupFilterList: filtersSelectors.getRatingGroupFilterList(state),
      ratingAgencyFilterList: filtersSelectors.getRatingAgencyFilterList(state),
      debtCouponTypeFilterList: filtersSelectors.getDebtCouponTypeFilterList(state),
      sponsorFilterList: filtersSelectors.getSponsorFilterList(state),
      underwriterFilterList: filtersSelectors.getUnderwriterFilterList(state),
      allocationFilterRange: filtersSelectors.getAllocationFilterRange(state),
      spreadFilterRange: filtersSelectors.getSpreadFilterRange(state),
      tenorWalYearsFilterRange: filtersSelectors.getTenorWalYearsFilterRange(state),
      yieldFilterRange: filtersSelectors.getYieldFilterRange(state),
      firstLoginAt: filtersSelectors.getFirstLoginAt(state),
      isFetching: filtersSelectors.isFetching(state),
      appliedFilters: filtersSelectors.getAppliedFilters(state),
      filtersPayload: filtersSelectors.getFiltersPayload(state),
      allocationFiltersPayload: filtersSelectors.getAllocationFiltersPayload(state),
      isAllocationZerocase: filtersSelectors.isAllocationZerocase(state),
      isFiltersPayload: !isEmpty(filtersSelectors.getFiltersPayload(state)),
      isAccountFetching: filtersSelectors.isAccountFetching(state),
      isSponsorFetching: filtersSelectors.isSponsorFetching(state),
      isUnderwritersFetching: filtersSelectors.isUnderwritersFetching(state),
      debtUpsizedFilter: filtersSelectors.getDebtUpsizedFilter(state),
    }),
    [state],
  );

  const isZerocase = useMemo(() => {
    return (
      isEmpty(stateSelectors.transactionTypeFilterList.allIds) &&
      isEmpty(stateSelectors.industryFilterList.allIds) &&
      isEmpty(stateSelectors.absSectorFilterList.allIds) &&
      isEmpty(stateSelectors.accountTypeFilterList.allIds) &&
      isEmpty(stateSelectors.accountFilterList) &&
      isEmpty(dealsFilters.deals) &&
      isEmpty(stateSelectors.sponsorFilterList) &&
      isEmpty(stateSelectors.underwriterFilterList)
    );
  }, [stateSelectors, dealsFilters]);

  const isLoading = stateSelectors.isFetching || isDealsFetching;

  return {
    ...stateSelectors,
    ...actions,
    isAppliedFilters,
    setAppliedFilters,
    getDealsFilters,
    dealsFilters,
    isZerocase,
    isLoading,
    isSidebarOpen,
    sidebarOpen,
    sidebarClose,
  };
};

const GlobalAnalyticsFiltersContext = createContext<GlobalAnalyticsFiltersContextType>(null);

export const useGlobalAnalyticsFiltersContext = () => {
  const context = useContext(GlobalAnalyticsFiltersContext);
  if (!context) {
    throw new Error('useGlobalAnalyticsFiltersContext must be used within a GlobalAnalyticsFiltersContext');
  }
  return context;
};

const GlobalAnalyticsFiltersContextProvider = ({ children }) => (
  <GlobalAnalyticsFiltersContext.Provider value={ useGlobalAnalyticsFilters() }>
    { children }
  </GlobalAnalyticsFiltersContext.Provider>
);

export default GlobalAnalyticsFiltersContextProvider;
