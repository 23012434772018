import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import { IAccountActivity as IRoadshowAccountActivity } from '@/dealroadshow/domain/vo/crossDealAnalytics/AccountActivity';
import { IContactActivity as IRoadshowContactActivity } from '@/dealroadshow/domain/vo/crossDealAnalytics/ContactActivity';
import { IAccountDetails } from '@/dmPortal/domain/vo/crossDealAnalytics/AccountDetails';
import { IContactDetails } from '@/dmPortal/domain/vo/crossDealAnalytics/ContactDetails';
import { IRoadshowActivity } from '@/dmPortal/domain/vo/crossDealAnalytics/RoadshowActivity';
import {
  IAccountFilter,
  IDealsFilters,
  IFiltersDealsOptions,
  IFiltersOptions,
  ISponsorFilter,
  IUnderwriterFilter,
} from '@/dmPortal/domain/vo/crossDealAnalytics/Filters';

@Dependencies(RpcDispatcher)
class RoadshowCrossDealAnalyticsRepository {
  constructor(private readonly rpc: typeof RpcDispatcher) {}

  /**
   * @param {Object} filters
   * @param {Object} options
   */
  getMetadata(filters = {}, options) {
    const defaultOptions = {
      extraFields: ['viewTimeSum'],
      groupBy: 'dealManager',
    };
    options = { ...defaultOptions, ...options };
    let payload = {
      filters,
      ...options,
    };
    return this.getInvestorsActivity(payload);
  }

  /**
   * @param {{filters: {}, options: {}}}
   */
  getDealsActivity({ filters = {}, options }): Promise<{
    collection: IRoadshowActivity[],
    roadshowTotalCount: number,
  }> {
    const defaultOptions = {
      extraFields: ['viewTimeSum'],
      sortOrder: 'DESC',
      groupBy: 'roadshow',
    };
    options = { ...defaultOptions, ...options };
    options.sortOrder = options.sortOrder.toUpperCase();
    let payload = {
      filters,
      ...options,
    };
    return this.getInvestorsActivity(payload);
  }

  getAccountsActivity({ filters = {}, options }): Promise<{
    collection: IRoadshowAccountActivity[],
    accountTotalCount: number,
  }> {
    const defaultOptions = {
      extraFields: ['viewTimeSum'],
      sortOrder: 'DESC',
      groupBy: 'account',
    };
    options = { ...defaultOptions, ...options };
    options.sortOrder = options.sortOrder.toUpperCase();
    let payload = {
      filters,
      ...options,
    };
    return this.getInvestorsActivity(payload);
  }

  /**
   * @param {Object} filters
   * @param {Object} options
   */
  getAccountsActivityByType(filters = {}, options) {
    const defaultOptions = {
      extraFields: ['viewTimeSum'],
      sortOrder: 'DESC',
      page: 1,
      perPage: 100,
      groupBy: 'accountType',
    };
    options = { ...defaultOptions, ...options };
    options.sortOrder = options.sortOrder.toUpperCase();
    let payload = {
      filters,
      ...options,
    };
    return this.getInvestorsActivity(payload);
  }

  getContactsActivity({ filters = {}, options }): Promise<{
    collection: IRoadshowContactActivity[],
    contactTotalCount: number,
  }> {
    const defaultOptions = {
      extraFields: ['viewTimeSum'],
      sortOrder: 'DESC',
      groupBy: 'contact',
    };
    options = { ...defaultOptions, ...options };
    options.sortOrder = options.sortOrder.toUpperCase();
    let payload = {
      filters,
      ...options,
    };
    return this.getInvestorsActivity(payload);
  }

  /**
   * @param {Object} payload
   * @return {Promise}
   */
  async getInvestorsActivity(payload) {
    const request = new Request('roadshow.analytics.cross_deal.get_investors_activity', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  }

  /**
   * @param filters
   * @param options
   *
   * @return {Promise}
   */
  async getTtmFilter(filters, options) {
    const request = new Request('roadshow.analytics.cross_deal.get_ttm_filter', { filters, ...options });
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  }

  /**
   * @param filters
   * @param options
   */
  async getCrossDealFilters(filters, options) {
    const request = new Request('roadshow.analytics.cross_deal.get_investors_activity_filter', { filters, ...options });
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  }

  async getDealsFilters(filters, options: IFiltersDealsOptions): Promise<IDealsFilters> {
    const request = new Request('roadshow.analytics.cross_deal.get_deals_for_filter', { filters, ...options });
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  }

  async getAccountFilters(filters, options: IFiltersOptions): Promise<IAccountFilter[]> {
    const request = new Request('roadshow.analytics.cross_deal.get_accounts_for_filter', { filters, ...options });
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  }

  async getSponsorFilters(filters, options: IFiltersOptions): Promise<ISponsorFilter[]> {
    const request = new Request('roadshow.analytics.cross_deal.get_sponsor_companies_for_filter', {
      filters,
      ...options,
    });
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  }

  async getUnderwriterFilters(filters, options: IFiltersOptions): Promise<IUnderwriterFilter[]> {
    const request = new Request('roadshow.analytics.cross_deal.get_underwriters_for_filter', { filters, ...options });
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  }

  /**
   * @param {Object} filters
   */
  async getAccountDetails(filters = {}): Promise<IAccountDetails> {
    const request = new Request('roadshow.analytics.cross_deal.get_account_details', { ...filters });
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  }

  /**
   * @param {Object} filters
   */
  async getContactDetails(filters): Promise<IContactDetails> {
    const request = new Request('roadshow.analytics.cross_deal.get_contact_details', { ...filters });
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  }

  /**
   * @param {Object} filters
   * @param {Object} options
   */
  async hasUserAccessToCrossDealActivity(filters, options) {
    const request = new Request('roadshow.analytics.cross_deal.get_access', { filters, ...options });
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  }

  /**
   * @param {Object} filters
   * @param {Object} options
   */
  async getCrossDealActivityTotalCount(filters, options) {
    const request = new Request('roadshow.analytics.cross_deal.get_count', { filters, ...options });
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  }

  /**
   * Checks whether user has access to cross deal analytics across tenants.
   */
  async hasUserAccessToCrossDealAnalytics() {
    const request = new Request('roadshow.analytics.cross_deal.get_common_access');
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  }
}

export default RoadshowCrossDealAnalyticsRepository;
