import React, { useMemo } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { components as ReactSelectComponents } from 'react-select';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import styles from '../../allocations.scss';
import FinalFormSelect from '@/ui/shared/components/Form/Select/legacy/FinalFormSelect';

interface IProps {
  fieldComponent: React.ElementType,
}

const filterByName = 'includeCurrencyIds';

const Currency = ({
  fieldComponent: Field,
}: IProps) => {
  const { currencyFilterList } = useGlobalAnalyticsFiltersContext();

  const options = useMemo(() => currencyFilterList?.allIds.map((currencyId) => {
    const currency: any = currencyFilterList?.byId[currencyId] || {};
    return {
      value: currency?.currencyId,
      label: `${ currency?.currencyName } (${ currency?.currencyDisplayName })`,
      abbreviation: currency?.currencyDisplayName,
      canChangeResponse: currency?.canChangeResponse,
    };
  }), [currencyFilterList?.allIds]);

  if (isEmpty(currencyFilterList?.allIds)) {
    return null;
  }
  const abbreviationValueRenderer = ({ data, ...otherProps }) => (
    // @ts-ignore
    <ReactSelectComponents.MultiValueLabel { ...otherProps }>
      { data.abbreviation || data.label }
    </ReactSelectComponents.MultiValueLabel>
  );

  return (
    <>
      <div className={ styles.fieldLabel }>Currency</div>
      <Field
        name={ filterByName }
        disabled={ !options.length }
        component={ FinalFormSelect }
        placeholder="Select a Currency..."
        options={ options }
        isMulti
        components={ {
          MultiValueLabel: abbreviationValueRenderer,
        } }
        isNarrow
        isOptionDisabled={ (option) => !option.canChangeResponse }
        formatOptionLabel={ ({ label, canChangeResponse }) => (
          <div className={ cn({ [styles.disabled]: !canChangeResponse }) }>
            { label }
          </div>
        ) }
        hideSelectedOptions={ false }
        closeMenuOnSelect={ false }
        dataTest="allocationFilterCurrencySelect"
      />
    </>
  );
};

export default Currency;
