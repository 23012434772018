import { validateEmail } from '@/ui/shared/validators/user/validateEmail';
import { validateFieldsArrayForFinalForm } from '@/ui/shared/validators/validateFieldsArrayForFinalForm';
import { validateDuplicate } from '@/ui/shared/validators/validateDuplicate';

const paramsDealManagers = [
  {
    fieldName: 'corporateEmail',
    fieldCaption: 'Corporate Email',
    functionValidation: [
      {
        name: validateEmail,
        params: {},
      },
      {
        name: validateDuplicate,
        params: {
          listName: 'Deal Managers',
        },
      },
    ],
  },
];

export default (values) => validateFieldsArrayForFinalForm(
  'dealManagers', paramsDealManagers, { collection: values.dealManagers },
);
