import { Class, Transient, Value, Method } from 'constitute';
import ProcessingAdapter from './SocketProcessingAdapter';
import files from '@/Framework/url/filesUrl';
import { MultipartUploadStrategy, UploadDispatcher, MultipartUploadAdapter } from '@dealroadshow/file-uploader';

const UploadAdapter = new Class(MultipartUploadAdapter, Transient.with([
  new Value({ url: files.getBaseUrl() }),
]));

const factory = (processingAdapter, uploadAdapter) => {
  return new UploadDispatcher(new MultipartUploadStrategy(uploadAdapter, processingAdapter));
};

export default new Method(factory, [ProcessingAdapter, UploadAdapter]) as ReturnType<typeof factory>;
