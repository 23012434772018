import React, { useState, useCallback } from 'react';
import * as constants from '@/ui/shared/modules/Analytics/constants';
import Allocations from './Allocations';
import TransactionType from './TransactionType';
import Industry from './Industry';
import AbsSector from './AbsSector';
import AccountType from './AccountType';
import Account from './Account';
import CompanyParent from './CompanyParent';
import Deals from './Deals';
import Underwriters from './Underwriters';
import styles from '@/ui/shared/modules/Filters/FiltersPanel/sidebar.scss';

interface IProps {
  fieldComponent: React.ElementType,
  form: any,
  formValues: any,
  filtersPayload: any,
  resetTimestamp: string,
  isZerocase: boolean,
}

function Sections({ isZerocase, form, formValues, fieldComponent: Field, filtersPayload, resetTimestamp }: IProps) {
  const [hiddenSections, setHiddenSections] = useState([]);

  /**
   * @param {String} activeKey
   */
  const handleSectionToggle = useCallback((activeKey) => {
    setHiddenSections((sections) => {
      if (sections.includes(activeKey)) {
        return sections.filter((section) => section !== activeKey);
      }

      return [...sections, activeKey];
    });
  }, []);

  if (isZerocase) {
    return (
      <div className={ styles.zerocase }>{ constants.ZEROCASE_LABEL }</div>
    );
  }

  return (
    <>
      <Allocations
        form={ form }
        fieldComponent={ Field }
        sectionId="allocationFilterList"
        sectionTitle="Allocations"
        isVisible={ !hiddenSections.includes('allocationFilterList') }
        onSectionToggle={ handleSectionToggle }
        formValues={ {
          includeRatingGroupIds: formValues?.includeRatingGroupIds || [],
          includeRatingAgencyIds: formValues?.includeRatingAgencyIds || [],
          includeDebtCouponTypeIds: formValues?.includeDebtCouponTypeIds || [],
        } }
      />
      <TransactionType
        form={ form }
        fieldComponent={ Field }
        sectionId="transactionTypeFilterList"
        sectionTitle="Finsight Application & Transaction Type"
        isVisible={ !hiddenSections.includes('transactionTypeFilterList') }
        onSectionToggle={ handleSectionToggle }
        formValues={ {
          includeTransactionTypeIds: formValues?.includeTransactionTypeIds || [],
          includeTransactionTypeTenants: formValues?.includeTransactionTypeTenants || [],
        } }
      />
      <Industry
        form={ form }
        fieldComponent={ Field }
        sectionId="industryFilterList"
        sectionTitle="Industry & Subindustry"
        isVisible={ !hiddenSections.includes('industryFilterList') }
        onSectionToggle={ handleSectionToggle }
        formValues={ {
          includeIndustryIds: formValues?.includeIndustryIds || [],
          includeSubIndustryIds: formValues?.includeSubIndustryIds || [],
        } }
      />
      <AbsSector
        form={ form }
        fieldComponent={ Field }
        sectionId="absSectorFilterList"
        sectionTitle="Abs Sector & Subsector"
        isVisible={ !hiddenSections.includes('absSectorFilterList') }
        onSectionToggle={ handleSectionToggle }
        formValues={ {
          includeAbsSectorIds: formValues?.includeAbsSectorIds || [],
          includeAbsSubSectorIds: formValues?.includeAbsSubSectorIds || [],
        } }
      />
      <CompanyParent
        fieldComponent={ Field }
        sectionId="sponsorFilterList"
        sectionTitle="Company / Parent"
        isVisible={ !hiddenSections.includes('sponsorFilterList') }
        onSectionToggle={ handleSectionToggle }
        filtersPayload={ filtersPayload }
      />
      <Underwriters
        fieldComponent={ Field }
        sectionId="underwriterFilterList"
        sectionTitle="Underwriter"
        isVisible={ !hiddenSections.includes('underwriterFilterList') }
        onSectionToggle={ handleSectionToggle }
        filtersPayload={ filtersPayload }
      />
      <AccountType
        form={ form }
        fieldComponent={ Field }
        sectionId="accountTypeList"
        sectionTitle="Account Type"
        isVisible={ !hiddenSections.includes('accountTypeList') }
        onSectionToggle={ handleSectionToggle }
        formValues={ formValues?.includeAccountTypeIds || [] }
      />
      <Account
        fieldComponent={ Field }
        sectionId="accountFilterList"
        sectionTitle="Account"
        isVisible={ !hiddenSections.includes('accountFilterList') }
        onSectionToggle={ handleSectionToggle }
        filtersPayload={ filtersPayload }
      />
      <Deals
        sectionId="dealFilterList"
        sectionTitle="Deals"
        isVisible={ !hiddenSections.includes('dealFilterList') }
        onSectionToggle={ handleSectionToggle }
        filtersPayload={ filtersPayload }
        resetTimestamp={ resetTimestamp }
      />
    </>
  );
}

export default Sections;
