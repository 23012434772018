import PresentationMediaRepository from '@/dealroadshow/infrastructure/repository/PresentationMediaRepository';
import * as actionTypes from '../actionTypes';
import { IUploadProcessing, ProcessingEvent, UploadEvent } from '@dealroadshow/file-uploader';

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadStart(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_MEDIA_AUDIO_ADD,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadChunkSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_MEDIA_AUDIO_CHUNK_SUCCESS,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_MEDIA_AUDIO_SUCCESS,
    payload,
  };
}

/**
 * chunk or combination
 * @param {Object} payload
 * @return {Object}
 */
function uploadError(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_MEDIA_AUDIO_ERROR,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadProcessing(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_MEDIA_AUDIO_PROCESSING,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadDone(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_MEDIA_AUDIO_DONE,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadCancelSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_MEDIA_AUDIO_CANCEL_SUCCESS,
    payload,
  };
}

/**
 * @param {String} uuid
 */
export function uploadPresentationMediaAudioCancel(uuid) {
  return async (dispatch, getState) => {
    dispatch({
      type: actionTypes.UPLOAD_PRESENTATION_MEDIA_AUDIO_CANCEL,
      payload: {
        uuid,
      },
    });

    if (getState().upload.presentationMediaAudio.callback
        && typeof getState().upload.presentationMediaAudio.callback.cancel === 'function') {
      getState().upload.presentationMediaAudio.callback.cancel();
    } else if (getState().upload.presentationMediaAudio.process.uuid) {
      dispatch(uploadCancelSuccess({
        uuid: getState().upload.presentationMediaAudio.process.uuid,
      }));
    }
  };
}

/**
 * @param {Array} payload
 */
export function initUploadPresentationMediaAudio(payload) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_PRESENTATION_MEDIA_AUDIO_INIT,
      payload,
    });
  };
}

/**
 * @param {Array.<File>} files
 */
export function uploadPresentationMediaAudio(files) {
  return async (dispatch, getState) => {
    if (getState().upload.presentationMediaAudio.process.uuid) {
      uploadPresentationMediaAudioCancel(getState().upload.presentationMediaAudio.process.uuid);
    }

    const presentationMediaRepository = getState().container.get(PresentationMediaRepository);
    const upload: IUploadProcessing = await presentationMediaRepository.uploadAudio(files[0]);

    dispatch(uploadStart({
      uploadFile: files[0],
      callback: upload,
      uuid: upload.getUuid(),
    }));

    upload
      .on(UploadEvent.uploadChunkDone, (data) => dispatch(uploadChunkSuccess(data)))
      .on(UploadEvent.error, (data) => dispatch(uploadError(data)))
      .on(UploadEvent.uploadDone, (data) => dispatch(uploadSuccess(data)))
      .on(ProcessingEvent.processing, (data) => dispatch(uploadProcessing(data)))
      .on(ProcessingEvent.processingDone, (data) => dispatch(uploadDone(data)))
      .on(UploadEvent.cancel, (data) => dispatch(uploadCancelSuccess(data)));
  };
}
