import { groupValidators } from '@/ui/shared/validators/helpers';
import { validateMinLength } from '@/ui/shared/validators/validateMinLength';
import { validateMaxLength } from '@/ui/shared/validators/validateMaxLength';
import { validateRequiredFields } from '@/ui/shared/validators/validateRequiredFields';
import { validateAngleBracketsPresence } from '@/ui/shared/validators/validateAngleBracketsPresence';

export default (values) => groupValidators(
  validateRequiredFields([{
      fieldName: 'dealName',
      fieldCaption: 'Allocation Name',
  }], values),
  validateMinLength({
    fieldName: 'dealName',
    fieldCaption: 'Allocation Name',
    value: values.dealName,
    length: 3,
  }),
  validateMaxLength({
    fieldName: 'dealName',
    fieldCaption: 'Allocation Name',
    value: values.dealName,
    length: 128,
  }),
  validateAngleBracketsPresence({
    fieldName: 'dealName',
    fieldCaption: 'Allocation Name',
    value: values.dealName,
  }),
);
