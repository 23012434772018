import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isFinite';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import RenderRangeField from '../RenderRangeField';
import { normalizeMinValue, normalizeMaxValue } from '../normalizers';
import styles from '../../allocations.scss';

interface IProps {
  fieldComponent: React.ElementType,
  onChangeRange: (filterByName: string, initialValue: any) => void,
}

const filterByName = 'yieldSizeRange';

const multiplyByOneHundred = (value) => {
  const multipliedValue = Math.round(value * 100);
  if (isNumber(multipliedValue)) {
    return multipliedValue;
  }
  return value;
};

const divideOnOneHundred = (value) => {
  const dividedValue = value / 100;
  if (isNumber(dividedValue)) {
    return dividedValue;
  }
  return value;
};

const Yield = ({
  fieldComponent: Field,
  onChangeRange,
}: IProps) => {
  const { yieldFilterRange, allocationFiltersPayload } = useGlobalAnalyticsFiltersContext();

  useEffect(() => {
    // @ts-ignore
    if (!allocationFiltersPayload?.yieldFrom && !allocationFiltersPayload?.yieldTo) {
      onChangeRange(filterByName, initialValue);
    }
  }, [yieldFilterRange?.range]);

  if (isEmpty(yieldFilterRange?.range)) {
    return null;
  }

  const initialValue = {
    range: {
      min: yieldFilterRange?.range?.min,
      max: yieldFilterRange?.range?.max,
    },
    minValue: yieldFilterRange?.range?.min,
    maxValue: yieldFilterRange?.range?.max,
  };

  return (
    <>
      <div className={ styles.fieldLabel }>Yield (%)</div>
      <Field
        name={ filterByName }
        disabled={ !yieldFilterRange?.range.canChangeResponse }
        render={ RenderRangeField }
        minValue={ normalizeMinValue(multiplyByOneHundred(initialValue.minValue)) }
        maxValue={ normalizeMaxValue(multiplyByOneHundred(initialValue.maxValue)) }
        step={ 1 }
        format={ ({ range = {}, ...rest }) => ({
          ...rest,
          range: {
            // @ts-ignore
            min: range?.min || range?.min === 0 ? normalizeMinValue(multiplyByOneHundred(range?.min)) : 0,
            // @ts-ignore
            max: range?.max || range?.max === 0 ? normalizeMaxValue(multiplyByOneHundred(range?.max)) : 0,
          },
        }) }
        parse={ (value) => ({
          range: {
            min: value?.min || value?.min === 0 ? divideOnOneHundred(value?.min) : null,
            max: value?.max || value?.max === 0 ? divideOnOneHundred(value?.max) : null,
          },
          minValue: divideOnOneHundred(value?.minValue),
          maxValue: divideOnOneHundred(value?.maxValue),
        }) }
      />
    </>
  );
};

export default Yield;
