import { roundUpValueToFraction } from '@/Framework/dataHelpers/number/roundUpValueToFraction';

export const formatSubscriptionLevel = (value: number) => {
  const subscriptionLevel = Number(value);
  if (subscriptionLevel > 0 && subscriptionLevel < 0.01) return '0.01x';
  return `${ roundUpValueToFraction(subscriptionLevel, 2).toFixed(2) }x`;
};

export const getDigitsAfterDecimalCount = (value: number) => {
  const hasDecimal = value % 1 !== 0;
  return hasDecimal ? value.toString().split('.')[1].length : 0;
};
