/**
 * @param {number} maxSize
 * @param {string} maxSizeUnits
 *
 * @return {number}
 */
export const getMaxSizeValue = (maxSize, maxSizeUnits) => {
  switch (maxSizeUnits) {
    case 'MB':
      return Math.round(maxSize / 1024 ** 2);
    case 'GB':
      return Math.round(maxSize / 1024 ** 3);
    default:
      return maxSize;
  }
};
