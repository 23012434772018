import { AppliedFiltersType, FiltersPayloadType } from '@/dealroadshow/domain/vo/dmPortal/analytics/Filters';
import { useDIContext } from '@/Framework/DI/DIContext';
import SessionStorageRepository from '@/Framework/browser/storage/SessionStorageRepository';

const ROADSHOW_SESSION_STORAGE_KEY = 'roadshow';

type SavedFiltersType = {
  appliedFilters: AppliedFiltersType,
  filtersPayload: FiltersPayloadType,
};

export const useFilterStorage = () => {
  const { container } = useDIContext();

  const sessionStorageRepository = container.get(SessionStorageRepository);

  const getLocalFiltersState = (roadshowId: string): SavedFiltersType | null => {
    const state = JSON.parse(sessionStorageRepository.getItem(ROADSHOW_SESSION_STORAGE_KEY));

    if (state && state[roadshowId]) {
      return state[roadshowId].filters;
    }

    return null;
  };

  const saveLocalFiltersState = (roadshowId: string, filters: SavedFiltersType) => {
    const state = JSON.parse(sessionStorageRepository.getItem(ROADSHOW_SESSION_STORAGE_KEY));

    const initialState = {
      [roadshowId]: {
        filters,
      },
    };

    if (state) {
      const newState = {
        ...state,
        [roadshowId]: {
          ...state[roadshowId],
          filters,
        },
      };

      return sessionStorageRepository.setItem(ROADSHOW_SESSION_STORAGE_KEY, JSON.stringify(newState));
    }

    return sessionStorageRepository.setItem(ROADSHOW_SESSION_STORAGE_KEY, JSON.stringify(initialState));
  };

  const removeLocalFiltersState = (roadshowId: string) => {
    const state = JSON.parse(sessionStorageRepository.getItem(ROADSHOW_SESSION_STORAGE_KEY));
    if (state) {
      const newState = { ...state };
      if (roadshowId in newState) {
        delete newState[roadshowId].filters;
      }
      return sessionStorageRepository.setItem(ROADSHOW_SESSION_STORAGE_KEY, JSON.stringify(newState));
    }
    return sessionStorageRepository.removeItem(ROADSHOW_SESSION_STORAGE_KEY);
  };

  return {
    getLocalFiltersState,
    saveLocalFiltersState,
    removeLocalFiltersState,
  };
};
