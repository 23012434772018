import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';

const setLocalZone = (timeZone: string, timestamp: number) => {
  if (!timestamp) {
    return null;
  }

  return getUnixTimestamp(utcToZonedDate(timeZone, getDateFromUnixTime(timestamp)));
};

export default setLocalZone;
