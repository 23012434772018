import React from 'react';
import { TabLink as NextNavLink } from '@/ui/shared/components/Next/Link';
import cn from 'classnames';
import { tenantsConfig } from '@/dmPortal/application/GlobalAnalytics/config';
import GlobalAnalyticsContextProvider, {
  useGlobalAnalyticsContext,
} from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsContext';
import GlobalAnalyticsFiltersContextProvider, {
  useGlobalAnalyticsFiltersContext,
} from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import Sidebar from './common/Sidebar';
import FiltersBtn from './common/FiltersBtn';
import { Spinner } from '@dealroadshow/uikit';

import headersStyles from '@/ui/shared/styles/headers.scss';
import pageStyles from '@/ui/shared/styles/page.scss';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import styles from './GlobalAnalytics.scss';

interface IProps {
  children: React.ReactNode,
}

const GlobalAnalytics = ({ children }: IProps) => {
  const { isTenantActive, relativePath, isInitialized } = useGlobalAnalyticsContext();
  const { sidebarOpen } = useGlobalAnalyticsFiltersContext();

  if (!isInitialized) return <Spinner />;

  return (
    <>
      <div className={ cn(pageStyles.pageHeaderWithActions, spacesStyles.mbm) }>
        <div className={ cn(pageStyles.pageHeaderTitle, headersStyles.isH2) } data-test="pageHeaderTitle">
          Global Analytics
        </div>
        <div className={ pageStyles.pageHeaderActionsWrapper }>
          <FiltersBtn onClick={ sidebarOpen } />
        </div>
      </div>
      <div className={ pageStyles.pageNavigation }>
        <div className={ pageStyles.pageNavigationLinks }>
          { Object.values(tenantsConfig).map(({ code, path, title }) => (
            <NextNavLink
              key={ path }
              className={ cn({
                [styles.disabledNavLink]: code === tenantsConfig.allocate.code
                  ? !isTenantActive(tenantsConfig.roadshows.code)
                  : !isTenantActive(code),
              }) }
              activeClassName={ pageStyles.isActiveNavLink }
              to={ relativePath(path) }
              dataTest={ `${ code }Link` }
            >
              { title }
            </NextNavLink>
          )) }
        </div>
      </div>
      { children }
      <Sidebar />
    </>
  );
};

export default ({ children }: IProps) => (
  <GlobalAnalyticsContextProvider>
    <GlobalAnalyticsFiltersContextProvider>
      <GlobalAnalytics>
        { children }
      </GlobalAnalytics>
    </GlobalAnalyticsFiltersContextProvider>
  </GlobalAnalyticsContextProvider>
);
