import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import styles from '../../allocations.scss';
import RenderRangeField from '../RenderRangeField';
import { normalizeMinValue, normalizeMaxValue } from '../normalizers';

interface IProps {
  fieldComponent: React.ElementType,
  onChangeRange: (filterByName: string, initialValue: any) => void,
}

const filterByName = 'tenorWalYearsRange';

const TenorWalYears = ({
  fieldComponent: Field,
  onChangeRange,
}: IProps) => {
  const { tenorWalYearsFilterRange, allocationFiltersPayload } = useGlobalAnalyticsFiltersContext();

  useEffect(() => {
    // @ts-ignore
    if (!allocationFiltersPayload?.tenorWalYearsFrom && !allocationFiltersPayload?.tenorWalYearsTo) {
      onChangeRange(filterByName, normalizeValue);
    }
  }, [tenorWalYearsFilterRange?.range]);

  if (isEmpty(tenorWalYearsFilterRange?.range)) {
    return null;
  }

  const normalizeValue = {
    range: {
      min: normalizeMinValue(tenorWalYearsFilterRange?.range.min),
      max: normalizeMaxValue(tenorWalYearsFilterRange?.range.max),
    },
    minValue: normalizeMinValue(tenorWalYearsFilterRange?.range.min),
    maxValue: normalizeMaxValue(tenorWalYearsFilterRange?.range.max),
  };

  const initialValue = {
    range: {
      min: tenorWalYearsFilterRange?.range.min,
      max: tenorWalYearsFilterRange?.range.max,
    },
    minValue: tenorWalYearsFilterRange?.range.min,
    maxValue: tenorWalYearsFilterRange?.range.max,
  };

  return (
    <>
      <div className={ styles.fieldLabel }>TENOR / WAL (YEARS)</div>
      <Field
        name={ filterByName }
        disabled={ !tenorWalYearsFilterRange?.range.canChangeResponse }
        render={ RenderRangeField }
        minValue={ normalizeMinValue(initialValue.minValue) }
        maxValue={ normalizeMaxValue(initialValue.maxValue) }
        step={ 0.1 }
        parse={ (value) => ({
          range: {
            min: value?.min,
            max: value?.max,
          },
          minValue: value?.minValue,
          maxValue: value?.maxValue,
        }) }
      />
    </>
  );
};

export default TenorWalYears;
