import React from 'react';
import DebtHeader from './DebtHeader';
import EquityHeader from './EquityHeader';
import { IDebtSecurities, IEquitySecurities } from '@/allocate/domain/vo/Allocations/Analytics/SecutiryDetails';
import { AllocationsTypes } from '@/allocate/application/config';

interface IProps {
  type: AllocationsTypes,
  securityDetails: IDebtSecurities | IEquitySecurities,
}

export default ({ type, securityDetails }: IProps) => {
  if (type === AllocationsTypes.DEBT) {
    return <DebtHeader securityDetails={ securityDetails as IDebtSecurities } />;
  }

  if (type === AllocationsTypes.EQUITY) {
    return <EquityHeader securityDetails={ securityDetails as IEquitySecurities } />;
  }

  return null;
};
