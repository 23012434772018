import RoadshowAnalyticsRepository from '@/dealroadshow/infrastructure/repository/analytics/RoadshowAnalyticsRepository';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import * as actionTypes from './actionTypes';
import RoadshowType from '@/dealroadshow/domain/RoadshowType';
import * as roadshowSelectors from '@/dealroadshow/application/actions/data/selectors';

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestDealMetadata(payload) {
  return {
    type: actionTypes.REQUEST_DEAL_METADATA,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestDealMetadataSuccess(payload) {
  return {
    type: actionTypes.REQUEST_DEAL_METADATA_SUCCESS,
    payload,
  };
}

/**
 * @param {String} error
 * @return {Object}
 */
function requestDealMetadataError(error) {
  return {
    type: actionTypes.REQUEST_DEAL_METADATA_ERROR,
    error,
  };
}

/**
 * Get roadshow by id
 *
 * @param {String} roadshowId
 */
export function getDealMetadata(roadshowId) {
  return async (dispatch, getState) => {
    dispatch(requestDealMetadata({ roadshowId }));
    try {
      const roadshowAnalyticsRepository = getState().container.get(RoadshowAnalyticsRepository);
      const response = await roadshowAnalyticsRepository.getInvestorsActivity({
        filters: { roadshowId },
        timeZone: roadshowSelectors.getRoadshowTimeZone(getState()),
        groupBy: 'roadshow',
        includeEmpty: true,
      });
      const roadshowRepository = getState().container.get(RoadshowRepository);
      const isAdminAnalyticsOnly = await roadshowRepository.isAdminAnalyticsOnlyByRoadshowId({ roadshowId });
      if (response.collection && response.collection.length) {
        let metadata = {
          ...response.collection[0],
          isVideoOnly: RoadshowType.isVideoOnly(response.collection[0].roadshowType),
          isAdminAnalyticsOnly,
        };
        dispatch(requestDealMetadataSuccess(metadata));
        return response;
      }
      throw response;
    } catch (error) {
      dispatch(requestDealMetadataError(error));
      throw error;
    }
  };
}

/**
 * @return {Object}
 */
export function resetMetadata() {
  return {
    type: actionTypes.RESET_DEAL_METADATA,
  };
}
