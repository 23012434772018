import { ReactNode } from 'react';
import { Button, ButtonVariantType } from '@dealroadshow/uikit';
import FormStep from './FormStep';
import DealManagersField from './DealManagersField';
import { useBulkAddDealManagersFormContext } from './context/BulkAddDealManagersFormContext';

import styles from './bulkAddDealManagersModal.scss';

interface IProps {
  onClose: () => void,
  children: ReactNode,
}

const FormFields = ({ children, onClose }: IProps) => {
  const {
    countInvalidEmails,
    countExistEmails,
    countEmails,
  } = useBulkAddDealManagersFormContext();

  return (
    <div>
      <div className={ styles.formContainer }>
        <FormStep num={ 1 } title="Add Deal Manager(s) by corporate emails">
          <DealManagersField />
        </FormStep>
        { children }
      </div>
      <Button
        type="submit"
        variant={ ButtonVariantType.action }
        title={ `Add Deal Managers (${ countEmails })` }
        dataTest="bulkAddDealManagersButtonAdd"
        disabled={ countInvalidEmails > 0 || countExistEmails > 0 || countEmails === 0 }
      />
      <Button
        variant={ ButtonVariantType.text }
        title="Cancel"
        dataTest="bulkAddDealManagersButtonCancel"
        onClick={ onClose }
      />
    </div>
  );
};

export default FormFields;
