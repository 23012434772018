import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import { RoadshowRole } from '@/dealroadshow/domain/vo/RoadshowRole';

const unknownRole = {
  isAdmin: false,
  currentDealroadshowRole: null,
};

const useRoadshowRole = () => {
  const { container } = useDIContext();
  const roadshowRepository = container.get<RoadshowRepository>(RoadshowRepository);

  const [{ isAdmin, currentDealroadshowRole }, setRole] = useState(unknownRole);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const getCurrentUserRole = async (roadshowId?: string) => {
    setIsLoading(true);

    try {
      const response = await roadshowRepository.getCurrentRole(roadshowId);
      setRole(response);
    } catch (e) {
      setRole(unknownRole);
    } finally {
      setIsLoading(false);
      setIsLoaded(true);
    }
  };

  const isManager = currentDealroadshowRole === RoadshowRole.Manager;
  const isManagerAnalyticsOnly = currentDealroadshowRole === RoadshowRole.ManagerAnalyticsOnly;

  return {
    getCurrentUserRole,
    isLoaded,
    isLoading,
    isAdmin,
    isManager,
    isManagerAnalyticsOnly,
  };
};

export default useRoadshowRole;
