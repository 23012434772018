import { combineReducers } from 'redux';
import actionTypesFactory from './actionTypes';
import config from '@/Framework/config';

const initialState = {
  collection: [],
  isCollectionFetching: false,
};

const transactionType = (tenant) => {
  const actionTypes = actionTypesFactory(tenant);

  return (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.GET_TRANSACTION_TYPES:
        return {
          ...state,
          isCollectionFetching: true,
        };
      case actionTypes.GET_TRANSACTION_TYPES_SUCCESS:
        return {
          ...state,
          isCollectionFetching: false,
          collection: action.payload,
        };
      case actionTypes.GET_TRANSACTION_TYPES_ERROR:
        return {
          ...state,
          isCollectionFetching: false,
        };
      default:
        return state;
    }
  };
};

export default combineReducers({
  [config.tenant.tenantDealvdr.code]: transactionType(config.tenant.tenantDealvdr.code),
  [config.tenant.tenant17g5.code]: transactionType(config.tenant.tenant17g5.code),
  [config.tenant.dealroadshow.code]: transactionType(config.tenant.dealroadshow.code),
  [config.tenant.tenantInvestorSet.code]: transactionType(config.tenant.tenantInvestorSet.code),
});
