/**
 * Splitting a JS array into N arrays
 *
 * @param {Array} array
 * @param {Number} n
 * @return {Array}
 */
export default function splitArray(array, n) {
  let rest = array.length % n;
  let restUsed = rest;
  let partLength = Math.floor(array.length / n);
  let result = [];

  for (let i = 0; i < array.length; i += partLength) {
    let end = partLength + i;
    let add = false;

    if (rest !== 0 && restUsed) {
      end++;
      restUsed--;
      add = true;
    }

    result.push(array.slice(i, end));

    if (add) {
      i++;
    }
  }

  return result;
}
