import { FINSIGHT_CARD } from '@/dmPortal/infrastructure/repository/constants';

/**
 * Specific sorting of the array alphabetically. (Tenant 17g5 is displayed last in the list.)
 *
 * @param {Array} applicationCollection
 *
 * @return {Array}
 */
const sortApplications = (applicationCollection) => applicationCollection.sort((a, b) => {
  const nameAppA = a.name.toLowerCase();
  const nameAppB = b.name.toLowerCase();
  const isANumber = !Number.isNaN(parseInt(nameAppA));
  const isBNumber = !Number.isNaN(parseInt(nameAppB));
  if (!isANumber && !isBNumber) {
    return (nameAppA > nameAppB) ? 1 : -1;
  }
  return isANumber ? 1 : -1;
});

/**
 * Adding 2 cards to the Application page.
 *
 * @param {Array} collection
 * @return {Array} collection
 */
export const addStatelessApplications = (collection) => {
  // let applicationCollection = [...collection];
  collection.push(FINSIGHT_CARD);
  sortApplications(collection);
  // return applicationCollection;
};
