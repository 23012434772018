import { substringFloat } from './substringFloat';
import { generateValidFloatString } from './generateValidFloatString';

/**
 *
 * @param {string | number} value
 * @param {number} fraction
 * @returns {number}
 */

export const roundUpValueToFraction = (value, fraction = 0) => {
  const entry = generateValidFloatString(value);
  if (!fraction) {
    return substringFloat(entry, 1);
  }
  const mathPow = 10 ** fraction;
  return Number((Math.round(entry * mathPow) / mathPow).toFixed(fraction));
};
