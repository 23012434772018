export const convertNumberWithCurrencyToNumber = (value: string): number | string => {
  if (value && /^([$£€¥₡₩₭₦₮₽฿₴]?)[0-9,]{0,19}([$£€¥₡₩₭₦₮₽฿₴]?)$/i.test(value)) {
    return addDelimiters(value);
  }

  return value;
};

export const addDelimiters = (value: string | number): string => {
  if (value || value === 0) {
    const parsedValue = value.toString().replace(/\D/g, '');

    if (parsedValue) {
      return Number(parsedValue).toLocaleString('en-US');
    }
  }

  return '';
};

export const removeDelimiters = (value: string | number, delimiters = /\D/g): number | null => (
  value ? Number(value.toString().replace(delimiters, '')) : null
);
