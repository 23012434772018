import unescape from 'lodash/unescape';

/**
 * Convert symbol codes to symbols
 * Example:
 * Input value -> !@#$%^&amp;*(){}:&quot;&lt;&gt;/\|&#039;;~`
 * Result value -> !@#$%^&*(){}:"<>/\|';~`
 * @param {String} value
 */
// @ts-ignore
const decodeSymbols = (value:string): string => unescape(value).replaceAll('&#039;', "'");

export default decodeSymbols;
