import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InvestorTargetingRepository from '@/dealroadshow/infrastructure/repository/InvestorTargetingRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import { toggleRoadshowInvestorTargeting } from '@/dealroadshow/application/actions/data/actions';
import { getInvestorTargetingEnabled } from '@/dealroadshow/application/actions/data/selectors';
import { useRoadshowRoleContext } from '@/dealroadshow/application/RoadshowRoleContext';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { useFiltersContext } from '../FiltersContext';

export const useInvestorTargetingActivation = ({ roadshowId }: { roadshowId: string }) => {
  const { isInitialized: isFilterInitialized } = useFiltersContext();
  const { isAdmin, isLoaded: isRoleLoaded } = useRoadshowRoleContext();
  const { container } = useDIContext();
  const investorTargetingRepository = container.get<InvestorTargetingRepository>(InvestorTargetingRepository);

  const investorTargetingEnabled = useSelector(getInvestorTargetingEnabled);
  const dispatch = useDispatch();

  const [isActivationLoading, setIsActivationLoading] = useState(false);

  /**
   * Default value should be equal investorTargetingEnabled
   * if tagging not complete investor targeting can't be activated
   */
  const [isEnhancedTaggingComplete, setIsEnhancedTaggingComplete] = useState(investorTargetingEnabled);
  const [isEnhancedTaggingCompleteLoading, setIsEnhancedTaggingCompleteLoading] = useState(!investorTargetingEnabled);

  const updateInvestorTargetingEnabling = (enable: boolean) => dispatch(toggleRoadshowInvestorTargeting(enable));

  const activate = useCallback(async () => {
    setIsActivationLoading(true);
    try {
      await investorTargetingRepository.activate({ roadshowId });
      updateInvestorTargetingEnabling(true);
      NotificationManager.success(
        <>
          <span>Investor Targeting has been Activated for all Deal Managers.</span>
          { isAdmin && (
            <>
              <br />
              <b>
                FOR INTERNAL USE ONLY: This data should NOT be shared with Deal Managers because Finsight Support has
                access to roadshows these Deal Managers do not.
              </b>
            </>
          ) }
        </>,
      );
    } catch (e) {
      NotificationManager.error(getErrorMessage(e));
    } finally {
      setIsActivationLoading(false);
    }
  }, [roadshowId, isAdmin]);

  const deactivate = useCallback(async () => {
    setIsActivationLoading(true);
    try {
      await investorTargetingRepository.deactivate({ roadshowId });
      updateInvestorTargetingEnabling(false);
      NotificationManager.info(
        <>
          <span>Investor Targeting has been Deactivated for all Deal Managers.</span>
          { isAdmin && (
            <>
              <br />
              <b>
                FOR INTERNAL USE ONLY: This data should NOT be shared with Deal Managers because Finsight Support has
                access to roadshows these Deal Managers do not.
              </b>
            </>
          ) }
        </>,
      );
    } catch (e) {
      NotificationManager.error(getErrorMessage(e));
    } finally {
      setIsActivationLoading(false);
    }
  }, [roadshowId, isAdmin]);

  const checkIsEnhancedTaggingComplete = useCallback(async () => {
    try {
      const isComplete = await investorTargetingRepository.isEnhancedTaggingComplete({ roadshowId });
      setIsEnhancedTaggingComplete(isComplete);
    } catch (e) {
      NotificationManager.error(getErrorMessage(e));
    } finally {
      setIsEnhancedTaggingCompleteLoading(false);
    }
  }, [roadshowId]);

  useEffect(() => {
    if (!investorTargetingEnabled) {
      checkIsEnhancedTaggingComplete();
    }
  }, [investorTargetingEnabled, checkIsEnhancedTaggingComplete]);

  const isVisibleForAdmin = isAdmin && isEnhancedTaggingComplete;
  const isVisibleDetails = isVisibleForAdmin || investorTargetingEnabled;
  const isLoading = !isRoleLoaded || isEnhancedTaggingCompleteLoading || isActivationLoading || !isFilterInitialized;

  return {
    isVisibleForAdmin,
    isVisibleDetails,
    investorTargetingEnabled,
    isEnhancedTaggingComplete,
    isActivationLoading,
    isLoading,
    activate,
    deactivate,
  };
};
