export const REQUEST_ROADSHOW_EXPORT_ENTRY_CODES = 'REQUEST_ROADSHOW_EXPORT_ENTRY_CODES';
export const REQUEST_ROADSHOW_EXPORT_ENTRY_CODES_SUCCESS = 'REQUEST_ROADSHOW_EXPORT_ENTRY_CODES_SUCCESS';
export const REQUEST_ROADSHOW_EXPORT_ENTRY_CODES_ERROR = 'REQUEST_ROADSHOW_EXPORT_ENTRY_CODES_ERROR';

export const ROADSHOW_ENTRY_CODE_DUPLICATE_VALIDATION_ERROR = 'ROADSHOW_ENTRY_CODE_DUPLICATE_VALIDATION_ERROR';
export const RESET_ROADSHOW_ENTRY_CODE_DUPLICATE_VALIDATION = 'RESET_ROADSHOW_ENTRY_CODE_DUPLICATE_VALIDATION';
export const SET_ROADSHOW_ENTRY_CODE_FOCUS_INPUT_CODE = 'SET_ROADSHOW_ENTRY_CODE_FOCUS_INPUT_CODE';
export const CHANGE_ROADSHOW_ENTRY_CODE_ROTATING_PAGE = 'CHANGE_ROADSHOW_ENTRY_CODE_ROTATING_PAGE';
export const CHANGE_ROADSHOW_ENTRY_CODE_ROTATING_ITEMS_PER_PAGE = 'CHANGE_ROADSHOW_ENTRY_CODE_ROTATING_ITEMS_PER_PAGE';
export const RESET_ROADSHOW_ENTRY_CODES_PAGINATION = 'RESET_ROADSHOW_ENTRY_CODES_PAGINATION';
export const SET_IS_DIAL_IN_CODE_FETCHING = 'SET_IS_DIAL_IN_CODE_FETCHING';
export const ADD_ENTRY_CODE_TO_SAVING_QUEUE = 'ADD_ENTRY_CODE_TO_SAVING_QUEUE';
export const REMOVE_ENTRY_CODE_FROM_SAVING_QUEUE = 'REMOVE_ENTRY_CODE_FROM_SAVING_QUEUE';
export const ROADSHOW_IS_ALREADY_UPDATED = 'ROADSHOW_IS_ALREADY_UPDATED';
