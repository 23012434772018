import { Dependencies } from 'constitute';
import { socketEvents } from '@dealroadshow/file-uploader';
import UploadDispatcher from '@/dmPortal/application/DI/FileUpload/UploadDispatcher';
import SocketClient from '@/dealroadshow/application/DI/Socket/Client';
import Request from '@/Framework/api/Rpc/Request';
import { callbackWrapper } from '@/Framework/DI/Providers/Socket/helpers/callbackWrapper';

@Dependencies(SocketClient, UploadDispatcher)
class PresentationMediaRepository {
  /**
   * @param {SocketClient} socketClient
   * @param {UploadDispatcher} uploadDispatcher
   */
  constructor(socketClient, uploadDispatcher) {
    this.socket = socketClient;
    this.uploadDispatcher = uploadDispatcher;
  }

  /**
   * Upload presentation media audio to backend
   *
   * @param {File} file
   * @return {Upload}
   */
  uploadAudio(file) {
    return this.uploadDispatcher.upload(file, { type: 'ROADSHOW_PRESENTATION_AUDIO' });
  }

  /**
   * Upload presentation media video to backend
   *
   * @param {File} file
   * @return {Upload}
   */
  uploadVideo(file) {
    return this.uploadDispatcher.upload(file, { type: 'ROADSHOW_PRESENTATION_VIDEO' });
  }

  /**
   * Upload presentation media thumbnail to backend
   *
   * @param {File} file
   * @return {Upload}
   */
  uploadThumbnail(file) {
    return this.uploadDispatcher.upload(file, { type: 'ROADSHOW_PRESENTATION_THUMBNAIL' });
  }

  /**
   * On processing state change
   *
   * @param {string} fileId
   * @param {function} callback
   *
   */
  async subscribeOnProcessingStatusChange(fileId, callback) {
    const socketSubscription = await this.socket.subscribe(
      new Request('file.processing', { entityId: fileId }),
    );

    socketEvents.forEach((event) => socketSubscription.on(event, callbackWrapper(callback)));
  }
}

export default PresentationMediaRepository;
