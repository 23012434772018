import { ITtmFilter } from '@/dmPortal/domain/vo/crossDealAnalytics/TtmFilter';
import { tenantDealroadshow } from '@/dealroadshow/application/dmPortal/crossDealAnalytics/constants';
import { tenantEvercall } from '@/evercall/application/dmPortal/analytics/crossDeal/constants';
import { tenantAllocate } from '@/allocate/application/config';
import { tenantCollection as tenantDataroomCollection } from '@/dataroom/application/crossDealAnalytics/constants';
import { transactionTypes as dataroomTransactionTypes } from '@/dataroom/domain/TransactionTypes';
import { transactionTypes as roadshowTransactionTypes } from '@/dealroadshow/domain/TransactionTypes';
import { CrossDealAnalyticsType } from '@/dmPortal/domain/vo/crossDealAnalytics/types/CrossDealAnalyticsType';

export const getTenantInitialCollectionMapping = <T extends string, K>(
  tenantCollection: T[],
  initialValue: K,
) => tenantCollection.reduce(
  (acc, tenant) => ({ ...acc, [tenant]: initialValue }), {},
) as { [key in T]: K };

export const mergeTtmFilters = (collection: ITtmFilter[][]): ITtmFilter[] => {
  const { TTM, ALL, ...other } = collection.reduce((acc, tenantCollection) => {
    tenantCollection.forEach((item) => {
      const mergedItem = acc[item.filterName];

      if (mergedItem) {
        mergedItem.dateFrom = (mergedItem.dateFrom && item.dateFrom) ?
          Math.min(mergedItem.dateFrom, item.dateFrom) : null;
        mergedItem.dateTo = (mergedItem.dateTo && item.dateTo) ?
          Math.max(mergedItem.dateTo, item.dateTo) : null;
        if (item.filterLabel) {
          mergedItem.filterLabel = item.filterLabel;
        }
      } else {
        acc[item.filterName] = item;
      }
    });
    return acc;
  }, {}) as { [key: string]: ITtmFilter };

  return [
    TTM,
    ...Object.values(other).sort((a, b) => (
      (a.filterName > b.filterName) ? -1 : 1
    )),
    ALL,
  ].filter((item) => !!item);
};

export const sortAvailableApplications = (
  availableApplications: string[],
) => availableApplications.sort((a, b) => {
  const isANumber = !Number.isNaN(parseInt(a));
  const isBNumber = !Number.isNaN(parseInt(b));

  if ((isANumber && isBNumber) || (!isANumber && !isBNumber)) {
    return (a > b) ? 1 : -1;
  }

  return (isANumber) ? 1 : -1;
});

export const extendDetailsFiltersByTenants = (
  type: CrossDealAnalyticsType,
  filters: Omit<ITtmFilter, 'filterName'> & {
    includeAccountIds?: number[],
    includeInvestorIds?: number[],
  },
  timeZone: string,
) => ({
  [tenantDealroadshow]: {
    filters: {
      ...filters,
      excludeTransactionTypeIds: [roadshowTransactionTypes.OtherExcludedAnalytics],
    },
  },
  [tenantEvercall]: {
    filters: {
      ...filters,
      excludeTransactionTypeIds: [roadshowTransactionTypes.OtherExcludedAnalytics],
    },
  },
  ...(type === CrossDealAnalyticsType.Accounts ? {
    [tenantAllocate]: {
      filters,
    },
  } : {}),
  ...tenantDataroomCollection.reduce((acc, tenant) => {
    acc[tenant] = {
      filters: {
        ...filters,
        excludeTransactionTypeIds: [dataroomTransactionTypes[tenant].Other.id],
      },
    };
    return acc;
  }, {}),
  timeZone,
});
