import { IValidationError } from './interfaces/ValidationError';

interface IParams {
  value: string,
  fieldName: string,
  fieldCaption: string,
}

export const validateDealSize = (params: IParams): IValidationError => {
  if (Number(params?.value?.toString().replace(/[^\d]/g, '')) > 999999999999999) {
    return { [params.fieldName]: 'Too large amount. Max 999,999,999,999,999' };
  }
  // TODO: use convertNumberWithCurrencyToNumber from ui/shared/helpers/convertTypes.ts
  if (params.value && !/^([$£€¥₡₩₭₦₮₽฿₴]{0,1})[0-9,]{0,19}([$£€¥₡₩₭₦₮₽฿₴]{0,1})$/i.test(params.value)) {
    return { [params.fieldName]: 'Invalid size' };
  }
  return null;
};
