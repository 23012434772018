import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import getZonedUTCDate from '@/Framework/DateTime/getZonedUTCDate';
import setLocalZone from '@/Framework/DateTime/setLocalZone';

const setOtherZone = (timeZone: string, timestamp: number) => {
  if (!timestamp) {
    return null;
  }

  return getUnixTimestamp(getZonedUTCDate(timeZone, getDateFromUnixTime(timestamp)));
};

export const useDatePeriod = ({
  dateFromMin,
  dateToMax,
  dateFrom,
  dateTo,
  timeZone,
  onDateFromChange: onDateFromChangeHandler,
  onDateToChange: onDateToChangeHandler,
}: {
  dateFromMin: number,
  dateToMax: number,
  dateFrom: number | null,
  dateTo: number | null,
  onDateFromChange: (value: number) => void,
  onDateToChange: (value: number) => void,
  timeZone: string,
}) => {
  const dateFromInLocal = setLocalZone(timeZone, dateFrom || dateFromMin);
  const dateFromMinInLocal = setLocalZone(timeZone, dateFromMin);
  const dateToInLocal = setLocalZone(timeZone, dateTo || dateToMax);
  const dateToMaxInLocal = setLocalZone(timeZone, dateToMax);

  const handleDateFromChange = (value: Date) => {
    if (value) {
      const dateFrom = getUnixTimestamp(startOfDay(value));

      if (dateFrom <= dateToInLocal) {
        onDateFromChangeHandler(setOtherZone(timeZone, Math.max(dateFrom, dateFromMinInLocal)));
      }
    }
  };

  const handleDateToChange = (value: Date) => {
    if (value) {
      const dateTo = getUnixTimestamp(endOfDay(value));

      if (dateTo >= dateFromInLocal) {
        onDateToChangeHandler(setOtherZone(timeZone, Math.min(dateTo, dateToMaxInLocal)));
      }
    }
  };

  return {
    dateFrom: dateFromInLocal,
    dateTo: dateToInLocal,
    dateFromMin: dateFromMinInLocal,
    dateToMax: dateToMaxInLocal,
    onDateFromChange: handleDateFromChange,
    onDateToChange: handleDateToChange,
  };
};
