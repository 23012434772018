import config from '@/Framework/config';
import { AllocateTenant } from '@/Framework/Tenant/vo/types/AllocateTenant';
import { DealroadshowTenant } from '@/Framework/Tenant/vo/types/DealroadshowTenant';

export const tenantAllocate = config.tenant.allocate.code as AllocateTenant;
export const tenantDealroadshow = config.tenant.dealroadshow.code as DealroadshowTenant;
export const ASSET_BACKED_SECURITY_DEBT_ID = '313e47d6-b77d-4fa1-ac65-147ef7c65264';
export const ALLOCATE_LIST_URL = '/allocate/all';

export enum AllocationsTypes {
  DEBT = 'debt',
  EQUITY = 'equity',
}
