import { parse } from 'date-fns';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import getZonedUTCDate from '@/Framework/DateTime/getZonedUTCDate';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';

const DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss XXXX';

export const setLocalZone = (timeZone: string, timestamp: number) => {
  if (!timestamp) {
    return null;
  }

  return getUnixTimestamp(utcToZonedDate(timeZone, getDateFromUnixTime(timestamp)));
};

export const setOtherZone = (timeZone: string, timestamp: number) => {
  if (!timestamp) {
    return null;
  }

  return getUnixTimestamp(getZonedUTCDate(timeZone, getDateFromUnixTime(timestamp)));
};

export const getDateFromMin = (startedAt?: string) => {
  if (!startedAt) {
    return null;
  }

  const parsedDate = parse(startedAt, DATE_FORMAT, new Date());
  const minDate = getUnixTimestamp(parsedDate);

  return minDate;
};

export const getDateToMax = (finishedAt?: string) => {
  const parsedDate = finishedAt ? parse(finishedAt, DATE_FORMAT, new Date()) : null;
  const maxDate = Math.min(getUnixTimestamp(), ...[parsedDate && getUnixTimestamp(parsedDate)]);

  return maxDate;
};
