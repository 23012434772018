import config from '@/Framework/config';
import styles from './accountsAndContacts.scss';

export const fieldsTooltipMapping = {
  dataroomUniqueLoginCount: 'Data Rooms include DealVDR, InvestorSet, VeriSend and 17g5.com',
};

export const fieldsTooltipClassNameMapping = {
  dataroomUniqueLoginCount: styles.dataroomUniqueLoginCountTooltip,
};

export const mapTenantToColor = {
  [config.tenant.dealroadshow.code]: '#1f4f95',
  [config.tenant.tenantEvercall.code]: '#3d9086',
  [config.tenant.allocate.code]: '#006a8b',
  [config.tenant.tenantDealvdr.code]: '#3d905b',
  [config.tenant.tenantInvestorSet.code]: '#c39900',
  [config.tenant.tenant17g5.code]: '#923952',
  [config.tenant.tenantVeriSend.code]: '#646374',
};

export const DEFAULT_LEFT_COLUMN_WIDTH = '40%';
export const MAX_LEFT_COLUMN_WIDTH = '50%';
export const MIN_LEFT_COLUMN_WIDTH = 410;
export const RESIZE_THROTTLE_DELAY = 100;

export enum CurrencySymbols {
  USD = '$',
  AUD = '$',
  CAD = '$',
  EUR = '€',
  GBP = '£',
  JPY = '¥',
}
