import DocumentsRepository from '@/dealroadshow/infrastructure/repository/DocumentsRepository';
import * as actionTypes from '../actionTypes';
import createAction from '@/Framework/State/Redux/createAction';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getMessageByErrorCode } from '@/Framework/Message/Mapper/getMessage';
import { IUploadProcessing, ProcessingEvent, UploadEvent } from '@dealroadshow/file-uploader';

const uploadStart = (payload) => createAction(actionTypes.UPLOAD_DOCUMENT_ADD, payload);
const uploadChunkSuccess = (payload) => createAction(actionTypes.UPLOAD_DOCUMENT_CHUNK_SUCCESS, payload);
const uploadError = (payload) => createAction(actionTypes.UPLOAD_DOCUMENT_ERROR, payload);
const uploadSuccess = (payload) => createAction(actionTypes.UPLOAD_DOCUMENT_SUCCESS, payload);
const uploadDone = (payload) => createAction(actionTypes.UPLOAD_DOCUMENT_DONE, payload);
const uploadProcessing = (payload) => createAction(actionTypes.UPLOAD_DOCUMENT_PROCESSING, payload);
const uploadCancelSuccess = (payload) => createAction(actionTypes.UPLOAD_DOCUMENT_CANCEL_SUCCESS, payload);

/**
 * @param {Object} payload
 * @param {Function} errorCallback
 */
const onError = (payload, errorCallback) => async (dispatch, getState) => {
  const index = getState().upload.documents.sort.findIndex((item) => (item.uuid === payload.uuid));
  if (index > -1 && payload.error.message && errorCallback) {
    NotificationManager.error(payload.error.message);
    errorCallback(payload.uuid, index);
  }
};

/**
 * @param {String} uuid
 */
export function uploadDocumentCancel(uuid) {
  return async (dispatch, getState) => {
    if (getState().upload.documents.files[uuid]
      && getState().upload.documents.files[uuid].callback
      && typeof getState().upload.documents.files[uuid].callback.cancel === 'function') {
      getState().upload.documents.files[uuid].callback.cancel();
    } else if (getState().upload.documents.files[uuid]) {
      dispatch(uploadCancelSuccess({
        uuid: getState().upload.documents.files[uuid].process.uuid,
      }));
    }

    dispatch(uploadCancelSuccess({
      uuid,
    }));
  };
}

/**
 * @param {Number} oldIndex
 * @param {Number} newIndex
 */
export function uploadDocumentOrder(oldIndex, newIndex) {
  return async (dispatch) => {
    dispatch(createAction(actionTypes.UPLOAD_DOCUMENT_ORDER, {
      oldIndex,
      newIndex,
    }));
  };
}

/**
 * @param {Array.<File>} files
 * @param {Function} errorCallback
 */
export function uploadDocuments(files, errorCallback) {
  return async (dispatch, getState) => {
    const documentsRepository = await getState().container.get(DocumentsRepository);

    await Promise.all(files.sort((prev, next) => prev.size - next.size).map(async (file) => {
     try {
       const upload: IUploadProcessing = await documentsRepository.upload(file);

       dispatch(uploadStart({
        uploadFile: file,
        callback: upload,
        uuid: upload.getUuid(),
      }));

      upload
        .on(UploadEvent.uploadChunkDone, (data) => dispatch(uploadChunkSuccess(data)))
        .on(UploadEvent.error, (data) => {
          dispatch(uploadError(data));
          dispatch(onError(data, errorCallback));
        })
        .on(UploadEvent.uploadDone, (data) => dispatch(uploadSuccess(data)))
        .on(ProcessingEvent.processing, (data) => dispatch(uploadProcessing(data)))
        .on(ProcessingEvent.processingDone, (data) => dispatch(uploadDone(data)))
        .on(UploadEvent.cancel, (data) => dispatch(uploadCancelSuccess(data)));
      } catch (e) {
       NotificationManager.error(getMessageByErrorCode(e.code));
     }
    }));
  };
}

/**
 * @param {Array} payload
 */
export function initUploadDocuments(payload) {
  return async (dispatch) => {
    dispatch(createAction(actionTypes.UPLOAD_DOCUMENT_INIT, payload));
  };
}
