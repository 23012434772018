export const REQUEST_APPLICATION_BLACKLIST = 'REQUEST_APPLICATION_BLACKLIST';
export const REQUEST_APPLICATION_BLACKLIST_SUCCESS = 'REQUEST_APPLICATION_BLACKLIST_SUCCESS';
export const REQUEST_APPLICATION_BLACKLIST_TOTAL_COUNT = 'REQUEST_APPLICATION_BLACKLIST_TOTAL_COUNT';
export const REQUEST_APPLICATION_BLACKLIST_ERROR = 'REQUEST_APPLICATION_BLACKLIST_ERROR';

export const REQUEST_BLACKLIST_USERS_LIST = 'REQUEST_BLACKLIST_USERS_LIST';
export const REQUEST_BLACKLIST_USERS_LIST_SUCCESS = 'REQUEST_BLACKLIST_USERS_LIST_SUCCESS';
export const REQUEST_BLACKLIST_USERS_LIST_ERROR = 'REQUEST_BLACKLIST_USERS_LIST_ERROR';

export const BLACKLIST_PAGE_CHANGE = 'BLACKLIST_PAGE_CHANGE';
export const BLACKLIST_ITEMS_PER_PAGE_CHANGE = 'BLACKLIST_ITEMS_PER_PAGE_CHANGE';

export const BLACKLIST_CHANGE_INPUT = 'BLACKLIST_CHANGE_INPUT';
export const BLACKLIST_USER_EXIST = 'BLACKLIST_USER_EXIST';
export const BLACKLIST_DOMAINS_ADD = 'BLACKLIST_DOMAINS_ADD';
export const BLACKLIST_REMOVE = 'BLACKLIST_REMOVE';
export const BLACKLIST_USERS_AND_DOMAINS_INIT = 'BLACKLIST_USERS_AND_DOMAINS_INIT';
export const BLACKLIST_RESET = 'BLACKLIST_RESET';
export const BLACKLIST_VALIDATION_ERROR_AND_WARNING = 'BLACKLIST_VALIDATION_ERROR_AND_WARNING';
