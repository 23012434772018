export const ZEROCASE_LABEL = 'No records for this period';
export const ZEROCASE_24_HOURS_LABEL = 'No activity in past 24 hours';
export const ZEROCASE_7_DAYS_LABEL = 'No activity in past 7 days';

export const RETURNING_LOGINS_COLOR = '#67f176';
export const NEW_LOGINS_COLOR = '#7ab7e9';

export const COLORS = [
  '#87b5e4',
  '#6c9dcf',
  '#4c83bc',
  '#eb5380',
  '#6adfca',
  '#77e5e8',
  '#4faea6',
  '#66cd54',
  '#f19f62',
  '#aed174',
  '#ca8cd3',
  '#f2a3b8',
  '#6ad2ff',
  '#3e5af1',
  '#8f80f3',
  '#f9deb4',
  '#fabb82',
  '#fe7f7f',
  '#ff70bd',
  '#e4b200',
  '#c17d7d',
  '#aadfad',
  '#f2da00',
  '#2cdea9',
  '#bfccd8',
];
