import SlideshowRepository from '@/dealroadshow/infrastructure/repository/SlideshowRepository';
import * as actionTypes from '../actionTypes';
import { IUploadProcessing, ProcessingEvent, UploadEvent } from '@dealroadshow/file-uploader';

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadStart(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_SLIDE_ADD,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadChunkSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_SLIDE_CHUNK_SUCCESS,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_SLIDE_SUCCESS,
    payload,
  };
}

/**
 * chunk or combination
 * @param {Object} payload
 * @return {Object}
 */
function uploadError(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_SLIDE_ERROR,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadProcessing(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_SLIDE_PROCESSING,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadDone(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_SLIDE_DONE,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadCancelSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_SLIDE_CANCEL_SUCCESS,
    payload,
  };
}

/**
 * @param {{
 *  uuidOriginal: String,
 *  slideType: String,
 *  uuid: String
 * }} payload
 */
export function uploadPresentationSlideCancel(payload) {
  return async (dispatch, getState) => {
    if (getState().upload.presentationSlides.files[payload.uuidOriginal].types[payload.slideType].callback
        && typeof getState().upload.presentationSlides.files[payload.uuidOriginal].types[payload.slideType].callback.cancel === 'function') {
      getState().upload.presentationSlides.files[payload.uuidOriginal].types[payload.slideType].callback.cancel();
    } else if (getState().upload.presentationSlides.files[payload.uuidOriginal].types[payload.slideType].process.uuid) {
      dispatch(uploadCancelSuccess(payload));
    }

    dispatch(uploadCancelSuccess(payload));
  };
}

/**
 * @param {Array} slides
 * @param {Array} slideType
 */
export function initUploadPresentationSlides(slides, slideType) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_PRESENTATION_SLIDE_INIT,
      payload: {
        slides,
        slideType,
      },
    });
  };
}

/**
 * @param {Number} oldIndex
 * @param {Number} newIndex
 */
export function uploadPresentationSlidesOrder(oldIndex, newIndex) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_PRESENTATION_SLIDE_ORDER,
      payload: {
        oldIndex,
        newIndex,
      },
    });
  };
}

/**
 * @param {{
 *  uuidOriginal: String,
 *  slideType: String,
 *  files: Array.<File>
 * }} params
 */
export function uploadPresentationSlide(params) {
  return async (dispatch, getState) => {
    if (getState().upload.presentationSlides.files[params.uuidOriginal].types[params.slideType].process.uuid) {
      uploadPresentationSlideCancel({
        uuid: getState().upload.presentationSlides.files[params.uuidOriginal].types[params.slideType].process.uuid,
        uuidOriginal: params.uuidOriginal,
        slideType: params.slideType,
      });
    }

    const slideshowRepository = getState().container.get(SlideshowRepository);
    const upload: IUploadProcessing = await slideshowRepository.uploadSlide(params.files[0]);

    dispatch(uploadStart({
      uploadFile: params.files[0],
      callback: upload,
      uuid: upload.getUuid(),
      uuidOriginal: params.uuidOriginal,
      slideType: params.slideType,
    }));

    upload
      .on(UploadEvent.uploadChunkDone, (data) => dispatch(uploadChunkSuccess({
        ...data,
        uuidOriginal: params.uuidOriginal,
        slideType: params.slideType,
      })))
      .on(UploadEvent.error, (data) => dispatch(uploadError({
        ...data,
        uuidOriginal: params.uuidOriginal,
        slideType: params.slideType,
      })))
      .on(UploadEvent.uploadDone, (data) => dispatch(uploadSuccess({
        ...data,
        uuidOriginal: params.uuidOriginal,
        slideType: params.slideType,
      })))
      .on(ProcessingEvent.processing, (data) => dispatch(uploadProcessing({
        ...data,
        uuidOriginal: params.uuidOriginal,
        slideType: params.slideType,
      })))
      .on(ProcessingEvent.processingDone, (data) => dispatch(uploadDone({
        ...data,
        uuidOriginal: params.uuidOriginal,
        slideType: params.slideType,
      })))
      .on(UploadEvent.cancel, (data) => dispatch(uploadCancelSuccess({
        ...data,
        uuidOriginal: params.uuidOriginal,
        slideType: params.slideType,
      })));
  };
}
