export enum RemunerationsMap {
  Commission = 'Commission',
  Markup = 'Markup',
  NoCommission = 'NoCommission',
  Y = 'Y',
}
export const RemunerationsListMap = {
  [RemunerationsMap.Commission]: 'C - Price includes commission',
  [RemunerationsMap.Markup]: 'M - Price includes markup or markdown',
  [RemunerationsMap.NoCommission]: 'N - No commissions or markup/markdown',
  [RemunerationsMap.Y]: 'Y',
};

export const REMUNERATIONS_LIST_TABLE_TOOLTIP_MAP = {
  [RemunerationsMap.Commission]: 'Commission included in price',
  [RemunerationsMap.Markup]: 'Markup or markdown included in price',
  [RemunerationsMap.NoCommission]: 'No Commission or Markup/Markdown',
  [RemunerationsMap.Y]: 'Y',
  null: 'No value assigned',
};

export const REMUNERATIONS_LIST_TABLE_VALUE_MAP = {
  [RemunerationsMap.Commission]: 'C',
  [RemunerationsMap.Markup]: 'M',
  [RemunerationsMap.NoCommission]: 'N',
  [RemunerationsMap.Y]: 'Y',
  null: '-',
};

export enum TradeStatusLabelMap {
  New = 'New',
  Correction = 'Correction',
  Cancel = 'Cancel',
  Reversal = 'Reversal',
}

export enum TradeSideLabelMap {
  Sell = 'Sell',
  Buy = 'Buy',
}

export enum TradeCPTsFilterMap {
  Dealer = 'Dealer',
  Customer = 'Customer',
  ATS = 'ATS',
}

export enum TradeCPTsTableMap {
  Dealer = 'Dealer',
  Customer = 'Customer',
  ATS = 'ATS',
  NotMemberAffiliate = 'Affiliate',
}

export const TRACE_PAGE_TITLE = 'TRACE Data & Visualizations';

export const traceNoAccessFeatures = [
  'Seamless access to timely and valuable trading data straight from FINRA',
  'Recognized by the market for ease-of-use and comprehensive dataset coverage of trading data',
  'Spot market liquidity trends through advanced filters & dynamic visualizations',
];

export const traceNoAccessDescription = 'Tap into the potential of FINRA\'s Trade Reporting and Compliance Engine (TRACE) data to analyze secondary market liquidity with our award-winning module.';

export const traceImageCaption = 'Search and filter through 240,000+ TRACE FINRA filings.';
