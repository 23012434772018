import CommercialVideoRepository from '@/dealroadshow/infrastructure/repository/CommercialVideoRepository';
import * as actionTypes from '../actionTypes';
import { IUploadProcessing, ProcessingEvent, UploadEvent } from '@dealroadshow/file-uploader';

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadStart(payload) {
  return {
    type: actionTypes.UPLOAD_COMMERCIAL_VIDEO_ADD,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadChunkSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_COMMERCIAL_VIDEO_CHUNK_SUCCESS,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_COMMERCIAL_VIDEO_SUCCESS,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */

function uploadError(payload) {
  return {
    type: actionTypes.UPLOAD_COMMERCIAL_VIDEO_ERROR,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */

function uploadProcessingError(payload) {
  return {
    type: actionTypes.UPLOAD_COMMERCIAL_VIDEO_PROCESSING_ERROR,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadProcessing(payload) {
  return {
    type: actionTypes.UPLOAD_COMMERCIAL_VIDEO_PROCESSING,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadDone(payload) {
  return {
    type: actionTypes.UPLOAD_COMMERCIAL_VIDEO_DONE,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadCancelSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_COMMERCIAL_VIDEO_CANCEL_SUCCESS,
    payload,
  };
}

/**
 * @param {String} uuid
 */
export function uploadCommercialVideoCancel(uuid) {
  return async (dispatch, getState) => {
    if (getState().upload.commercialVideo.files[uuid]
        && getState().upload.commercialVideo.files[uuid].callback
        && typeof getState().upload.commercialVideo.files[uuid].callback.cancel === 'function') {
      getState().upload.commercialVideo.files[uuid].callback.cancel();
    } else if (getState().upload.commercialVideo.files[uuid]) {
      dispatch(uploadCancelSuccess({
        uuid: getState().upload.commercialVideo.files[uuid].process.uuid,
      }));
    }

    dispatch(uploadCancelSuccess({
      uuid,
    }));
  };
}

/**
 * @param {Number} oldIndex
 * @param {Number} newIndex
 */
export function uploadCommercialVideoOrder(oldIndex, newIndex) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_COMMERCIAL_VIDEO_ORDER,
      payload: {
        oldIndex,
        newIndex,
      },
    });
  };
}

/**
 * @param {Array.<File>} files
 */
export function uploadCommercialVideo(files) {
  return async (dispatch, getState) => {
    const commercialVideoRepository = getState().container.get(CommercialVideoRepository);

    await Promise.all(files.sort((prev, next) => prev.size - next.size).map(async (file) => {
      const upload: IUploadProcessing = await commercialVideoRepository.upload(file);
      dispatch(uploadStart({
        uploadFile: file,
        callback: upload,
        uuid: upload.getUuid(),
      }));

      upload
        .on(UploadEvent.uploadChunkDone, (data) => dispatch(uploadChunkSuccess(data)))
        .on(UploadEvent.uploadDone, (data) => dispatch(uploadSuccess(data)))
        .on(UploadEvent.uploadError, (data) => dispatch(uploadError(data)))
        .on(ProcessingEvent.processingJoinChunksError, (data) => dispatch(uploadError(data)))
        .on(ProcessingEvent.processing, (data) => dispatch(uploadProcessing(data)))
        .on(ProcessingEvent.processingDone, (data) => dispatch(uploadDone(data)))
        .on(ProcessingEvent.processingError, (data) => dispatch(uploadProcessingError(data)))
        .on(UploadEvent.cancel, (data) => dispatch(uploadCancelSuccess(data)));
    }));
  };
}

/**
 * @param {Array} payload
 */
export function initUploadCommercialVideo(payload) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_COMMERCIAL_VIDEO_INIT,
      payload,
    });
  };
}
