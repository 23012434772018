import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UploadPreview } from '../components';
import * as constants from '../constants';
import { uploadRelatedRoadshowLogo, uploadRelatedRoadshowLogoCancel } from '../actions';

function mapStateToProps(state) {
  return {
    uploadFile: state.upload.relatedRoadshowLogo,
    formFieldName: 'logo',
    allowedFileTypes: constants.UPLOAD_LOGO_ALLOWED_FILES,
    allowedFileText: constants.UPLOAD_LOGO_ALLOWED_FILES_TEXT,
    uploadName: 'Logo',
    canRemove: true,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onUpload: uploadRelatedRoadshowLogo,
    onUploadCancel: uploadRelatedRoadshowLogoCancel,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadPreview);
