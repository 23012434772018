import { Dependencies } from 'constitute';
import UploadDispatcher from '@/dmPortal/application/DI/FileUpload/UploadDispatcher';

@Dependencies(UploadDispatcher)
class LogoRepository {
  /**
   * @param {Dispatcher} uploadDispatcher
   */
  constructor(uploadDispatcher) {
    this.uploadDispatcher = uploadDispatcher;
  }

  /**
   * Upload presentation to backend
   *
   * @param {File} file
   * @return {Upload}
   */
  upload(file) {
    return this.uploadDispatcher.upload(file, { type: 'ROADSHOW_LOGO' });
  }
}

export default LogoRepository;
