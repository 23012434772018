import React from 'react';
import FilterRange from '@/ui/shared/modules/Filters/components/FilterRange';

const RenderRangeField = ({ input, ...rest }) => (
  <FilterRange
    { ...input }
    { ...rest }
    value={ { ...input.value.range } }
  />
);

export default RenderRangeField;
