import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import IndustryRepository from '@/dealroadshow/infrastructure/repository/industry/IndustryRepository';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

export default function useIndustries() {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState(false);
  const [collection, setCollection] = useState([]);

  async function getIndustriesList() {
    setIsFetching(true);

    try {
      const industryRepository = container.get<IndustryRepository>(IndustryRepository);
      const response = await industryRepository.list();
      setCollection(response);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  }

  return {
    isFetching,
    collection,
    getIndustriesList,
  };
}
