import React, { createContext, useContext } from 'react';
import useRoadshowRole from './useRoadshowRole';

export const RoadshowRoleContext = createContext<ReturnType<typeof useRoadshowRole>>(null);

export const useRoadshowRoleContext = () => {
  const context = useContext(RoadshowRoleContext);
  if (!context) {
    throw new Error('useRoadshowRoleContext must be used within a RoadshowRoleContext');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const RoadshowRoleContextProvider = ({ children }: IProps) => {
  return <RoadshowRoleContext.Provider value={ useRoadshowRole() }>{ children }</RoadshowRoleContext.Provider>;
};

export default RoadshowRoleContextProvider;
