import React from 'react';
import FiltersContextProvider from './FiltersContext';
import InvestorTargetingContextProvider from './InvestorTargetingContext';
import MeetingsMetaContextProvider from './MeetingsMetaContext';
import QuestionsMetaContext from './QuestionsMetaContext';
import RoadshowAnalyticsContextProvider from './RoadshowAnalyticsContext/RoadshowAnalyticsContext';
import RoadshowRoleContextProvider from '../../RoadshowRoleContext';

interface IProps {
  children: React.ReactNode,
}

const RoadshowAnalyticsContextWrapper = ({ children }: IProps) => {
  return (
    <RoadshowRoleContextProvider>
      <FiltersContextProvider>
        <InvestorTargetingContextProvider>
          <MeetingsMetaContextProvider>
            <QuestionsMetaContext>
              <RoadshowAnalyticsContextProvider>{ children }</RoadshowAnalyticsContextProvider>
            </QuestionsMetaContext>
          </MeetingsMetaContextProvider>
        </InvestorTargetingContextProvider>
      </FiltersContextProvider>
    </RoadshowRoleContextProvider>
  );
};

export default RoadshowAnalyticsContextWrapper;
