import React from 'react';
import styles from './preloader.scss';

function Preloader() {
  return (
    <div className={ styles.animatedPreloader }>
      <span className={ styles.animatedSquare } />
      <span className={ styles.animatedRectangle } />
    </div>
  );
}

export default Preloader;
