import find from 'lodash/find';

export const APPLICATION_STATES = {
  hidden: 'Hidden',
  noAccess: 'NoAccess',
  activated: 'Activated',
  activatedCanNotTurnOff: 'ActivatedCanNotTurnOff',
  notActivated: 'NotActivated',
  noInformation: 'NoInformation',
};

class Application {
  static isTenantActivated(tenant, collection) {
    if (!collection.length) {
      return false;
    }

    let application = find(collection, (application) => application.tenant === tenant);

    return Application.isActivated(application.state);
  }

  static isHidden(state) {
    return state === APPLICATION_STATES.hidden;
  }

  static isActivated(state) {
    return state === APPLICATION_STATES.activated || state === APPLICATION_STATES.activatedCanNotTurnOff;
  }

  static hasNoAccess(state) {
    return state === APPLICATION_STATES.noAccess;
  }

  static statusCanBeChanged(state) {
    return state === APPLICATION_STATES.activated || state === APPLICATION_STATES.notActivated;
  }

  static notActivated(state) {
    return state === APPLICATION_STATES.notActivated;
  }

  static hasNoInformation(state) {
    return state === APPLICATION_STATES.noInformation;
  }
}

export default Application;
