import { useState, useCallback } from 'react';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import { ICreateAccountPayload, ICollectionItem } from '@/allocate/domain/vo/Allocations/Upload/LinkAccounts';

const useLinkAccountModals = (
  leisCollection: ICollectionItem[],
  createAccountForLeiMapping: (payload: ICreateAccountPayload) => Promise<void>,
  mapAllocations: (forceImport: boolean, roadshowName?: string) => Promise<void>,
  roadshowName: string,
  ) => {
  const { currentUser } = useSessionContext();
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [isLinkAccountModalVisible, setIsLinkAccountModalVisible] = useState(false);
  const [linkAccountModalSelectedAccount, setLinkAccountModalSelectedAccount] = useState('');
  const [linkAccountModalSelectedLei, setLinkAccountModalSelectedLei] = useState('');

  /**
   * Open link account modal
   */
  const handleOpenLinkAccountModal = useCallback((rowIndex: number): void => {
    setLinkAccountModalSelectedLei(leisCollection[rowIndex].lei.name);
    setIsLinkAccountModalVisible(true);
  }, [leisCollection]);

  /**
   * Link account modal submit
   */
  const onLinkAccountModalSubmit = useCallback((): void => {
    createAccountForLeiMapping({
      accountName: linkAccountModalSelectedAccount,
      leiName: linkAccountModalSelectedLei,
      userId: currentUser.id,
    });
    setLinkAccountModalSelectedAccount('');
    setLinkAccountModalSelectedLei('');
    setIsLinkAccountModalVisible(false);
  }, [linkAccountModalSelectedAccount, linkAccountModalSelectedLei, currentUser]);

  /**
   * Link account modal cancel
   */
  const onLinkAccountModalCancel = useCallback((): void => {
    setLinkAccountModalSelectedAccount('');
    setLinkAccountModalSelectedLei('');
    setIsLinkAccountModalVisible(false);
  }, []);

  /**
   * Import modal confirm
   */
  const onImportModalConfirm = useCallback((): void => {
    setIsImportModalVisible(false);
    mapAllocations(true, roadshowName);
  }, [leisCollection]);

  /**
   * Import modal cancel
   */
  const onImportModalCancel = useCallback((): void => {
    setIsImportModalVisible(false);
  }, []);

  return {
    linkAccountModalSelectedAccount,
    setLinkAccountModalSelectedAccount,
    linkAccountModalSelectedLei,
    isImportModalVisible,
    setIsImportModalVisible,
    isLinkAccountModalVisible,
    handleOpenLinkAccountModal,
    onLinkAccountModalSubmit,
    onLinkAccountModalCancel,
    onImportModalConfirm,
    onImportModalCancel,
  };
};

export default useLinkAccountModals;
