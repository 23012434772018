import format from 'date-fns/format';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';

/**
 * Format timestamp into readable format
 * @example
 * formatUnixtimestamp(1690157400); // Jul 24, 2023
 * formatUnixtimestamp(1690157400, {
 *  timeZone: "`America/New_York`",
 *  format: 'MMM dd, yyyy hh:mm a',
 * }); // Jul 23, 2023 08:10 PM
 */
const formatUnixtimestamp = (
  timestamp: number,
  {
    format: dateFormat = 'MMM dd, yyyy',
    timeZone = 'UTC',
  }: {
    format?: string,
    timeZone?: string,
  } = {},
): string => {
  const utcTime = utcToZonedDate(timeZone, getDateFromUnixTime(timestamp));

  return format(utcTime, dateFormat);
};

export default formatUnixtimestamp;
