import React from 'react';
import styles from '../filters.scss';

interface IProps {
  className?: string,
  isExpandable?: boolean,
  itemsCount?: number,
  selectedItemsCount?: number,
  children: React.ReactNode,
}

function FilterItem(props: IProps) {
  const {
    className = styles.filterItem,
    itemsCount = 0,
    selectedItemsCount = 0,
    children,
    isExpandable = true,
  } = props;

  return (
    <div className={ className }>
      <div className={ styles.filterItemCheckbox }>
        { children }
      </div>
      { itemsCount > 0 && (
        <div className={ styles.filterItemCount }>
          {
            selectedItemsCount > 0
              ? (<><span className={ styles.filterItemCountSelected }>{ selectedItemsCount }</span> / { itemsCount }</>)
              : (<>{ itemsCount }</>)
          }
        </div>
      ) }
      { isExpandable && (
        <div className={ styles.filterItemExpand } />
      ) }
    </div>
  );
}

export default React.memo(FilterItem);
