import {
  format,
  addSeconds,
  startOfToday,
} from 'date-fns';
import isObject from 'lodash/isObject';
import { TradeSideLabelMap, TradeStatusLabelMap } from '@/finsight/ui/components/trace/contants';
import formatNumber from '@/Framework/dataHelpers/formatters/formatNumber';
import { MAP_FILTERS_NAME, MAP_FILTERS_PLURAL_NAME, MAP_STATUS_LABEL } from './constants';
import { CURRENT_FACE_RANGE_PRESET } from '@/finsight/ui/components/bonds/common/BondsSidebar/constants';
import { MONTH_DAY_YEAR_FORMAT, TIME_FORMAT_WITH_SECONDS } from '@/Framework/DateTime/dateFormats';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import setLocalZone from '@/Framework/DateTime/setLocalZone';

/**
 * @param {String} filterName
 * @param {Object} filterValue
 * @returns {String}
 */
const includedRegistrationGroupIdsFilter =
  (filterName, filterValue) => ((Array.isArray(filterValue) && filterValue.length === 1)
    ? `${ filterValue.length } ${ MAP_FILTERS_NAME[filterName] }`
    : `${ filterValue.length } ${ MAP_FILTERS_PLURAL_NAME[filterName] }`);

/**
 *
 * @param {Array} currentFaceRangeIds
 * @param {Number} minValue
 * @param {Number} maxValue
 * @returns {Array}
 */
const mapCurrentFaceRangeIds = (currentFaceRangeIds, { minValue, maxValue }) => (
  CURRENT_FACE_RANGE_PRESET
    .filter((presetItem) => currentFaceRangeIds.indexOf(presetItem.currentFaceRangeId) !== -1)
    .map((item) => {
      const fromValue = formatNumber(item.currentFaceRangeFromValue || minValue);
      const toValue = formatNumber(item.currentFaceRangeToValue || maxValue);
      return `${ fromValue }-${ toValue }`;
    })
);

/**
 *
 * @param {String} filterName
 * @param {Object} filterValue
 * @param {Object} filterData
 * @returns {String}
 */
const currentFaceRangeIdsFilter =
  (filterName, filterValue, filterData) => `${ MAP_FILTERS_NAME[filterName] }:
    ${ Array.isArray(filterValue) && mapCurrentFaceRangeIds(filterValue, filterData).join('; ') }`;

/**
 * @param {String} filterName
 * @param {Object} filterValue
 * @returns {String}
 */
const includedStatusFilter =
  (filterName, filterValue) => `${ MAP_FILTERS_NAME[filterName] }: ${ MAP_STATUS_LABEL[filterValue] }`;

const includedTradeIdsFilter =
  (filterName, filterValue, labelMap) => `${ MAP_FILTERS_NAME[filterName] }: ${
  Array.isArray(filterValue)
  ? filterValue.map((value) => labelMap[value]).join(', ')
  : labelMap[filterValue]
}`;

/**
 * @param {String} filterName
 * @param {Object} filterValue
 * @returns {String}
 */
const spreadRangeFilter = (filterName, filterValue) => {
  if (
    isObject(filterValue) &&
    ('range' in filterValue) &&
    (('min' in filterValue.range) &&
    ('max' in filterValue.range))
  ) {
    return `${ MAP_FILTERS_NAME[filterName] }: ${ formatNumber(filterValue.range.min) }-${ formatNumber(filterValue.range.max) }`;
  }

  return undefined;
};

/**
 * @param {String} filterName
 * @param {Object} filterValue
 * @returns {String}
 */
const getDateFromToRangeFilter = (filterName, { dateFrom, dateTo, timeZone }) => {
  if (dateFrom && dateTo) {
    const getDateFromUnix = (timestamp, timeZone) => (timeZone ?
      getDateFromUnixTime(setLocalZone(timeZone, timestamp)) : getDateFromUnixTime(timestamp));
    const dateFromFromUnix = getDateFromUnix(dateFrom, timeZone);
    const dateToFromUnix = getDateFromUnix(dateTo, timeZone);
    return `${ MAP_FILTERS_NAME[filterName] }: ${ format(dateFromFromUnix, MONTH_DAY_YEAR_FORMAT) }
    - ${ format(dateToFromUnix, MONTH_DAY_YEAR_FORMAT) }`;
  }

  return undefined;
};

/**
 * @param {String} filterName
 * @param {Object} filterValue
 * @returns {String}
 */
const allocationSizeRangeFilter = (filterName, filterValue) => {
  if (
    isObject(filterValue) &&
    ('range' in filterValue) &&
    (('min' in filterValue.range) &&
    ('max' in filterValue.range))
  ) {
    const min = formatNumber(Math.round(filterValue.range.min / 1000000));
    const max = formatNumber(Math.round(filterValue.range.max / 1000000));
    return `${ MAP_FILTERS_NAME[filterName] }: ${ min }-${ max }`;
  }

  return undefined;
};

/**
 * @param {String} filterName
 * @param {Object} filterValue
 * @returns {String}
 */
const speedRangeFilter = (filterName, filterValue) => {
  if (
    isObject(filterValue) &&
    ('range' in filterValue) &&
    (('min' in filterValue.range) &&
    ('max' in filterValue.range))
  ) {
    return `${ MAP_FILTERS_NAME[filterName] }: ${ filterValue.range.min }-${ filterValue.range.max }`;
  }

  return undefined;
};

const dateRangeFilter = (filterName, filterValue) => {
  if (
    isObject(filterValue) &&
    ('range' in filterValue) &&
    (('min' in filterValue.range) &&
    ('max' in filterValue.range))
  ) {
    const dateFromFromUnix = getDateFromUnixTime(filterValue.range.min);
    const dateToFromUnix = getDateFromUnixTime(filterValue.range.max);
    return `${ MAP_FILTERS_NAME[filterName] }: ${
      format(dateFromFromUnix, MONTH_DAY_YEAR_FORMAT) }-${ format(dateToFromUnix, MONTH_DAY_YEAR_FORMAT)
    }`;
  }

  return undefined;
};

/**
 * @param {String} filterName
 * @param {Object} filterValue
 * @returns {String}
 */
const yieldRangeFilter = (filterName, filterValue) => {
  if (
    isObject(filterValue) &&
    ('range' in filterValue) &&
    (('min' in filterValue.range) &&
    ('max' in filterValue.range))
  ) {
    return `${ MAP_FILTERS_NAME[filterName] }: ${ filterValue.range.min }%-${ filterValue.range.max }%`;
  }

  return undefined;
};

/**
 * @param {String} filterName
 * @param {Object} filterValue
 * @returns {String}
 */
const yieldSizeRangeFilter = (filterName, filterValue) => {
  if (
    isObject(filterValue) &&
    ('range' in filterValue) &&
    (('min' in filterValue.range) &&
    ('max' in filterValue.range))
  ) {
    return `${ MAP_FILTERS_NAME[filterName] }: ${ Math.round(filterValue.range.min * 100) }-${ Math.round(filterValue.range.max * 100) }`;
  }

  return undefined;
};

/**
 * @param {String} filterName
 * @param {Boolean} filterValue
 * @returns {String}
 */
const upsizedFilter = (filterName, filterValue) => {
  if (filterValue) {
    return MAP_FILTERS_NAME[filterName];
  }

  return undefined;
};

/**
 * @param {String} filterName
 * @param {Object} filterValue
 * @returns {String}
 */
const tenorRangeFilter = (filterName, filterValue) => {
  if (isObject(filterValue) && ('range' in filterValue) &&
      (('min' in filterValue.range) && ('max' in filterValue.range))) {
    return `${ MAP_FILTERS_NAME[filterName] }: ${ filterValue.range.min }-${ filterValue.range.max }`;
  }

  return undefined;
};

/**
 * @param {String} filterName
 * @param {Object} filterValue
 * @returns {String}
 */
const maturityDateRangeFilter = (filterName, filterValue) => {
  if (isObject(filterValue) && ('range' in filterValue) &&
      (('min' in filterValue.range) && ('max' in filterValue.range))) {
    return `${ MAP_FILTERS_NAME[filterName] }: ${ filterValue.range.min }-${ filterValue.range.max }`;
  }

  return undefined;
};

const timeRangeFilter = (filterName, { range: filterValue }) => {
  if (isObject(filterValue) &&
      (('min' in filterValue) && ('max' in filterValue))) {
    const min = format(addSeconds(startOfToday(), filterValue.min), TIME_FORMAT_WITH_SECONDS);
    const max = format(addSeconds(startOfToday(), filterValue.max), TIME_FORMAT_WITH_SECONDS);
    return `${ MAP_FILTERS_NAME[filterName] }: ${ min }-${ max }`;
  }

  return undefined;
};

/**
 *
 * @param filterName{string}
 * @returns {string}
 */
const booleanFilter =
  (filterName) => MAP_FILTERS_NAME[filterName];

const filtersFormatterMap = {
  // Deal
  dates: getDateFromToRangeFilter,
  includeInvestorIds: includedRegistrationGroupIdsFilter,
  includeContactIds: includedRegistrationGroupIdsFilter,
  // Cross Deal
  includeTransactionTypeIds: includedRegistrationGroupIdsFilter,
  includeSubIndustryIds: includedRegistrationGroupIdsFilter,
  includeAbsSubSectorIds: includedRegistrationGroupIdsFilter,
  includeAccountTypeIds: includedRegistrationGroupIdsFilter,
  includeAccountIds: includedRegistrationGroupIdsFilter,
  includeCurrencyIds: includedRegistrationGroupIdsFilter,
  includeDealIds: includedRegistrationGroupIdsFilter,
  includeRatingGroupIds: includedRegistrationGroupIdsFilter,
  includeRatingAgencyIds: includedRegistrationGroupIdsFilter,
  includeDebtCouponTypeIds: includedRegistrationGroupIdsFilter,
  includeSponsorIds: includedRegistrationGroupIdsFilter,
  includeUnderwriterIds: includedRegistrationGroupIdsFilter,
  allocationRange: allocationSizeRangeFilter,
  spreadSizeRange: tenorRangeFilter,
  tenorWalYearsRange: tenorRangeFilter,
  yieldSizeRange: yieldSizeRangeFilter,
  upsized: upsizedFilter,
  // NRSRO
  includedDocumentTypeIds: includedRegistrationGroupIdsFilter,
  includedPseudoSectorIds: includedRegistrationGroupIdsFilter,
  includedPublisherIds: includedRegistrationGroupIdsFilter,
  // Bonds and Bondscreener
  includedRegionIds: includedRegistrationGroupIdsFilter,
  includedCurrencyIds: includedRegistrationGroupIdsFilter,
  includedSubsectorIds: includedRegistrationGroupIdsFilter,
  includedIssuerIds: includedRegistrationGroupIdsFilter,
  includedDealerIds: includedRegistrationGroupIdsFilter,
  includedRatingGroupIds: includedRegistrationGroupIdsFilter,
  includedRatingAgencyIds: includedRegistrationGroupIdsFilter,
  includedBenchmarkGroupIds: includedRegistrationGroupIdsFilter,
  includedSpeedTypeIds: includedRegistrationGroupIdsFilter,
  includedStatus: includedStatusFilter,
  currentFaceRange: spreadRangeFilter,
  currentFaceRangeIds: currentFaceRangeIdsFilter,
  spreadRange: spreadRangeFilter,
  walRange: speedRangeFilter,
  speedRange: speedRangeFilter,
  // Bondscreener
  includedSubindustryIds: includedRegistrationGroupIdsFilter,
  includedCouponTypeIds: includedRegistrationGroupIdsFilter,
  includedRegistrationGroupIds: includedRegistrationGroupIdsFilter,
  includedProductIds: includedRegistrationGroupIdsFilter,
  trancheSizeRange: speedRangeFilter,
  dealSizeRange: speedRangeFilter,
  pricingSpreadRange: speedRangeFilter,
  weightedAverageLifeRange: speedRangeFilter,
  couponRange: yieldRangeFilter,
  tenorRange: tenorRangeFilter,
  maturityDateRange: maturityDateRangeFilter,
  includedRankingIds: includedRegistrationGroupIdsFilter,
  yieldRange: yieldRangeFilter,
  isUpsized: booleanFilter,
  includedParentIds: includedRegistrationGroupIdsFilter,
  // Trace
  maturityDate: speedRangeFilter,
  price: speedRangeFilter,
  coupon: speedRangeFilter,
  size: speedRangeFilter,
  tradeDate: dateRangeFilter,
  tradeTime: timeRangeFilter,
  includedContraPartyTypes: includedRegistrationGroupIdsFilter,
  includedRemunerations: includedRegistrationGroupIdsFilter,
  includedReportingPartyTypes: includedRegistrationGroupIdsFilter,
  includedReportingPartySides: (name, value) => includedTradeIdsFilter(name, value, TradeSideLabelMap),
  includedTradeStatuses: (name, value) => includedTradeIdsFilter(name, value, TradeStatusLabelMap),
};

/**
 *
 * @param {String} filterName
 * @param {Object} filterValue
 * @param {Object} filterData
 * @returns {String|Object}
 */
export function filtersFormatter(filterName, filterValue, filterData) {
  return filtersFormatterMap[filterName] ?
    filtersFormatterMap[filterName](filterName, filterValue, filterData) : filterValue;
}
