import isNumber from 'lodash/isFinite';

export const normalizeMinValue = (value) => {
  const result = Math.floor(value);

  if (isNumber(result)) {
    return result;
  }
  return value;
};

export const normalizeMaxValue = (value) => {
  const result = Math.ceil(value);

  if (isNumber(result)) {
    return result;
  }
  return value;
};
