import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useFiltersContext } from '@/dealroadshow/application/dmPortal/analytics/FiltersContext';
import { Checkbox } from '@dealroadshow/uikit';
import FilterSection from '@/ui/shared/modules/Filters/components/FilterSection';
import FilterItem from '@/ui/shared/modules/Filters/components/FilterItem';
import FilterColumns from '@/ui/shared/modules/Filters/components/FilterColumns';
import { getIndeterminateState } from '@/ui/shared/modules/Filters/helpers';

interface IProps {
  fieldComponent: React.ElementType,
  form: any,
  formValues: any,
  sectionId: string,
  sectionTitle: string,
  isVisible: boolean,
  onSectionToggle: (id: string) => void,
}

const filterName = 'includeAccountTypeIds';

function AccountType({
  form,
  formValues,
  fieldComponent: Field,
  sectionId,
  sectionTitle,
  isVisible,
  onSectionToggle,
}: IProps) {
  const { accountTypes } = useFiltersContext();

  const children = useMemo(
    () => accountTypes?.allIds.map((accountTypeId) => ({
      value: accountTypes?.byId[accountTypeId]?.accountTypeId,
      label: accountTypes?.byId[accountTypeId]?.accountTypeTitle,
      disabled: !accountTypes?.byId[accountTypeId]?.canChangeResponse,
    })),
    [accountTypes?.allIds],
  );
  const indeterminateState = useMemo(() => getIndeterminateState(children, formValues), [children, formValues]);

  const headerComponent = useMemo(() => {
    const activeAccountTypeIds = accountTypes?.allIds.filter(
      (accountTypeId) => accountTypes?.byId[accountTypeId]?.canChangeResponse,
    );

    const handleFilterSectionChange = (event) => {
      const { checked } = event.target;
      if (checked) {
        form.change(filterName, activeAccountTypeIds);
      } else {
        form.change(filterName, formValues.filter((value) => !activeAccountTypeIds?.includes(value)));
      }
    };

    return (
      <Checkbox
        name={ sectionId }
        dataTest={ sectionId }
        label={ sectionTitle }
        supportIndeterminate
        onChange={ handleFilterSectionChange }
        { ...indeterminateState }
      />
    );
  }, [form, accountTypes?.allIds, indeterminateState]);

  if (isEmpty(accountTypes?.allIds)) {
    return null;
  }

  return (
    <FilterSection
      sectionId={ sectionId }
      headerComponent={ headerComponent }
      isVisible={ isVisible }
      onSectionToggle={ onSectionToggle }
    >
      <FilterColumns>
        { children.map(({ value, label, disabled }) => (
          <FilterItem key={ value }>
            <Field
              name={ filterName }
              component={ ({ meta, input }) => (
                <Checkbox
                  dataTest={ `accountType ${ label } checkbox` }
                  { ...input }
                  meta={ meta }
                  value={ value }
                  disabled={ disabled }
                  checked={ formValues?.includes(value) }
                  onChange={ (event) => {
                  const { checked } = event.target;
                  if (checked) {
                    form.change(filterName, [...formValues, value]);
                  } else {
                    form.change(filterName, formValues.filter((id) => id !== value));
                  }
                } }
                  label={ label }
                />
            ) }
            />
          </FilterItem>
        )) }
      </FilterColumns>
    </FilterSection>
  );
}

export default AccountType;
