import { SortOrder } from '@dealroadshow/uikit';

export interface InvestorTargetingTargetsResponseRow {
  total: number,
  viewed: number,
  viewedPercentage: number,
  remaining: number,
}

export interface InvestorTargetingTargetsResponse {
  lastYearAccounts: InvestorTargetingTargetsResponseRow,
  beforeLastYearAccounts: InvestorTargetingTargetsResponseRow,
  comparableAccounts: InvestorTargetingTargetsResponseRow,
  total: InvestorTargetingTargetsResponseRow,
}

export interface InvestorTargetingListAllocation {
  currency: string,
  totalDealSize: number,
  securities: Array<{
    name: string,
    dealSize: number,
  }>,
}

export interface InvestorTargetingViewer {
  name: string,
  viewTime: number,
}

export interface InvestorTargetingListItem {
  viewed: boolean,
  account: {
    id: number,
    title: string,
  },
  accountType: string,
  recentContacts: Array<{
    id: number,
    title: string,
    email: string,
  }>,
  allocationsLastYear: InvestorTargetingListAllocation[],
  allocationsBeforeLastYear: InvestorTargetingListAllocation[],
  allocationsComparable: InvestorTargetingListAllocation[],
  viewedLastYear: number,
  viewedBeforeLastYear: number,
  viewedComparable: number,
  viewedLastYearDetails: InvestorTargetingViewer[],
  viewedBeforeLastYearDetails: InvestorTargetingViewer[],
  viewedCompsDetails: InvestorTargetingViewer[],
}

export interface InvestorTargetingListResponse {
  collection: InvestorTargetingListItem[],
  totalCount: number,
}

export type InvestorTargetingBasePayload = {
  roadshowId: string,
};

export type InvestorTargetingTargetsPayload = InvestorTargetingBasePayload & {
  comparableLevel?: string,
};

export enum InvestorTargetingListFilterTypeType {
  ALLOCATED = 'allocated',
  VIEWERS = 'viewers',
}
export enum InvestorTargetingListFilterPeriodType {
  LAST_YEAR = 'lastYear',
  BEFORE_LAST_YEAR = 'beforeLastYear',
  ALL = 'all',
}
export enum InvestorTargetingListFilterSectorType {
  ISSUER = 'issuer',
  COMPARABLES = 'comparables',
  ALL = 'all',
}
export enum InvestorTargetingListFilterViewedType {
  All = 'all',
  Viewed = 'viewed',
  NotViewed = 'notViewed',
}

export type InvestorTargetingGlobalFilterType = {
  comparableLevel?: string | null,
  includeAccountIds?: number[],
  includeAccountTypeIds?: string[],
};

export type InvestorTargetingListFilterType = null | {
  type: InvestorTargetingListFilterTypeType,
  period: InvestorTargetingListFilterPeriodType,
  sector: InvestorTargetingListFilterSectorType,
  viewed: InvestorTargetingListFilterViewedType,
};

export type InvestorTargetingDetailsListSettingsPayload = {
  page: number,
  perPage: number,
  search: string,
  sortBy?: string,
  sortOrder?: SortOrder,
};

export type InvestorTargetingListPayload = InvestorTargetingDetailsListSettingsPayload &
  InvestorTargetingBasePayload &
  InvestorTargetingGlobalFilterType & {
    filter: InvestorTargetingListFilterType,
  };

export type RemoveAccountFromInvestorTargetingPayload = {
  accountId: number,
};

export type SaveInvestorTargetingFilterPayload = {
  roadshowId: string,
  comparableLevel: string,
};
