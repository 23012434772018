import React, { useEffect, useMemo } from 'react';
import noop from 'lodash/noop';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import { useGlobalAnalyticsContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsContext';
import FilterSection from '@/ui/shared/modules/Filters/components/FilterSection';
import Allocation from './Filters/Allocation';
import Upsized from './Filters/Upsized';
import Spread from './Filters/Spread';
import TenorWalYears from './Filters/TenorWalYears';
import Yield from './Filters/Yield';
import Currency from './Filters/Currency';
import RatingGroup from './Filters/RatingGroup';
import RatingAgency from './Filters/RatingAgency';
import DebtCouponType from './Filters/DebtCouponType';

import styles from './allocations.scss';

interface IProps {
  fieldComponent: React.ElementType,
  sectionId: string,
  sectionTitle: string,
  isVisible: boolean,
  onSectionToggle: (sectionId: string) => void,
  form: any,
  formValues: any,
}

function Allocations({
  fieldComponent: Field,
  sectionId,
  sectionTitle,
  isVisible,
  onSectionToggle,
  form,
  formValues,
}: IProps) {
  const { isAllocationZerocase: isZerocase } = useGlobalAnalyticsFiltersContext();
  const { isAllocationsPage } = useGlobalAnalyticsContext();

  useEffect(() => {
    if (((!isAllocationsPage || isZerocase) && isVisible) || ((isAllocationsPage && !isZerocase) && !isVisible)) {
      onSectionToggle(sectionId);
    }
  }, [isAllocationsPage, isZerocase]);

  const onChangeRange = (filterByName, value) => {
    form.change(filterByName, value);
  };

  const headerComponent = useMemo(() => (
    <span>
      { sectionTitle }
      <span className={ styles.subtitle }>
        { !isZerocase ? 'Only impacts Allocate' : 'You have no allocations' }
      </span>
    </span>
  ), [isZerocase]);

  return (
    <FilterSection
      sectionId={ sectionId }
      headerComponent={ headerComponent }
      isVisible={ isVisible }
      onSectionToggle={ !isZerocase ? onSectionToggle : noop }
      showArrow={ !isZerocase }
      dataTest="crossDealAllocationFilterSections"
    >
      { !isZerocase && (
        <>
          <Allocation fieldComponent={ Field } onChangeRange={ onChangeRange } />
          <Upsized />
          <Spread fieldComponent={ Field } onChangeRange={ onChangeRange } />
          <TenorWalYears fieldComponent={ Field } onChangeRange={ onChangeRange } />
          <Yield fieldComponent={ Field } onChangeRange={ onChangeRange } />
          <Currency fieldComponent={ Field } />
          <RatingGroup fieldComponent={ Field } form={ form } formValue={ formValues.includeRatingGroupIds } />
          <RatingAgency fieldComponent={ Field } form={ form } formValue={ formValues.includeRatingAgencyIds } />
          <DebtCouponType fieldComponent={ Field } form={ form } formValue={ formValues.includeDebtCouponTypeIds } />
        </>
      ) }
    </FilterSection>
  );
}

export default Allocations;
