import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { SortOrder } from '@dealroadshow/uikit';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import { ITtmFilter } from '@/dmPortal/domain/vo/crossDealAnalytics/TtmFilter';
import { IContactActivity as IDataroomContactActivity } from '@/dataroom/domain/vo/crossDealAnalytics/ContactActivity';
import { IDataroomActivity } from '@/dataroom/domain/vo/crossDealAnalytics/DataroomActivity';

@Dependencies(JsonRpcDispatcherFactory)
class DataroomCrossDealAnalyticsRepository {
  constructor(private readonly rpc: typeof JsonRpcDispatcherFactory) {}

  hasUserAccessToCrossDealActivity = async (
    tenant: DataroomTenant,
    payload: {
      filters: {
        includeAccountIds?: number[],
        includeContactIds?: number[],
      },
    },
  ): Promise<{
    userHasAccess: boolean,
  }> => {
    const request = new Request('cross_deal.dataroom.has_access', payload);
    const response = await this.rpc(tenant).call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getDealsActivity = async (
    tenant: DataroomTenant,
    payload: {
      filters: {
        search?: string,
        includeAccountIds?: number[],
        includeContactIds?: number[],
        excludeTransactionTypeIds: string[],
      } & Omit<ITtmFilter, 'filterName'>,
      page: number,
      perPage: number,
      sortBy: string,
      sortOrder: SortOrder,
      secondarySortBy: string,
      secondarySortOrder: SortOrder,
    },
  ): Promise<{
    collection: IDataroomActivity[],
    totalCount: number,
  }> => {
    const request = new Request('cross_deal.dataroom.get_activity', payload);
    const response = await this.rpc(tenant).call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getCrossDealDealsActivityTotalCount = async (
    tenant: DataroomTenant,
    payload: {
      filters: {
        search?: string,
        includeAccountIds?: number[],
        includeContactIds?: number[],
        excludeTransactionTypeIds: string[],
      } & Omit<ITtmFilter, 'filterName'>,
    },
  ): Promise<{
    dataroomActivityCount: number,
  }> => {
    const request = new Request('cross_deal.dataroom.get_activity_count', payload);
    const response = await this.rpc(tenant).call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getCrossDealContactsActivityTotalCount = async (
    tenant: DataroomTenant,
    payload: {
      filters: {
        search?: string,
        includeAccountIds?: number[],
        includeContactIds?: number[],
        excludeTransactionTypeIds: string[],
      } & Omit<ITtmFilter, 'filterName'>,
    },
  ): Promise<{
    contactActivityCount: number,
  }> => {
    const request = new Request('cross_deal.contact.get_activity_count', payload);
    const response = await this.rpc(tenant).call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getCrossDealAccountsActivityTotalCount = async (
    tenant: DataroomTenant,
    payload: {
      filters: {
        search?: string,
        includeAccountIds?: number[],
        includeContactIds?: number[],
        excludeTransactionTypeIds: string[],
      } & Omit<ITtmFilter, 'filterName'>,
    },
  ): Promise<{
    accountActivityCount: number,
  }> => {
    const request = new Request('cross_deal.account.get_activity_count', payload);
    const response = await this.rpc(tenant).call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getTtmFilter = async (
    tenant: DataroomTenant,
    payload: {
      filters: {
        includeAccountIds?: number[],
        includeContactIds?: number[],
        excludeTransactionTypeIds: string[],
      },
      timeZone: string,
    },
  ): Promise<{
    collection: ITtmFilter[],
  }> => {
    const request = new Request('cross_deal.dataroom.get_ttm_filter', payload);
    const response = await this.rpc(tenant).call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getContactsActivity = async (
    tenant: DataroomTenant,
    payload,
  ): Promise<{
    collection: IDataroomContactActivity[],
    totalCount: number,
  }> => {
    const request = new Request('cross_deal.contact.get_activity', payload);
    const response = await this.rpc(tenant).call<RpcSuccess>(request);
    return response.getResult().payload;
  };
}

export default DataroomCrossDealAnalyticsRepository;
