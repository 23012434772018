import * as uploadTypes from '../uploadTypes';
import * as processingTypes from '../processingTypes';

/**
 * @param {String} types
 */
export function convertUploadTypesToString(types) {
  switch (types) {
    case uploadTypes.UPLOAD_START:
      return 'Preparing file...';
    case uploadTypes.UPLOAD_CHUNK_SUCCESS:
      return 'Uploading';
    case uploadTypes.UPLOAD_SUCCESS:
      return 'Processing file...';
    case uploadTypes.UPLOAD_PROCESSING_START:
      return 'Converting file...';
    case uploadTypes.UPLOAD_ERROR:
    case uploadTypes.UPLOAD_PROCESSING_ERROR:
      return 'Unable to convert file';
    case uploadTypes.UPLOAD_DONE:
      return '';
    default:
      return undefined;
  }
}

/**
 * @param {String} types
 */
export function convertProcessingTypesToString(types) {
  switch (types) {
    case processingTypes.COMPLETED:
      return 'Processing completed';
    case processingTypes.PROCESSING:
      return 'Processing file...';
    case processingTypes.ERROR:
      return 'Processing failed';
    default:
      return undefined;
  }
}

/**
 * @param {String} types
 */
export function isShowProgressByUploadType(types) {
  switch (types) {
    case uploadTypes.UPLOAD_START:
    case uploadTypes.UPLOAD_CHUNK_SUCCESS:
      return true;
    case uploadTypes.UPLOAD_SUCCESS:
      return false;
    case uploadTypes.UPLOAD_PROCESSING_START:
      // TODO: Add ping server for status
      return false;
    case uploadTypes.UPLOAD_ERROR:
    case uploadTypes.UPLOAD_PROCESSING_ERROR:
      return false;
    case uploadTypes.UPLOAD_DONE:
      return false;
    default:
      return undefined;
  }
}

/**
 * @param {String} types
 */
export function isShowProgressForTableByUploadType(types) {
  switch (types) {
    case uploadTypes.UPLOAD_START:
    case uploadTypes.UPLOAD_CHUNK_SUCCESS:
      return true;
    case uploadTypes.UPLOAD_SUCCESS:
      return true;
    case uploadTypes.UPLOAD_PROCESSING_START:
      // TODO: Add ping server for status
      return true;
    case uploadTypes.UPLOAD_ERROR:
    case uploadTypes.UPLOAD_PROCESSING_ERROR:
      return false;
    case uploadTypes.UPLOAD_DONE:
      return false;
    default:
      return undefined;
  }
}

/**
 * @param {String} types
 */
export function isShowSpinnerForTableByUploadType(types) {
  switch (types) {
    case uploadTypes.UPLOAD_START:
    case uploadTypes.UPLOAD_CHUNK_SUCCESS:
      return false;
    case uploadTypes.UPLOAD_SUCCESS:
      return true;
    case uploadTypes.UPLOAD_PROCESSING_START:
      // TODO: Add ping server for status
      return true;
    case uploadTypes.UPLOAD_ERROR:
    case uploadTypes.UPLOAD_PROCESSING_ERROR:
      return true;
    case uploadTypes.UPLOAD_DONE:
      return false;
    default:
      return undefined;
  }
}
