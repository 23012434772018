import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  checkUserHasAccessToAccountsAndContactsAnalytics,
  resetUserHasAccessToAccountsAndContactsAnalytics,
} from '@/dmPortal/application/actions/accountsAndContacts/actions';
import Application from '@/dmPortal/domain/Application';
import Navigation from './Navigation';
import * as userHasAccessToAccountsAndContactsAnalyticsSelectors
  from '@/dmPortal/application/actions/accountsAndContacts/selectors';

function mapStateToProps(state) {
  const userHasAccess =
    userHasAccessToAccountsAndContactsAnalyticsSelectors.hasUserAccess(state);
  const isUserHasAccessFetching =
    userHasAccessToAccountsAndContactsAnalyticsSelectors.isFetching(state);

  return {
    isUserHasAccessFetching,
    userHasAccess,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    isTenantActivated: Application.isTenantActivated,
    isActivated: Application.isActivated,
    isHidden: Application.isHidden,
    hasNoInformation: Application.hasNoInformation,
    ...bindActionCreators({
      checkUserHasAccessToAccountsAndContactsAnalytics,
      resetUserHasAccessToAccountsAndContactsAnalytics,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
