import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { SortOrder } from '@dealroadshow/uikit';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { ITransactionTypeGroupItem } from '@/dealroadshow/domain/vo/TransactionTypeGroupItem';
import { IDataroomListItem } from '@/dataroom/domain/vo/collection/DataroomListItem';
import { IFetchCollectionResponse } from '@/Framework/State/useFetchCollection';

@Dependencies(JsonRpcDispatcherFactory)
class RestrictedRepository {
  constructor(private readonly rpc: typeof JsonRpcDispatcherFactory) {}

  getRestrictedList = async (payload: {
    sortBy: string,
    sortOrder: SortOrder,
    page?: number,
    perPage?: number | string,
    search: string,
    groupId: string,
    isPublicRequest: boolean,
  }): Promise<IFetchCollectionResponse<IDataroomListItem>> => {
    let { isPublicRequest, ...filters } = payload;

    const defaultFilters = {
      sortBy: 'name',
      sortOrder: 'asc',
    };

    if (filters.perPage === 'all') {
      const { perPage, page, ...otherFilters } = filters;
      filters = { ...otherFilters };
    }

    filters = { ...defaultFilters, ...filters };

    const request = new Request(
      isPublicRequest ? 'dataroom.restricted.list' : 'dataroom.restricted.list.user_specific',
      filters,
    );

    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getTransactionTypeGroups = async (): Promise<ITransactionTypeGroupItem[]> => {
    const request = new Request('dataroom.restricted.list.groups');
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload.groups;
  };

  requestAccess = async (payload: {
    dataroomId: number,
    role: string,
    message: string,
  }): Promise<void> => {
    const request = new Request('dataroom.restricted.access_request.create', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getRestrictedTotalCount = async (
    payload: {
      search: string,
    },
    isPublicRequest: boolean,
  ): Promise<{ [key: string]: { count: number } }> => {
    const request = new Request(
      isPublicRequest ? 'dataroom.restricted.count.groups' : 'dataroom.restricted.count.groups.user_specific',
      payload,
    );
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload.groups;
  };
}

export default RestrictedRepository;
