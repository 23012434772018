import { drsSteps as allocationsUploadSteps } from '@/allocate/application/Allocations/Upload/config';

//  @Kornienko Temporary route constants, must be transferred to a file with a URL
export const ACCOUNTS = 'accounts';
export const ALLOCATIONS = 'allocations';
export const CONTACTS = 'contacts';
export const DEAL_DIGEST = 'deal_digest';
export const ENGAGEMENT = 'engagement';
export const QUESTIONS = 'questions';
export const MEETINGS = 'meetings';
export const INVESTOR_TARGETING = 'investor-targeting';

export const sidebarAllowedRoutes = [
  ACCOUNTS,
  ALLOCATIONS,
  CONTACTS,
  ENGAGEMENT,
  INVESTOR_TARGETING,
];

export const publicRoutes = [
  ALLOCATIONS,
  DEAL_DIGEST,
  ENGAGEMENT,
  MEETINGS,
  ...allocationsUploadSteps.map((step) => step.url),
];

export const COMPARABLE_LEVEL_KEY = 'comparableLevel';

export const removableFilterKeys = [
  'includeAccountTypeIds',
  'includeInvestorIds',
  'dates',
];

export const filtersKeys = [
  ...removableFilterKeys,
  COMPARABLE_LEVEL_KEY,
];

export const removingFilterKeysMapping = {
  includeAccountTypeIds: ['includeAccountTypeIds'],
  dates: ['dateFrom', 'dateTo'],
  includeInvestorIds: ['includeInvestorIds', 'includeAccountIds'],
};
