import { Dependencies } from 'constitute';
import JsonRpcDispatcher, { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import { withCache } from '@/Framework/withCache';
import UsersRpcDispatcher from '@/users/application/DI/Rpc/HttpDispatcher';
import { IAccountType } from '@/dealroadshow/domain/vo/AccountType';

interface IAccountTypeList {
  collection: IAccountType[],
  totalCount: number,
}

@Dependencies(UsersRpcDispatcher)
class AccountTypeRepository {
  private readonly usersRpc: JsonRpcDispatcher;

  constructor(usersRpc) {
    this.usersRpc = usersRpc;
  }

  @withCache
  async getAccountTypeList(data = {}): Promise<IAccountTypeList> {
    const request = new Request('account_type.list', data);
    const response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult();
  }

  /**
   * @param {Object} filter
   * @return {Object}
   */
  list = async (filter = {}) => {
    let request = new Request('account_type.list', filter);
    let response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult();
  };

  /**
   * @param {String|Number} id
   * @return {Promise.<*>}
   */
  getById = async (id) => {
    let req = new Request('account_type.get_by_id', { id });
    let response = await this.usersRpc.call<RpcSuccess>(req);

    return response.getResult();
  };

  /**
   * @param {Object} data
   * @return {Promise<*>}
   */
  create = async (data) => {
    let request = new Request('account_type.create', data);
    let response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult();
  };

  /**
   * @param {Object} data
   * @return {Promise<*>}
   */
  update = async (data) => {
    let request = new Request('account_type.update', data);
    let response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult();
  };
}

export default AccountTypeRepository;
