import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UploadPreview } from '../components';
import * as constants from '../constants';
import { uploadLogo, uploadLogoCancel } from '../actions';

function mapStateToProps(state) {
  return {
    uploadFile: state.upload.logo,
    formFieldName: 'logo',
    allowedFileTypes: constants.UPLOAD_LOGO_ALLOWED_FILES,
    allowedFileText: constants.UPLOAD_LOGO_ALLOWED_FILES_TEXT,
    uploadName: 'logo',
    maxSize: constants.BYTES_IN_TWO_MEGABYTES,
    maxSizeUnits: 'MB',
    description: constants.UPLOAD_LOGO_SIZE_SUPPORTED_DESCRIPTION,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onUpload: uploadLogo,
    onUploadCancel: uploadLogoCancel,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadPreview);
