import React from 'react';
import PermissionsContextProvider from '@/allocate/application/Allocations/PermissionsContext';

interface IProps {
  children: React.ReactNode,
}

const Allocate = ({ children }: IProps) => (
  <PermissionsContextProvider>
    { children }
  </PermissionsContextProvider>
);

export default Allocate;
