import { IconType } from '@dealroadshow/uikit';

export default function mapFileExtensionToIconType(extension: string): IconType {
  switch (extension) {
    case 'cdi':
      return IconType.fileCdi;
    case 'doc':
    case 'docx':
      return IconType.fileDoc;
    case 'ppt':
    case 'pptx':
      return IconType.filePpt;
    case 'xls':
    case 'xlsx':
      return IconType.fileXls;
    case 'txt':
      return IconType.fileTxt;
    case 'zip':
      return IconType.fileZip;
    case 'pdf':
      return IconType.filePdf;
    case 'mp4':
    case 'avi':
    case 'mov':
    case 'wmv':
    case 'mkv':
    case 'flv':
      return IconType.video;
    case 'jpeg':
    case 'jpg':
      return IconType.fileJpg;
    case 'png':
      return IconType.filePng;
    default:
      return IconType.document;
  }
}
