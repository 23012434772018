import { v4 as uuid } from 'uuid';
import { Subscription } from '@dealroadshow/socket-frontend-sdk';
import Request from '@/Framework/api/Rpc/Request';
import { callbackWrapper } from '@/Framework/DI/Providers/Socket/helpers/callbackWrapper';

class FileDownloadLinkGenerator {
  constructor(private rpc: any, private socket: any) {}

  socketSubscriptions: Record<string, any> = {};

  private async subscribe(entityId: string) {
    this.socketSubscriptions[entityId] = await this.socket.subscribe(
      new Request('file.downloadLink.generation', {
        entityId,
      }),
    );
  }

  private unsubscribe(entityId: string) {
    if (this.socketSubscriptions[entityId] instanceof Subscription) {
      this.socketSubscriptions[entityId].cancel();
      delete this.socketSubscriptions[entityId];
    }

    return Promise.resolve();
  }

  generateUniqueId(): string {
    return uuid();
  }

  async getFileLink(request: typeof Request, entityId: string): Promise<string> {
    await this.subscribe(entityId);

    return new Promise<string>((resolve, reject) => {
      const processingCallback = (payload) => {
        if (payload.status === 'FILE_PLACEMENT_ERROR') {
          reject(payload.error);
        }
        if (payload.status === 'CREATED') {
          resolve(payload.url);
        }
      };
      this.socketSubscriptions[entityId].on('file.generation.complete', callbackWrapper(processingCallback));
      this.socketSubscriptions[entityId].on('file.generation.error', callbackWrapper(processingCallback));

      this.rpc.request(request);
    }).finally(() => this.unsubscribe(entityId));
  }
}

export default FileDownloadLinkGenerator;
