import { useEffect } from 'react';
import { useRoadshowLayoutContext } from '../RoadshowLayout/RoadshowLayoutContext';

export const RoadshowMenuContentPortal = ({ children }) => {
  const { setTabMenuContent } = useRoadshowLayoutContext();

  useEffect(() => {
    setTabMenuContent(children);

    return () => {
      setTabMenuContent(null);
    };
  }, [children]);

  return null;
};

export default RoadshowMenuContentPortal;
