export const CREATE_ROADSHOW_ID = 'CREATE_ROADSHOW_ID';
export const RESET_ROADSHOW_FORM = 'RESET_ROADSHOW_FORM';
export const TOGGLE_ROADSHOW_FORM_PUBLISH = 'TOGGLE_ROADSHOW_FORM_PUBLISH';
export const TOGGLE_ROADSHOW_FORM_NOTIFY_ALL_CONTACTS = 'TOGGLE_ROADSHOW_FORM_NOTIFY_ALL_CONTACTS';
export const ROADSHOW_UPLOAD_VALIDATION_SUCCESS = 'ROADSHOW_UPLOAD_VALIDATION_SUCCESS';
export const ROADSHOW_UPLOAD_VALIDATION_ERROR = 'ROADSHOW_UPLOAD_VALIDATION_ERROR';
export const RESET_ROADSHOW_UPLOAD_VALIDATION = 'RESET_ROADSHOW_UPLOAD_VALIDATION';
export const IS_SUBMIT_DRAFT_ROADSHOW = 'IS_SUBMIT_DRAFT_ROADSHOW';

export const REQUEST_ROADSHOW_CREATE = 'REQUEST_ROADSHOW_CREATE';
export const REQUEST_ROADSHOW_CREATE_SUCCESS = 'REQUEST_ROADSHOW_CREATE_SUCCESS';
export const REQUEST_ROADSHOW_CREATE_ERROR = 'REQUEST_ROADSHOW_CREATE_ERROR';

export const REQUEST_ROADSHOW_UPDATE = 'REQUEST_ROADSHOW_UPDATE';
export const REQUEST_ROADSHOW_UPDATE_SUCCESS = 'REQUEST_ROADSHOW_UPDATE_SUCCESS';
export const REQUEST_ROADSHOW_UPDATE_ERROR = 'REQUEST_ROADSHOW_UPDATE_ERROR';

export const ROADSHOW_FORM_INIT = 'ROADSHOW_FORM_INIT';
export const ROADSHOW_FORM_INIT_SUCCESS = 'ROADSHOW_FORM_INIT_SUCCESS';
export const ROADSHOW_FORM_INIT_ERROR = 'ROADSHOW_FORM_INIT_ERROR';
export const ROADSHOW_FORM_INITIAL_VALUES_CHANGE = 'ROADSHOW_FORM_INITIAL_VALUES_CHANGE';

export const SHOW_SUCCESS_MODAL = 'SHOW_SUCCESS_MODAL';
export const HIDE_SUCCESS_MODAL = 'HIDE_SUCCESS_MODAL';

export const SET_ROADSHOW_NEED_TO_REFRESH_MODAL_VISIBLE = 'SET_ROADSHOW_NEED_TO_REFRESH_MODAL_VISIBLE';

export const SHOW_SUBSECTOR_FIELD = 'SHOW_SUBSECTOR_FIELD';
export const HIDE_SUBSECTOR_FIELD = 'HIDE_SUBSECTOR_FIELD';

export const INCREMENT_ROADSHOW_VARIATION = 'INCREMENT_ROADSHOW_VARIATION';
