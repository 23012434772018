import LogoRepository from '@/dealroadshow/infrastructure/repository/LogoRepository';
import * as actionTypes from '../actionTypes';
import createAction from '@/Framework/State/Redux/createAction';
import { IUploadProcessing, ProcessingEvent, UploadEvent } from '@dealroadshow/file-uploader';

export const initUploadDisclaimerLogo = (payload) => (dispatch) => (
  dispatch({
    type: actionTypes.UPLOAD_DISCLAIMER_ROADSHOW_LOGO_INIT,
    payload,
  })
);

const uploadStart = (payload) => createAction(actionTypes.UPLOAD_DISCLAIMER_ROADSHOW_LOGO_ADD, payload);

const uploadCancelSuccess = (payload) => (
  createAction(actionTypes.UPLOAD_DISCLAIMER_ROADSHOW_LOGO_CANCEL_SUCCESS, payload));

/**
 * @param {String} uuid
 */
export const uploadDisclaimerLogoCancel = (uuid) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.UPLOAD_DISCLAIMER_ROADSHOW_LOGO_CANCEL,
    payload: {
      uuid,
    },
  });

  if (getState().upload.disclaimerLogo.process.uuid) {
    dispatch(uploadCancelSuccess({
      uuid: getState().upload.disclaimerLogo.process.uuid,
    }));
  }
};

export const uploaDisclaimerdLogo = (files) => async (dispatch, getState) => {
  if (getState().upload.logo.process.uuid) {
    uploadDisclaimerLogoCancel(getState().upload.logo.process.uuid);
  }

  const logoRepository = getState().container.get(LogoRepository);
  const upload: IUploadProcessing = await logoRepository.upload(files[0]);

  dispatch(uploadStart({
    uploadFile: files[0],
    callback: upload,
    uuid: upload.getUuid(),
  }));

  upload
    .on(UploadEvent.uploadChunkDone, (data) => (
      dispatch(createAction(actionTypes.UPLOAD_DISCLAIMER_ROADSHOW_LOGO_CHUNK_SUCCESS, data))))
    .on(UploadEvent.uploadDone, (data) => (
      dispatch(createAction(actionTypes.UPLOAD_DISCLAIMER_ROADSHOW_LOGO_SUCCESS, data))))
    .on(ProcessingEvent.processing, (data) => (
      dispatch(createAction(actionTypes.UPLOAD_DISCLAIMER_ROADSHOW_LOGO_PROCESSING, data))))
    .on(ProcessingEvent.processingDone, (data) => (
      dispatch(createAction(actionTypes.UPLOAD_DISCLAIMER_ROADSHOW_LOGO_DONE, data))))
    .on(UploadEvent.error, (data) => (
      dispatch(createAction(actionTypes.UPLOAD_DISCLAIMER_ROADSHOW_LOGO_ERROR, data))))
    .on(UploadEvent.cancel, (data) => dispatch(uploadCancelSuccess(data)));
};
