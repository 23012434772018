import { AppliedFiltersType, FiltersDataType } from '@/dealroadshow/domain/vo/dmPortal/analytics/Filters';

export const calcSelectedAccountsAndInvestor = ({
  accounts,
  investors,
  includeInvestorIds,
  includeAccountIds,
}: {
  accounts: FiltersDataType['accounts'],
  investors: FiltersDataType['investors'],
  includeAccountIds: AppliedFiltersType['includeAccountIds'],
  includeInvestorIds: AppliedFiltersType['includeInvestorIds'],
}) => {
  if (!!accounts?.allIds?.length && !!investors?.allIds?.length) {
    includeInvestorIds.forEach((investorId) => {
      const { accountId } = investors?.byId[investorId];
      const accountInvestorIds = accounts?.byId[accountId]?.investorsIds;
      const allInvestorIdsChecked = accountInvestorIds.every((value) => includeInvestorIds.includes(value));
      if (allInvestorIdsChecked) {
        if (!includeAccountIds.includes(accountId)) {
          includeAccountIds.push(accountId);
        }
        includeAccountIds.forEach(() => {
          includeInvestorIds = includeInvestorIds.filter(
            (value) => !accountInvestorIds.includes(value) && value !== investorId,
          );
        });
      }
    });
  }

  return {
    includeAccountIds,
    includeInvestorIds,
  };
};

export const getIndeterminatedOrCheckedAccountsIds = (
  { includeAccountIds, includeInvestorIds }: AppliedFiltersType,
  investors: FiltersDataType['investors'],
) => {
  return Array.from(
    new Set(
      [...(includeAccountIds || []),
        ...(includeInvestorIds || []).map((id) => investors.byId[id].accountId)],
    ),
  );
};
