import { combineReducers } from 'redux';
import presentationSlides from './presentationSlides';
import presentation from './presentation';
import documents from './documents';
import commercialVideo from './commercialVideo';
import logo from './logo';
import presentationMediaThumbnail from './presentationMediaThumbnail';
import presentationMediaVideo from './presentationMediaVideo';
import presentationMediaAudio from './presentationMediaAudio';
import relatedRoadshowLogo from './relatedRoadshowLogo';
import disclaimerLogo from './disclaimerLogo';
import disclaimerBackgroundImage from './disclaimerBackgroundImage';
import presentationCuePoints from './presentationCuePoints';
import viewerCustomFiles from './viewerCustomFiles';

export default combineReducers({
  presentationSlides,
  presentation,
  documents,
  viewerCustomFiles,
  commercialVideo,
  logo,
  presentationMediaThumbnail,
  presentationMediaVideo,
  presentationMediaAudio,
  relatedRoadshowLogo,
  disclaimerLogo,
  disclaimerBackgroundImage,
  presentationCuePoints,
});
