import { useMemo } from 'react';
import AutoSuggestModal from '@/ui/shared/components/Modals/AutoSuggestModal';
import { AccountSuggestModals } from './interfaces';
import {
  SUGGEST_COMPANY_LABEL,
  SUGGEST_COMPANY_NAME,
  SUGGEST_COMPANY_TEXT,
  SUGGEST_COMPANY_TITLE,
  SUGGEST_UNDERWRITER_LABEL,
  SUGGEST_UNDERWRITER_NAME,
  SUGGEST_UNDERWRITER_TEXT,
  SUGGEST_UNDERWRITER_TITLE,
} from '../constants';

interface IProps {
  visibleModal: null | AccountSuggestModals,
  modalInputValue: string,
  closeModal: () => void,
  handleSaveAccount: (accountName: string, suggestFor: string) => void,
}

const AccountSuggestModal = ({ visibleModal, modalInputValue, closeModal, handleSaveAccount }: IProps) => {
  const modalProps = useMemo(() => {
    if (visibleModal === AccountSuggestModals.COMPANY) {
      return {
        title: SUGGEST_COMPANY_TITLE,
        text: SUGGEST_COMPANY_TEXT,
        name: SUGGEST_COMPANY_NAME,
        label: SUGGEST_COMPANY_LABEL,
      };
    }

    return {
      title: SUGGEST_UNDERWRITER_TITLE,
      text: SUGGEST_UNDERWRITER_TEXT,
      name: SUGGEST_UNDERWRITER_NAME,
      label: SUGGEST_UNDERWRITER_LABEL,
    };
  }, [visibleModal]);

  return (
    <AutoSuggestModal
      isVisible={ Boolean(visibleModal) }
      closeModal={ closeModal }
      handleSaveAccount={ handleSaveAccount }
      inputValue={ modalInputValue }
      { ...modalProps }
    />
  );
};

export default AccountSuggestModal;
