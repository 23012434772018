import { ReactNode, createContext, useContext } from 'react';
import useBulkAddDealManagersForm from './useBulkAddDealManagersForm';

type BulkAddDealManagersFormContextType = ReturnType<typeof useBulkAddDealManagersForm>;

const BulkAddDealManagersFormContext = createContext<BulkAddDealManagersFormContextType>(null);

export const useBulkAddDealManagersFormContext = () => {
  const context = useContext(BulkAddDealManagersFormContext);

  if (!context) {
    throw new Error('useBulkAddDealManagersFormContext must be used within a BulkAddDealManagersFormContextProvider');
  }

  return context;
};

interface IProps {
  existEmails?: string[],
  children: ReactNode,
}

const BulkAddDealManagersFormContextProvider = ({ children, existEmails = [] }: IProps) => (
  <BulkAddDealManagersFormContext.Provider value={ useBulkAddDealManagersForm(existEmails) }>
    { children }
  </BulkAddDealManagersFormContext.Provider>
);

export default BulkAddDealManagersFormContextProvider;
