import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import BlacklistRepository from '@/dealroadshow/infrastructure/repository/BlacklistRepository';
import { NotificationManager } from '@/ui/shared/components/Notification';
import * as actionTypes from './actionTypes';
import createAction from '@/Framework/State/Redux/createAction';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';

export function getApplicationIsBlacklisted() {
  return async (dispatch, getState) => {
    try {
      const blacklistRepository = getState().container.get(BlacklistRepository);
      const response = await blacklistRepository.getApplicationIsBlacklisted();
      dispatch(createAction(actionTypes.GET_APPLICATION_IS_BLACKLISTED, response));
      return response;
    } catch (err) {
      dispatch(createAction(actionTypes.GET_APPLICATION_IS_BLACKLISTED_ERROR, err));
      return undefined;
    }
  };
}

/**
 * @return {Object}
 */
function requestRoadshow() {
  return {
    type: actionTypes.REQUEST_ROADSHOW,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestRoadshowSuccess(payload) {
  return {
    type: actionTypes.REQUEST_ROADSHOW_SUCCESS,
    payload,
  };
}

/**
 * @param {String} error
 * @return {Object}
 */
function requestRoadshowError(error) {
  return {
    type: actionTypes.REQUEST_ROADSHOW_ERROR,
    error,
  };
}

function finishedAtConvertToTZ(finishedAt, timeZone) {
  if (finishedAt && timeZone) {
   return getUnixTimestamp(
      utcToZonedDate(timeZone, getDateFromUnixTime(finishedAt)),
    );
  }
  return finishedAt;
}

/**
 * Get roadshow by id
 *
 * @param {String} roadshowId
 */
export function getRoadshowById(roadshowId) {
  return async (dispatch, getState) => {
    try {
      dispatch(requestRoadshow());

      const roadshowRepository = getState().container.get(RoadshowRepository);
      const roadshowDataPayload = await roadshowRepository.getRoadshowById(roadshowId);
      const roadshowData = {
        ...roadshowDataPayload,
        finishedAt: finishedAtConvertToTZ(roadshowDataPayload?.finishedAt, roadshowDataPayload?.timeZone?.timeZone),
      };

      dispatch(requestRoadshowSuccess(roadshowData));

      return roadshowData;
    } catch (error) {
      dispatch(requestRoadshowError(error));
      NotificationManager.error(getErrorMessage(error));
      return undefined;
    }
  };
}

/**
 * Subscribe on roadshow update
 *
 * @param {String} roadshowId
 * @param {Boolean} isRetail
 * @returns {Function}
 */

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestRoadshowSettingsSuccess(payload) {
  return {
    type: actionTypes.REQUEST_ROADSHOW_SETTINGS_SUCCESS,
    payload,
  };
}

/**
 * Get roadshow settings by id
 *
 * @param {String} roadshowId
 */
export function getRoadshowSettingsById(roadshowId) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    const response = await roadshowRepository.getRoadshowSettingsById(roadshowId);
    await dispatch(requestRoadshowSettingsSuccess(response));
    return response;
  };
}

export function resetRoadshowSettings() {
  return {
    type: actionTypes.RESET_ROADSHOW_SETTINGS,
  };
}

export function resetRoadshowItem() {
  return {
    type: actionTypes.RESET_ROADSHOW_ITEM,
  };
}

/**
 * @return {Object}
 */
function requestRoadshows() {
  return {
    type: actionTypes.REQUEST_ROADSHOWS,
  };
}

/**
 * @return {Object}
 */
export function resetRoadshows() {
  return { type: actionTypes.ROADSHOWS_RESET };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestRoadshowsSuccess(payload) {
  return {
    type: actionTypes.REQUEST_ROADSHOWS_SUCCESS,
    payload,
  };
}

/**
 * @param {String} error
 * @return {Object}
 */
function requestRoadshowsError(error) {
  return {
    type: actionTypes.REQUEST_ROADSHOWS_ERROR,
    error,
  };
}

/**
 * @param {String} sortBy
 * @param {String} sortOrder
 * @return {Object}
 */
export function sortRoadshows(sortBy, sortOrder) {
  return {
    type: actionTypes.ROADSHOWS_SORT_CHANGE,
    payload: {
      sortBy,
      sortOrder,
    },
  };
}

/**
 * @param {Number} page
 * @return {Object}
 */
export function paginateRoadshows(page) {
  return {
    type: actionTypes.ROADSHOWS_PAGE_CHANGE,
    payload: {
      page,
    },
  };
}

/**
 * @param {Number} perPage
 * @return {Object}
 */
export function itemsPerPageRoadshows(perPage) {
  return {
    type: actionTypes.ROADSHOWS_ITEMS_PER_PAGE_CHANGE,
    payload: {
      perPage,
    },
  };
}

/**
 * @param {String} search
 * @return {Object}
 */
export function searchRoadshows(search = '') {
  return {
    type: actionTypes.ROADSHOWS_SEARCH_CHANGE,
    payload: {
      search,
    },
  };
}

/**
 * @param {Object} filters
 */
export function getRoadshows(filters) {
  return async (dispatch, getState) => {
    const { isInitialCollection, ...otherProps } = filters;
    if ('search' in otherProps) {
      otherProps.search = otherProps.search.trim();
    }
    dispatch(requestRoadshows());
    try {
      const roadshowRepository = getState().container.get(RoadshowRepository);
      const response = await roadshowRepository.getList(otherProps);
      const payload = isInitialCollection ? { isInitialCollection, ...response } : response;
      dispatch(requestRoadshowsSuccess(payload));
    } catch (error) {
      dispatch(requestRoadshowsError(error));
      NotificationManager.error(getErrorMessage(error));
    }
  };
}

/**
 * Optimistic toggle roadshow published state
 *
 * @param {String} roadshowId
 */
export function toggleRoadshowPublished(roadshowId) {
  return async(dispatch, getState) => {
    let roadshows = getState().roadshow.data.collection;
    let roadshowIndex;
    roadshows.find((el, index) => {
      if (el.id === roadshowId) {
        roadshowIndex = index;
        return true;
      }
      return false;
    });

    roadshows[roadshowIndex].published = !roadshows[roadshowIndex].published;

    const roadshowRepository = getState().container.get(RoadshowRepository);
    try {
      await roadshowRepository.togglePublished(roadshowId, roadshows[roadshowIndex].published);

      dispatch({
        type: actionTypes.TOGGLE_ROADSHOW_PUBLISHED,
        payload: roadshows,
      });
      return true;
    } catch (e) {
      NotificationManager.error(getErrorMessage(e));
      return false;
    }
  };
}

/**
 * Enable/disable investor targeting
 *
 * @param {Boolean} enabled
 */
export function toggleRoadshowInvestorTargeting(enabled) {
  return createAction(actionTypes.TOGGLE_INVESTOR_TARGETING, {
    investorTargetingEnabled: enabled,
  });
}
