/**
 *
 * @param {string | number} value
 * @param {number} cutFrom
 * @returns {number}
 */

export const substringFloat = (value, cutFrom) => {
  const isFloat = Number(value) % 1 !== 0;
  if (!isFloat) {
    return Number(value);
  }
  return Number(value.substring(0, value.indexOf('.') + 1 + cutFrom));
};
