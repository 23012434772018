import * as actionTypes from './actionTypes';

/**
 * @deprecated This component is considered very legacy and should not be used.
 * It is recommended to avoid any further use of this component due to its outdated nature.
 * Consider using modern alternative instead such as CheckBoxArray. See Example in shared/components/Tables/Columns
 *
 * @param {Object} payload
 * @return {Object}
 */
export function fieldChange(payload) {
  return {
    type: actionTypes.CHECKBOX_CHANGE_FIELD,
    payload,
  };
}

/**
 * @deprecated This component is considered very legacy and should not be used.
 * It is recommended to avoid any further use of this component due to its outdated nature.
 * Consider using modern alternative instead such as CheckBoxArray. See Example in shared/components/Tables/Columns
 *
 * @param {Object} payload
 * @return {Object}
 */
export function registerField(payload) {
  return {
    type: actionTypes.CHECKBOX_REGISTER_FIELD,
    payload,
  };
}

/**
 * @deprecated This component is considered very legacy and should not be used.
 * It is recommended to avoid any further use of this component due to its outdated nature.
 * Consider using modern alternative instead such as CheckBoxArray. See Example in shared/components/Tables/Columns
 *
 * @param {String} name
 * @return {Object}
 */
export function unregisterField(name) {
  return {
    type: actionTypes.CHECKBOX_UNREGISTER_FIELD,
    payload: {
      name,
    },
  };
}

/**
 * @deprecated This component is considered very legacy and should not be used.
 * It is recommended to avoid any further use of this component due to its outdated nature.
 * Consider using modern alternative instead such as CheckBoxArray. See Example in shared/components/Tables/Columns
 *
 * @param {Object} checkboxes
 * @param {String} parentName
 * @return {*}
 */
function findNested(checkboxes, parentName) {
  const nested = (parentName) => {
    let children = Object.keys(checkboxes).filter((key) => checkboxes[key].parent === parentName);
    const result = [];
    children.forEach((childName) => {
      result.push(childName);
      let items = Object.keys(checkboxes).filter((key) => checkboxes[key].parent === childName);
      if (items.length) {
        items.forEach((checkbox) => result.push(checkbox, ...nested(checkbox)));
      }
    });
    return result || children;
  };
  return nested(parentName);
}

/**
 * @deprecated This component is considered very legacy and should not be used.
 * It is recommended to avoid any further use of this component due to its outdated nature.
 * Consider using modern alternative instead such as CheckBoxArray. See Example in shared/components/Tables/Columns
 *
 * @return {Object}
 */
export function getCheckboxChildren(checkboxes, name) {
  return findNested(checkboxes, name).reduce((obj, key) => ({ ...obj, [key]: checkboxes[key] }), {});
}
