import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import UploadDispatcher from '@/dmPortal/application/DI/FileUpload/UploadDispatcher';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import FileDownloadLinkGenerator from '@/dmPortal/application/DI/FileDownloadLinkGenerator/FileDownloadLinkGenerator';

@Dependencies(RpcDispatcher, UploadDispatcher, FileDownloadLinkGenerator)
class DocumentsRepository {
  constructor(
    private rpc: typeof RpcDispatcher,
    private uploadDispatcher: typeof UploadDispatcher,
    private fileDownloadLinkGenerator: typeof FileDownloadLinkGenerator,
  ) {}

  /**
   * Get temporary download url for a file
   *
   * @param {string} url
   * @param {string} downloadName
   * @return {Promise<void>}
   */
  async getDownloadUrl(url, downloadName) {
    const entityId = this.fileDownloadLinkGenerator.generateUniqueId();

    const request = new Request('roadshow.generate_document_download_link', { entityId, url, downloadName });

    return this.fileDownloadLinkGenerator.getFileLink(request, entityId);
  }

  /**
   * Get temporary download url for a file
   *
   * @param {string} url
   * @param {string} downloadName
   * @return {Promise<void>}
   */
  async getViewOnlyUrl(url, downloadName) {
    const entityId = this.fileDownloadLinkGenerator.generateUniqueId();

    const request = new Request('roadshow.generate_document_view_only_link', { entityId, url, downloadName });

    return this.fileDownloadLinkGenerator.getFileLink(request, entityId);
  }

  /**
   * Get temporary download url for a file
   *
   * @param {string} url
   * @param {string} name
   * @return {Promise<void>}
   */
  async getAdminDownloadUrl(url, name) {
    let req = new Request('admin.roadshow.get_temporary_download_url', { url, originalName: name });
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }

  /**
   * Upload presentation to backend
   *
   * @param {File} file
   * @return {Upload}
   */
  upload(file) {
    return this.uploadDispatcher.upload(file, { type: 'ROADSHOW_DOCUMENT' });
  }

  /**
   * @param {Object} payload
   */
  async getDocumentWatermark(payload) {
    let req = new Request('roadshow.get_document_watermark_params', payload);
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }
}

export default DocumentsRepository;
