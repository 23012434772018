import { IValidationError } from './interfaces/ValidationError';

export const validateFormSection = (
  values: string[],
  sectionName: string,
  params: (name: { [key: string]: any }) => { [key: string] : string },
): { [key: string]: IValidationError } => {
  const errors = {
    ...(sectionName in values ? params(values[sectionName]) : null),
  };

  if (Object.keys(errors).length > 0) {
    return {
      [sectionName]: errors,
    };
  }
  return null;
};
