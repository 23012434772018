import React, { createContext, useContext } from 'react';
import useCounties from '@/ui/shared/hooks/useCountries';
import useSectors from './useSectors';
import useIndustries from './useIndustries';
import useCompany from './useCompany';
import useCurrencies from './useCurrencies';
import useTransactionTypes from './useTransactionTypes';
import useSelectedCompany from './useSelectedCompany';
import useAccounts from './useAccounts';

export const EnhancedTaggingContext = createContext(null);

export const useEnhancedTaggingContext = () => {
  const context = useContext(EnhancedTaggingContext);
  if (!context) {
    throw new Error('useEnhancedTaggingContext must be used within a EnhancedTaggingProvider');
  }
  return context;
};

const EnhancedTaggingContextProvider = ({ children }) => (
  <EnhancedTaggingContext.Provider value={ {
    countries: useCounties(),
    sectors: useSectors(),
    industries: useIndustries(),
    companies: useCompany(),
    currencies: useCurrencies(),
    transactionTypes: useTransactionTypes(),
    selectedCompany: useSelectedCompany(),
    accounts: useAccounts(),
  } }
  >
    { children }
  </EnhancedTaggingContext.Provider>
);

export default EnhancedTaggingContextProvider;
