import { Dependencies } from 'constitute';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import FileDownloadLinkGenerator from '@/dmPortal/application/DI/FileDownloadLinkGenerator/FileDownloadLinkGenerator';
import {
  InvestorTargetingTargetsResponse,
  InvestorTargetingListResponse,
  InvestorTargetingBasePayload,
  RemoveAccountFromInvestorTargetingPayload,
  InvestorTargetingListPayload,
  InvestorTargetingTargetsPayload,
  SaveInvestorTargetingFilterPayload,
} from '@/dealroadshow/domain/vo/dmPortal/analytics/InvestorTargeting';

@Dependencies(RpcDispatcher, FileDownloadLinkGenerator)
class InvestorTargetingRepository {
  constructor(private rpc: typeof RpcDispatcher, private fileDownloadLinkGenerator: typeof FileDownloadLinkGenerator) {}

  /**
   * Check is investor targeting can be activated
   */
  async isEnhancedTaggingComplete(payload: InvestorTargetingBasePayload): Promise<boolean> {
    let req = new Request('roadshow.investor_targeting.is_enhanced_tagging_complete_for_targeting', payload);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Activate investor targeting
   */
  async activate(payload: InvestorTargetingBasePayload): Promise<null> {
    let req = new Request('roadshow.investor_targeting.activate', payload);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Deactivate investor targeting
   */
  async deactivate(payload: InvestorTargetingBasePayload): Promise<null> {
    let req = new Request('roadshow.investor_targeting.deactivate', payload);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Get investor targeting allocations list
   */
  async getAllocations(payload: InvestorTargetingTargetsPayload): Promise<InvestorTargetingTargetsResponse> {
    let req = new Request('roadshow.investor_targeting.get_investor_targeting_allocations', payload);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Get investor targeting roadshows list
   */
  async getRoadshows(payload: InvestorTargetingTargetsPayload): Promise<InvestorTargetingTargetsResponse> {
    let req = new Request('roadshow.investor_targeting.get_investor_targeting_roadshows', payload);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Get investor targeting list
   */
  async getTargetingList(payload: InvestorTargetingListPayload): Promise<InvestorTargetingListResponse> {
    let req = new Request('roadshow.investor_targeting.get_investor_targeting_list', payload);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Remove(hide) account from targeting list
   */
  async removeAccountFromTargetingList(
    payload: InvestorTargetingBasePayload & RemoveAccountFromInvestorTargetingPayload,
  ): Promise<any> {
    let req = new Request('roadshow.investor_targeting.remove_account_from_target_list', payload);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Save comparable level value for use it as default then
   */
  async saveInvestorTargetingFilter(payload: SaveInvestorTargetingFilterPayload): Promise<any> {
    let req = new Request('roadshow.investor_targeting.save_comparison_level_filter', payload);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Export investor targeting list to pdf
   */
  async exportInvestorTargetingList(payload: InvestorTargetingListPayload): Promise<string> {
    const entityId = this.fileDownloadLinkGenerator.generateUniqueId();

    const request = new Request('roadshow.investor_targeting.export_investor_target_list_to_pdf', {
      entityId,
      ...payload,
    });

    return this.fileDownloadLinkGenerator.getFileLink(request, entityId);
  }
}

export default InvestorTargetingRepository;
