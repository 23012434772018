import React from 'react';
import cn from 'classnames';
import { DataTable, dataTableStyles } from '@dealroadshow/uikit';
import columns from '@/allocate/ui/components/shared/Tables/SecurityDetails/SecurityDetailsTable/debtColumns';
import { IDebtSecurities } from '@/allocate/domain/vo/Allocations/Analytics/SecutiryDetails';
import styles from '@/allocate/ui/components/shared/Analytics/SecurityDetails/securityDetails.scss';

interface IProps {
  securityDetails: IDebtSecurities,
}

const DebtTable = ({ securityDetails }: IProps) => {
  const {
    securities,
    debtType,
    orderAtLaunch,
  } = securityDetails;

  const columnsConfig = {
    hasOrders: !!orderAtLaunch.length,
    hasDollarPrice: securities.some((security) => security.dollarPrice),
    hasSpreadAtWhisper: securities.some((security) => security.spreadAtWhisper),
    hasSpreadAtGuidance: securities.some((security) => security.spreadAtGuidance),
    hasInterestOnly: securities.some((security) => security.interestOnly),
    debtTypeId: debtType?.id,
  };

  return (
    <DataTable
      className={ cn(dataTableStyles.isStripedOdd, styles.dataTable) }
      data={ securities }
      columns={ columns(columnsConfig) }
      dataTest="securityDetailsDataTable"
    />
  );
};

export default DebtTable;
