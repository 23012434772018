import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import formatExtensionsByMimeType from '@/Framework/dataHelpers/formatters/formatExtensionsByMimeType';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getMaxSizeValue } from '../helpers/getMaxSizeValue';
import linkStyles from '@/ui/shared/styles/links.scss';
import styles from '../upload.scss';

interface IProps {
  onUpload: (files: File[]) => void,
  allowedFileTypes?: string,
  allowedFileText: string,
  uploadName: string,
  maxSize?: number,
  maxSizeUnits?: string,
  limit?: number,
}

const defaultProps = {
  allowedFileTypes: '',
  maxSize: Infinity,
  maxSizeUnits: 'B',
  limit: Infinity,
};

const ZERO_SIZE = 0;

class UploadDropzoneMultipleFiles extends Component<IProps, any> {
  /**
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {};
    this.onDropAcceptedCallback = this.onDropAcceptedCallback.bind(this);
    this.onDropRejectedCallback = this.onDropRejectedCallback.bind(this);
  }

  shouldComponentUpdate() {
    return false;
  }

  onDropAcceptedCallback(filesToUpload) {
    const { onUpload, maxSize, maxSizeUnits, limit } = this.props;
    let acceptedFiles = [];
    let rejectedFiles = [];
    let lessLimitFiles = [];
    let moreLimitFiles = [];
    if (filesToUpload.length) {
      lessLimitFiles = filesToUpload.slice(0, limit);
      moreLimitFiles = filesToUpload.slice(limit);

      acceptedFiles = lessLimitFiles.filter((file) => file.size <= maxSize && file.size !== ZERO_SIZE);
      rejectedFiles = lessLimitFiles.filter((file) => file.size > maxSize || file.size === ZERO_SIZE);
    }
    if (acceptedFiles.length) {
      onUpload(acceptedFiles);
    }
    if (rejectedFiles.length) {
      const zeroBytesFiles = rejectedFiles.filter((file) => file.size === ZERO_SIZE);
      if (zeroBytesFiles.length) {
        NotificationManager.error(getMessage(
          messageCodes.UPLOAD_ZERO_SIZE,
          { fileName: zeroBytesFiles[0].name },
        ));
      } else {
        NotificationManager.warning(getMessage(
          messageCodes.UPLOAD_MAX_SIZE,
          {
            size: getMaxSizeValue(maxSize, maxSizeUnits),
            units: maxSizeUnits,
          },
        ));
      }
    }
    if (moreLimitFiles.length) {
      let addText = moreLimitFiles.length === 1 ? 'file has' : 'files have';
      NotificationManager.error(getMessage(
        messageCodes.UPLOAD_SIMULTANEOUS_LIMIT,
        {
          limit,
          notUploaded: moreLimitFiles.length,
          addText,
        },
      ));
    }
  }

  onDropRejectedCallback(rejectedFiles) {
    const { allowedFileTypes, allowedFileText } = this.props;
    let files = rejectedFiles.filter((file) => (file instanceof File));
    if (files.length) {
      let acceptedExt = allowedFileTypes.split(',').map((accept) => accept.trim());
      files.forEach((file) => {
        let name = file.name.split('.');
        if (name.length > 1 && !acceptedExt.includes(name[name.length - 1])) {
          NotificationManager.error(getMessage(
            messageCodes.UPLOAD_INVALID_EXTENSION,
            { fileName: file.name, validExtensions: allowedFileText },
          ));
        }
      });
    }
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const { allowedFileTypes, allowedFileText, uploadName } = this.props;
    return (
      <div className={ styles.dropZoneWrp }>
        <Dropzone
          multiple
          accept={ formatExtensionsByMimeType(allowedFileTypes) }
          onDropAccepted={ this.onDropAcceptedCallback }
          onDropRejected={ this.onDropRejectedCallback }
        >
          { ({ getRootProps, getInputProps }) => (
            <div
              className={ styles.dropzone }
              { ...getRootProps() }
            >
              <div className={ styles.text }>
                Drag & drop { uploadName } or <span className={ linkStyles.link }>select files</span>
              </div>
              <div className={ styles.tip }>{ allowedFileText }</div>
              <input { ...getInputProps() } />
            </div>
          ) }
        </Dropzone>
      </div>
    );
  }
}

// @ts-ignore
UploadDropzoneMultipleFiles.defaultProps = defaultProps;

export default UploadDropzoneMultipleFiles;
