import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import FilterColumns from '@/ui/shared/modules/Filters/components/FilterColumns';
import FilterItem from '@/ui/shared/modules/Filters/components/FilterItem';
import { Checkbox } from '@dealroadshow/uikit';
import styles from '../../allocations.scss';

interface IProps {
  fieldComponent: React.ElementType,
  form: any,
  formValue: string[],
}

const filterByName = 'includeRatingAgencyIds';

const RatingAgency = ({
  fieldComponent: Field,
  form,
  formValue,
}: IProps) => {
  const { ratingAgencyFilterList } = useGlobalAnalyticsFiltersContext();

  const children = useMemo(
    () => ratingAgencyFilterList?.allIds.map((ratingAgencyId) => {
      const ratingAgency = ratingAgencyFilterList?.byId[ratingAgencyId];
      const { ratingAgencyDisplayName, ratingAgencyAbbreviation, canChangeResponse } = ratingAgency;
      return {
        value: ratingAgencyId,
        label: `${ ratingAgencyDisplayName === 'Other' ? 'Other / Unspecified' : ratingAgencyDisplayName } (${ ratingAgencyAbbreviation })`,
        disabled: !canChangeResponse,
      };
    }),
    [ratingAgencyFilterList?.allIds],
  );

  if (isEmpty(ratingAgencyFilterList?.allIds)) {
    return null;
  }

  return (
    <>
      <div className={ styles.fieldLabel }>Rating agencies</div>
      <FilterColumns>
        { children.map(({ value, label, disabled }) => (
          <FilterItem key={ value }>
            <Field
              name={ filterByName }
              component={ ({ meta, input }) => (
                <Checkbox
                  { ...input }
                  meta={ meta }
                  dataTest={ `ratingAgency ${ label } checkbox` }
                  label={ label }
                  value={ value }
                  supportIndeterminate
                  checked={ formValue.includes(value) }
                  onChange={ (event) => {
                    const { checked } = event.target;
                    if (checked) {
                      form.change(filterByName, [...formValue, value]);
                    } else {
                      form.change(filterByName, formValue.filter((id) => id !== value));
                    }
                  } }
                  disabled={ disabled }
                />
              ) }
            />
          </FilterItem>
        )) }
      </FilterColumns>
    </>
  );
};

export default RatingAgency;
