import React, { useCallback, useRef, useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import cn from 'classnames';

import {
  useGlobalAnalyticsFiltersContext,
} from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';

import styles from '@/dmPortal/ui/components/GlobalAnalytics/common/Sidebar/Sections/Account/account.scss';

const ALL_ITEMS_PER_PAGE = 100;
export default function useFilterMethods(filtersPayload, getFilters, list) {
  const {
    isAppliedFilters,
  } = useGlobalAnalyticsFiltersContext();
  const isFirstRender = useRef(true);

  const [currentPage, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    if (isAppliedFilters) {
      setPage(1);
    }
  }, [isAppliedFilters]);
  const formatOptionLabel = ({ label, canChangeResponse }) => (
    <div className={ cn({ [styles.disabled]: !canChangeResponse }) }>
      { label }
    </div>
  );

  const debounced = useCallback((search) => {
    setPage(1);
    getFilters(filtersPayload, 1, ALL_ITEMS_PER_PAGE, search);
  }, [currentPage, searchString]);

  const handleSearchChange = useCallback(debounce((value) => {
    setSearchString(value);
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
    if (searchString !== value) {
      debounced(value);
    }
  }, 500), [searchString, filtersPayload, currentPage]);

  const handleOnScrollBottom = () => {
    setPage(currentPage + 1);
    if (list.length >= currentPage * ALL_ITEMS_PER_PAGE) {
      getFilters(filtersPayload, currentPage + 1, ALL_ITEMS_PER_PAGE, searchString);
    }
  };

  return {
    formatOptionLabel,
    handleSearchChange,
    handleOnScrollBottom,
    isFirstRender,
  };
}
