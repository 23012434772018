import React from 'react';
import { IconType, PanelGroup, Panel, PanelArrow } from '@dealroadshow/uikit';
import styles from '@/ui/shared/modules/Filters/filters.scss';

interface IProps {
  sectionId: string | number,
  isVisible: boolean,
  showArrow?: boolean,
  onSectionToggle: Function,
  headerComponent: React.ReactNode,
  children: React.ReactNode,
  dataTest?: string,
}

const arrowPlus = (
  <PanelArrow
    icon={ IconType.expand }
    arrowWrapperClassName={ styles.panelGroupFilterHeaderArrowIcon }
  />
);

const arrowMinus = (
  <PanelArrow
    isActive
    iconActive={ IconType.collapse }
    arrowWrapperClassName={ styles.panelGroupFilterHeaderArrowIcon }
  />
);

function FilterSection(props: IProps) {
  const { sectionId, isVisible, showArrow = true, headerComponent, children, dataTest } = props;
  return (
    <PanelGroup
      activeKey={ isVisible ? String(sectionId) : '' }
      dataTest={ dataTest }
      onChange={ () => props.onSectionToggle(sectionId) }
    >
      <Panel
        id={ String(sectionId) }
        key={ sectionId }
        header={ headerComponent }
        showArrow={ showArrow }
        arrow={ arrowPlus }
        arrowActive={ arrowMinus }
        destroyOnToggle={ false }
        className={ styles.panelGroupWrapper }
        headerClass={ styles.panelGroupHeader }
        contentClass={ styles.panelGroupContentWrapper }
      >
        { children }
      </Panel>
    </PanelGroup>
  );
}

export default React.memo(FilterSection);
