import { ReactNode } from 'react';
import cn from 'classnames';
import { useBulkAddDealManagersFormContext } from './context';

import styles from './bulkAddDealManagersModal.scss';

interface IProps {
  num: number,
  title: string,
  children: ReactNode,
}

const FormStep = ({ num, title, children }: IProps) => {
  const { activeStep, setActiveStep } = useBulkAddDealManagersFormContext();
  const stepClassName = cn(styles.step, {
    [styles.activeStep]: activeStep === num,
  });

  return (
    <div className={ stepClassName } onClick={ () => setActiveStep(num) }>
      <h3 className={ styles.titleStep }>
        { `Step ${ num }: ${ title }` }
      </h3>
      { children }
    </div>
  );
};

export default FormStep;
