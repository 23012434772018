import { useMemo, useEffect, useState, useRef } from 'react';
import throttle from 'lodash/throttle';

const useResponsiveTabs = <T = string>(
  tabs: T[],
  screenSizeCheckFn: () => boolean,
) => {
  const tabsRef = useRef<HTMLSpanElement>(null);
  const tabsWrapperRef = useRef<HTMLDivElement>(null);

  const [isMobile, setIsMobile] = useState<boolean>(screenSizeCheckFn());
  const [tabsWidth, setTabsWidth] = useState(0);
  const [tabsWrapperWidth, setTabsWrapperWidth] = useState(0);
  const [isTabsFetching, setIsTabsFetching] = useState(true);
  const tabsWrapperPadding = 40;

  useEffect(() => {
    const handleResize = () => {
      if (tabsWrapperRef.current) {
        setTabsWrapperWidth(tabsWrapperRef.current.offsetWidth - tabsWrapperPadding);
      }
      setIsMobile(screenSizeCheckFn());
    };
    window.addEventListener('resize', throttle(handleResize, 100));
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (tabsWrapperRef.current) {
      setTabsWrapperWidth(tabsWrapperRef.current.offsetWidth - tabsWrapperPadding);
    }
  }, []);

  useEffect(() => {
    if (tabsRef.current) {
      setTabsWidth(tabsRef.current.offsetWidth);
    }
  }, [tabs.length]);

  useEffect(() => {
    if (tabsWidth && tabsWrapperWidth && isTabsFetching) {
      setIsTabsFetching(false);
    }
  }, [tabsWidth, tabsWrapperWidth]);

  const isTabsSelectVisible = useMemo(() => {
    return isMobile || tabsWidth > tabsWrapperWidth;
  }, [isMobile, tabsWidth, tabsWrapperWidth]);

  return {
    tabsRef,
    tabsWrapperRef,
    isTabsFetching,
    isTabsSelectVisible,
  };
};

export default useResponsiveTabs;
