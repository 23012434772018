import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import RoadshowAnalyticsRepository from '@/dealroadshow/infrastructure/repository/analytics/RoadshowAnalyticsRepository';
import InvestorTargetingRepository from '@/dealroadshow/infrastructure/repository/InvestorTargetingRepository';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import * as roadshowSelectors from '@/dealroadshow/application/actions/data/selectors';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import { useDIContext } from '@/Framework/DI/DIContext';
import {
  AppliedFiltersType,
  FiltersDataType,
  FiltersPayloadType,
} from '@/dealroadshow/domain/vo/dmPortal/analytics/Filters';
import { useFilterStorage } from './useFilterStorage';
import { calcSelectedAccountsAndInvestor } from './helpers';

export const initialAppliedFilters: AppliedFiltersType = {
  dateFrom: null,
  dateTo: null,
  search: null,
  includeAccountTypeIds: [],
  includeAccountIds: [],
  includeInvestorIds: [],
  comparableLevel: 'subsector/subindustry',
};

export const initialFiltersData: FiltersDataType = {
  dates: {
    firstLoginAt: '',
    startedAt: '',
    finishedAt: '',
  },
  accountTypes: {
    allIds: [],
    byId: {},
  },
  accounts: {
    allIds: [],
    byId: {},
  },
  investors: {
    allIds: [],
    byId: {},
  },
  comparableLevel: [],
};

export const useFilters = () => {
  const [{ accountTypes, accounts, comparableLevel, dates, investors }, setFiltersData] = useState(initialFiltersData);
  const [isFetching, setIsFetching] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [search, setSearch] = useState<string | null>(null);
  const [appliedFilters, setAppliedFilters] = useState(initialAppliedFilters);
  const [filtersPayload, setFiltersPayload] = useState<FiltersPayloadType>(null);
  const prefetchFiltersPayload = useRef<FiltersPayloadType>(null);

  const { container } = useDIContext();
  const { removeLocalFiltersState, saveLocalFiltersState, getLocalFiltersState } = useFilterStorage();
  const timeZone = useSelector(roadshowSelectors.getRoadshowTimeZone);

  const getRoadshowFilters = async (roadshowId: string, filters?: FiltersPayloadType) => {
    const payload = filters ?? filtersPayload;
    setIsFetching(true);
    prefetchFiltersPayload.current = payload;

    try {
      const roadshowAnalyticsRepository = container.get(RoadshowAnalyticsRepository);
      const response = await roadshowAnalyticsRepository.getRoadshowFilters(roadshowId, payload, timeZone);

      if (isEqual(prefetchFiltersPayload.current, payload)) {
        setFiltersData({
          ...response.filters,
          dates: response.dates,
        });
        setIsFetching(false);
        prefetchFiltersPayload.current = null;
      }
    } finally {
      setIsFetching(false);
      setIsInitialized(true);
    }
  };

  const getInitialState = (): AppliedFiltersType => {
    const initialState = {
      ...initialAppliedFilters,
    };

    const { startedAt, firstLoginAt, finishedAt } = dates;

    if (timeZone) {
      const nowDate = getUnixTimestamp(utcToZonedDate(timeZone));
      const finishedAtDate = finishedAt ? getUnixTimestamp(new Date(finishedAt)) : nowDate;

      const dateFromWithFirstLoginAt = firstLoginAt ? getUnixTimestamp(new Date(firstLoginAt)) : null;

      const dateFromWithStartedAt = startedAt ? getUnixTimestamp(new Date(startedAt)) : null;

      initialState.dateFrom = dateFromWithFirstLoginAt || dateFromWithStartedAt || null;
      initialState.dateTo = finishedAtDate < nowDate ? finishedAtDate : null;
    }
    return initialState;
  };

  const getFiltersPayload = ({
    dateFrom,
    dateTo,
    search,
    includeAccountTypeIds,
    includeInvestorIds,
    includeAccountIds,
    comparableLevel,
  }: AppliedFiltersType): FiltersPayloadType => {
    return {
      dateFrom,
      dateTo,
      search,
      includeAccountTypeIds,
      comparableLevel,
      ...calcSelectedAccountsAndInvestor({
        accounts,
        investors,
        includeInvestorIds,
        includeAccountIds,
      }),
    };
  };

  const prefetchFilters = async (roadshowId: string, values: AppliedFiltersType) => {
    const filtersPayload = getFiltersPayload(values);
    await getRoadshowFilters(roadshowId, filtersPayload);
  };

  const saveInvestorTargetingFilterState = async (roadshowId: string, comparableLevel: string) => {
    try {
      const roadshowAnalyticsRepository = container.get(InvestorTargetingRepository);
      await roadshowAnalyticsRepository.saveInvestorTargetingFilter({ roadshowId, comparableLevel });
    } catch (e) {
      // do nothing
    }
  };

  const applyFilters = (roadshowId: string, values: AppliedFiltersType) => {
    const oldComparableLevel = appliedFilters.comparableLevel;

    const filtersPayload = getFiltersPayload(values);
    setFiltersPayload(filtersPayload);
    setAppliedFilters(values);

    const newComparableLevel = values.comparableLevel;
    if (oldComparableLevel !== newComparableLevel) {
      saveInvestorTargetingFilterState(roadshowId, values.comparableLevel);
    }

    saveLocalFiltersState(roadshowId, {
      appliedFilters: values,
      filtersPayload,
    });
  };

  const resetFilters = (roadshowId: string) => {
    removeLocalFiltersState(roadshowId);
    const initialState = getInitialState();
    setFiltersPayload(initialState);
    setAppliedFilters(initialAppliedFilters);
  };

  const initRoadshowFilters = async (roadshowId: string, isPublic: boolean) => {
    if (isPublic) {
      await getRoadshowFilters(roadshowId);
      return;
    }

    const { filtersPayload, appliedFilters } = getLocalFiltersState(roadshowId) ?? {};
    await getRoadshowFilters(roadshowId, filtersPayload);
    const initialState = getInitialState();
    setFiltersPayload(filtersPayload || initialState);
    setAppliedFilters(appliedFilters || initialState);
  };

  const isZerocase = useMemo(() => {
    return isEmpty(accountTypes?.allIds) || isEmpty(accounts?.allIds);
  }, [accountTypes, accounts]);

  return {
    applyFilters,
    resetFilters,
    getRoadshowFilters,
    prefetchFilters,
    getInitialState,
    initRoadshowFilters,
    setSearch,
    search,
    isFetching,
    appliedFilters,
    filtersPayload,
    isZerocase,
    isInitialized,
    accountTypes,
    accounts,
    comparableLevel,
    dates,
    investors,
  };
};
