import { Dependencies } from 'constitute';
import subHours from 'date-fns/subHours';
import subWeeks from 'date-fns/subWeeks';
import startOfDay from 'date-fns/startOfDay';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import getZonedUTCDate from '@/Framework/DateTime/getZonedUTCDate';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import {
  IActivityByAccountResponse,
  IActivityByRoadshowResponse,
  ITopMostViewedSlideResponse,
  ITotalViewResponse,
} from '@/dealroadshow/domain/vo/dmPortal/analytics/DealDigest';
import FileDownloadLinkGenerator from '@/dmPortal/application/DI/FileDownloadLinkGenerator/FileDownloadLinkGenerator';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';

@Dependencies(RpcDispatcher, FileDownloadLinkGenerator)
class RoadshowAnalyticsRepository {
  constructor(private rpc: typeof RpcDispatcher, private fileDownloadLinkGenerator: typeof FileDownloadLinkGenerator) {}

  getRecentActivitySummary(roadshowId: string, timeZone: string): Promise<IActivityByRoadshowResponse> {
    const dateFrom = getUnixTimestamp(subHours(new Date(), 24));
    const dateTo = getUnixTimestamp(new Date());
    let payload = {
      filters: {
        roadshowId,
        dateFrom,
        dateTo,
      },
      timeZone,
      groupBy: 'roadshow',
    };
    return this.getInvestorsActivity(payload);
  }

  getTotalActivitySummary(roadshowId: string, timeZone: string): Promise<IActivityByRoadshowResponse> {
    let payload = {
      filters: { roadshowId },
      timeZone,
      groupBy: 'roadshow',
    };
    return this.getInvestorsActivity(payload);
  }

  getRecentActivityByAccount(roadshowId: string, timeZone: string): Promise<IActivityByAccountResponse> {
    const dateFrom = getUnixTimestamp(subHours(new Date(), 24));
    const dateTo = getUnixTimestamp(new Date());
    let payload = {
      filters: {
        roadshowId,
        dateFrom,
        dateTo,
      },
      timeZone,
      sortBy: 'viewTime',
      sortOrder: 'DESC',
      groupBy: 'account',
      extraFields: ['contactList', 'topSlideList', 'isNewLogin', 'index'],
    };
    return this.getInvestorsActivity(payload);
  }

  getTopActivityByAccount(roadshowId: string, timeZone: string): Promise<IActivityByAccountResponse> {
    let payload = {
      filters: { roadshowId },
      timeZone,
      sortBy: 'viewTime',
      sortOrder: 'DESC',
      page: 1,
      perPage: 15,
      groupBy: 'account',
      extraFields: ['contactList', 'topSlideList', 'isNewLogin', 'index'],
    };
    return this.getInvestorsActivity(payload);
  }

  /**
   * @param {String} roadshowId
   * @param {Object} filters
   * @param {Object} options
   * @param {String} timeZone
   */
  getAccountsActivityByType(roadshowId, filters, options, timeZone) {
    const defaultOptions = {
      sortBy: 'viewTimeSum',
      sortOrder: 'DESC',
      page: 1,
      perPage: 100,
      groupBy: 'accountType',
    };
    options = { ...defaultOptions, ...options, sortOrder: options.sortOrder.toUpperCase() };
    let payload = {
      filters: {
        roadshowId,
        ...filters,
      },
      timeZone,
      ...options,
    };
    return this.getInvestorsActivity(payload);
  }

  /**
   * @param {String} roadshowId
   * @param {Object} filters
   * @param {Object} options
   * @param {String} timeZone
   */
  getAccountsActivity(roadshowId, filters, options, timeZone) {
    const defaultOptions = {
      sortOrder: 'DESC',
      page: 1,
      perPage: 15,
      groupBy: 'account',
    };
    options = { ...defaultOptions, ...options };
    options.sortOrder = options.sortOrder.toUpperCase();
    let payload = {
      filters: {
        roadshowId,
        ...filters,
      },
      timeZone,
      ...options,
    };
    return this.getInvestorsActivity(payload);
  }

  /**
   * @param {String} roadshowId
   * @param {Object} filters
   * @param {Object} options
   * @param {String} timeZone
   */
  getContactsActivity(roadshowId, filters, options, timeZone) {
    const defaultOptions = {
      sortOrder: 'DESC',
      page: 1,
      perPage: 15,
      groupBy: 'contact',
    };
    options = { ...defaultOptions, ...options };
    options.sortOrder = options.sortOrder.toUpperCase();
    let payload = {
      filters: {
        roadshowId,
        ...filters,
      },
      timeZone,
      ...options,
    };
    return this.getInvestorsActivity(payload);
  }

  /**
   * @param {String} roadshowId
   * @param {Object} filters
   */
  getLoginsByUSContacts(roadshowId, filters) {
    let payload = {
      filters: {
        roadshowId,
        countryCodes: ['US'],
        ...filters,
      },
      groupBy: 'region',
    };
    return this.getInvestorLoginsByGeo(payload);
  }

  /**
   * @param {String} roadshowId
   * @param {Object} filters
   */
  getLoginsByGlobalContacts(roadshowId, filters) {
    let payload = {
      filters: {
        roadshowId,
        ...filters,
      },
      groupBy: 'country',
    };
    return this.getInvestorLoginsByGeo(payload);
  }

  /**
   * @param {Object} payload
   * @return {Promise<void>}
   */
  async getInvestorsActivity(payload) {
    let req = new Request('roadshow.analytics.get_investors_activity', payload);
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }

  /**
   * @param {Object} payload
   */
  async getInvestorLoginsByGeo(payload) {
    let req = new Request('roadshow.analytics.get_investor_logins_by_geo', payload);
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }

  /**
   * @param {String} roadshowId
   * @param {String} componentType
   * @param {Object} filters
   */
  async getComponentsStats(roadshowId, componentType, filters = {}) {
    let req = new Request('roadshow.analytics.get_component_stats', {
      filters: {
        roadshowId,
        componentType,
        ...filters,
      },
      groupBy: 'roadshow',
      sortBy: 'componentOrder',
      sortOrder: 'ASC',
    });
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }

  async getTotalViewTimeBySlideAndAccount(roadshowId: string): Promise<ITotalViewResponse> {
    let req = new Request('roadshow.analytics.get_slides_stats', {
      filters: {
        roadshowId,
      },
      groupBy: 'accountType',
      sortBy: 'pageNumber',
      sortOrder: 'ASC',
      includeEmpty: true,
    });
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }

  async getTopMostViewedSlides(roadshowId: string): Promise<ITopMostViewedSlideResponse> {
    let req = new Request('roadshow.analytics.get_slides_stats', {
      filters: {
        roadshowId,
      },
      groupBy: 'slide',
      sortBy: 'viewTime',
      sortOrder: 'DESC',
      page: 1,
      perPage: 8,
    });
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }

  getDefaultFiltersForTotalViewTimeByDate(timeZone: string) {
    const zonedTime = utcToZonedDate(timeZone);
    const twoWeeksAgo = subWeeks(zonedTime, 2);
    const dayStartZoned = startOfDay(twoWeeksAgo);
    const dayStart = getZonedUTCDate(timeZone, dayStartZoned);

    return {
      dateFrom: getUnixTimestamp(dayStart),
      dateTo: getUnixTimestamp(new Date()),
    };
  }

  /**
   * @param {String} roadshowId
   * @param {Object} filters
   * @param {String} timeZone
   */
  async getTotalViewTimeByDate(roadshowId, filters = {}, timeZone) {
    const defaultFilters = this.getDefaultFiltersForTotalViewTimeByDate(timeZone);

    filters = { ...defaultFilters, ...filters };
    let req = new Request('roadshow.analytics.get_slides_cumulative_stats', {
      filters: {
        roadshowId,
        ...filters,
      },
      timeZone,
    });
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }

  /**
   * @param {String} roadshowId
   * @param {Object} filters
   * @param {String} timeZone
   */
  async getTotalUniqueLoginsByDate(roadshowId, filters, timeZone) {
    let req = new Request('roadshow.analytics.get_login_cumulative_stats', {
      filters: {
        roadshowId,
        unique: true,
        ...filters,
      },
      timeZone,
    });
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }

  /**
   * @param {String} roadshowId
   * @param {Object} filters
   * @param {String} timeZone
   */
  async getTotalViewTimeBySlide(roadshowId, filters, timeZone) {
    let req = new Request('roadshow.analytics.get_slide_versions_stats', {
      filters: {
        roadshowId,
        ...filters,
      },
      timeZone,
      includeEmpty: true,
    });
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }

  /**
   * @param {String} roadshowId
   * @param {Object} filters
   * @param {String} timeZone
   */
  async getRoadshowFilters(roadshowId, filters = {}, timeZone) {
    let req = new Request('roadshow.analytics.get_deal_specific_filters', {
      filters: {
        roadshowId,
        ...filters,
      },
      timeZone,
    });
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }

  /**
   * @param {String} roadshowId
   * @param {Object} filters
   * @param {Object} targetingFilters
   * @param {String} timeZone
   * @return {Promise}
   */
  downloadExcel(roadshowId, filters, targetingFilters, timeZone) {
    const entityId = this.fileDownloadLinkGenerator.generateUniqueId();

    const request = new Request('roadshow.analytics.generate_report', {
      filters: {
        roadshowId,
        ...filters,
      },
      ...(targetingFilters && { targetingFilters }),
      timeZone,
      entityId,
      reportType: 'Excel',
    });

    return this.fileDownloadLinkGenerator.getFileLink(request, entityId);
  }

  exportDealDigestToPdf(roadshowId: string): Promise<string> {
    const entityId = this.fileDownloadLinkGenerator.generateUniqueId();

    const request = new Request('roadshow.analytics.export_deal_digest_to_pdf', {
      entityId,
      roadshowId,
    });

    return this.fileDownloadLinkGenerator.getFileLink(request, entityId);
  }
}

export default RoadshowAnalyticsRepository;
