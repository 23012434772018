import { useRef, useState } from 'react';
import { AccountSuggestModals } from './interfaces';

export const useAccountSuggestModal = ({ setFieldValue }: { setFieldValue: (name: string, value: any) => void }) => {
  const [visibleModal, setVisibleModal] = useState<null | AccountSuggestModals>(null);
  const [modalInputValue, setModalInputValue] = useState('');
  const targetFieldName = useRef<string>(null);

  const openModal = (modal: AccountSuggestModals) => {
    return ({ inputValue, inputName }: { inputValue: string, inputName: string }) => {
      setVisibleModal(modal);
      setModalInputValue(inputValue);
      targetFieldName.current = inputName;
    };
  };

  const closeModal = () => {
    setFieldValue(targetFieldName.current, '');

    if (visibleModal === AccountSuggestModals.UNDERWRITER) {
      setFieldValue(targetFieldName.current.replace('.name', '.lead'), false);
    }

    setVisibleModal(null);
    setModalInputValue('');
  };

  const openCompanyModal = openModal(AccountSuggestModals.COMPANY);
  const openUnderwriterModal = openModal(AccountSuggestModals.UNDERWRITER);

  return {
    visibleModal,
    closeModal,
    modalInputValue,
    openCompanyModal,
    openUnderwriterModal,
  };
};
