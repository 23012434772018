interface IUnderwriter {
  name: string,
  lead: boolean,
}

export const sortUnderwriters = (
  a: IUnderwriter,
  b: IUnderwriter,
): number => {
  if (a.lead !== b.lead) {
    if (a.lead) {
      return -1;
    }

    return 1;
  }

  return a.name.localeCompare(b.name);
};

export default sortUnderwriters;
