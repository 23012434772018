export function getAccountTypes(state) {
  return state.accountType.data.collection;
}

export function getAccountTypesColors(state) {
  return state.accountType.colors;
}

export function getAccountTypesByName(state) {
  return state.accountType.byName;
}
