import React, { createContext, useContext } from 'react';
import { useFilters } from './useFilters';

interface IProps {
  children: React.ReactNode,
}

type TFiltersContext = ReturnType<typeof useFilters>;

const FiltersContext = createContext<TFiltersContext>(null);

export const useFiltersContext = () => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFiltersContext must be used within a FiltersProvider');
  }
  return context;
};

const FiltersContextProvider = ({ children }: IProps) => (
  <FiltersContext.Provider value={ useFilters() }>{ children }</FiltersContext.Provider>
);

export default FiltersContextProvider;
