import React, { createContext, useContext } from 'react';
import useNavigation from './useNavigation';

type NavigationContextType = ReturnType<typeof useNavigation>;

export const NavigationContext = createContext<NavigationContextType>(null);

export const useNavigationContext = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigationContext must be used within a NavigationContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const NavigationContextProvider = ({ children }:IProps) => {
  const contextValues = useNavigation();

  return (
    <NavigationContext.Provider value={ contextValues }>
      { children }
    </NavigationContext.Provider>
  );
};

export default NavigationContextProvider;
