export const GET_CROSS_DEAL_FILTERS = 'REQUEST_CROSS_DEAL_FILTERS';
export const GET_CROSS_DEAL_FILTERS_SUCCESS = 'REQUEST_CROSS_DEAL_FILTERS_SUCCESS';
export const GET_CROSS_DEAL_FILTERS_ERROR = 'REQUEST_CROSS_DEAL_FILTERS_ERROR';

export const CROSS_DEAL_FILTERS_PREFETCH_PAYLOAD = 'CROSS_DEAL_FILTERS_PREFETCH_PAYLOAD';
export const CROSS_DEAL_FILTERS_PAYLOAD = 'CROSS_DEAL_FILTERS_PAYLOAD';
export const CROSS_DEAL_FILTERS_APPLY = 'CROSS_DEAL_FILTERS_APPLY';
export const CROSS_DEAL_ALLOCATION_FILTERS_PAYLOAD = 'CROSS_DEAL_ALLOCATION_FILTERS_PAYLOAD';

export const GET_ACCOUNT_FILTERS = 'GET_ACCOUNT_FILTERS';
export const GET_ACCOUNT_FILTERS_SUCCESS = 'GET_ACCOUNT_FILTERS_SUCCESS';
export const GET_ACCOUNT_FILTERS_ERROR = 'GET_ACCOUNT_FILTERS_ERROR';
export const GET_MORE_ACCOUNT_FILTERS = 'GET_MORE_ACCOUNT_FILTERS';
export const GET_MORE_ACCOUNT_FILTERS_SUCCESS = 'GET_MORE_ACCOUNT_FILTERS_SUCCESS';
export const GET_MORE_ACCOUNT_FILTERS_ERROR = 'GET_MORE_ACCOUNT_FILTERS_ERROR';

export const GET_SPONSOR_FILTERS = 'GET_SPONSOR_FILTERS';
export const GET_SPONSOR_FILTERS_SUCCESS = 'GET_SPONSOR_FILTERS_SUCCESS';
export const GET_SPONSOR_FILTERS_ERROR = 'GET_SPONSOR_FILTERS_ERROR';
export const GET_MORE_SPONSOR_FILTERS = 'GET_MORE_SPONSOR_FILTERS';
export const GET_MORE_SPONSOR_FILTERS_SUCCESS = 'GET_MORE_SPONSOR_FILTERS_SUCCESS';
export const GET_MORE_SPONSOR_FILTERS_ERROR = 'GET_MORE_SPONSOR_FILTERS_ERROR';

export const GET_UNDERWRITER_FILTERS = 'GET_UNDERWRITER_FILTERS';
export const GET_UNDERWRITER_FILTERS_SUCCESS = 'GET_UNDERWRITER_FILTERS_SUCCESS';
export const GET_UNDERWRITER_FILTERS_ERROR = 'GET_UNDERWRITER_FILTERS_ERROR';
export const GET_MORE_UNDERWRITER_FILTERS = 'GET_MORE_UNDERWRITER_FILTERS';
export const GET_MORE_UNDERWRITER_FILTERS_SUCCESS = 'GET_MORE_UNDERWRITER_FILTERS_SUCCESS';
export const GET_MORE_UNDERWRITER_FILTERS_ERROR = 'GET_MORE_UNDERWRITER_FILTERS_ERROR';
