import * as actionTypes from './actionTypes';

const initialState = {
  collection: [],
  isCollectionFetching: false,
};

export default function data(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CURRENCIES:
      return {
        ...state,
        isCollectionFetching: true,
      };
    case actionTypes.GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        isCollectionFetching: false,
        collection: action.payload,
      };
    case actionTypes.GET_CURRENCIES_ERROR:
      return {
        ...state,
        isCollectionFetching: false,
      };
    default:
      return state;
  }
}
