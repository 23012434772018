import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import RoadshowCrossDealAnalyticsRepository from '@/dealroadshow/infrastructure/repository/analytics/RoadshowCrossDealAnalyticsRepository';
import { IFormattedDealsFilters, IDeal, IFormattedDeal } from '@/dmPortal/domain/vo/crossDealAnalytics/Filters';
import { GLOBAL_ANALYTICS_TIMEZONE } from '../config';
import { IAppliedFilters } from './types';
import { useGlobalAnalyticsContext } from '../GlobalAnalyticsContext';

export const useDealsFilters = () => {
  const { dealsSupportedTenants: tenants } = useGlobalAnalyticsContext();

  const { container } = useDIContext();

  const [isLoading, setIsLoading] = useState(false);
  const [dealsFilters, setDealsFilters] = useState<IFormattedDealsFilters>({
    deals: [],
    tenants: [],
  });

  const roadshowCrossDealAnalyticsRepository = container.get<RoadshowCrossDealAnalyticsRepository>(
    RoadshowCrossDealAnalyticsRepository,
  );

  const prepareDeal = (deal: IDeal): IFormattedDeal => ({
    ...deal,
    nameInLowerCase: deal.name.toLowerCase(),
  });

  const getDealsFilters = async (filters: IAppliedFilters) => {
    setIsLoading(true);

    try {
      const response = await roadshowCrossDealAnalyticsRepository.getDealsFilters(filters, {
        timeZone: GLOBAL_ANALYTICS_TIMEZONE,
        tenants,
      });

      setDealsFilters({
        tenants: response.tenants,
        deals: response.deals.map(prepareDeal),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getDealsFilters,
    isLoading,
    dealsFilters,
  };
};
