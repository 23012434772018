import { FormStep } from '@/dmPortal/ui/common/Modals/BulkAddDealManagersModal';
import { Field } from '@/ui/shared/components/Form/FinalForm';
import fieldsList from './bulkAddFields';

import styles from '../dealManagers.scss';

const BulkAddForm = () => (
  <FormStep num={ 2 } title="Set Parameters">
    <div className={ styles.addDealManagersForm }>
      {
        fieldsList.map((params) => (
          <Field key={ params.name } { ...params } />
        ))
      }
    </div>
  </FormStep>
);

export default BulkAddForm;
