import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { startOfDay, endOfDay } from 'date-fns';
import * as roadshowSelectors from '@/dealroadshow/application/actions/data/selectors';
import DatePeriodComponent from '@/ui/shared/modules/Filters/FiltersPanel/DatePeriod';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import styles from '@/ui/shared/modules/Filters/FiltersPanel/sidebar.scss';
import { useFiltersContext } from '@/dealroadshow/application/dmPortal/analytics/FiltersContext';
import { getDateFromMin, getDateToMax, setLocalZone, setOtherZone } from '../../helpers';

interface IProps {
  form: any,
  dateFrom:number,
  dateTo: number,
}

const DatePeriod = ({ form, dateFrom, dateTo }: IProps) => {
  const {
    dates: { startedAt = '', finishedAt = '' },
  } = useFiltersContext();
  const timeZone = useSelector(roadshowSelectors.getRoadshowTimeZone);

  const dateFromMin = getDateFromMin(startedAt);
  const dateToMax = getDateToMax(finishedAt);

  const dateFromInLocal = setLocalZone(timeZone, dateFrom || dateFromMin);
  const dateFromMinInLocal = setLocalZone(timeZone, dateFromMin);
  const dateToInLocal = setLocalZone(timeZone, dateTo || dateToMax);
  const dateToMaxInLocal = setLocalZone(timeZone, dateToMax);

  const handleDateFromChange = useCallback(
    (value) => {
      if (value) {
        // Set time from 00:00:00
        const dateFrom = getUnixTimestamp(startOfDay(value));

        if (dateFrom <= dateToInLocal) {
          form.change('dateFrom', setOtherZone(timeZone, Math.max(dateFrom, dateFromMinInLocal)));
        }
      }
    },
    [timeZone, form, dateToInLocal, dateFromMinInLocal],
  );

  const handleDateToChange = useCallback(
    (value) => {
      if (value) {
        // Set time to 23:59:59
        const dateTo = getUnixTimestamp(endOfDay(value));

        if (dateTo >= dateFromInLocal) {
          form.change('dateTo', setOtherZone(timeZone, Math.min(dateTo, dateToMaxInLocal)));
        }
      }
    },
    [timeZone, form, dateFromInLocal, dateToMaxInLocal],
  );

  return (
    <div className={ styles.headerPeriod }>
      <div className={ styles.headerPeriodWrp }>
        <DatePeriodComponent
          dateFrom={ dateFromInLocal }
          dateTo={ dateToInLocal }
          dateFromMin={ dateFromMinInLocal }
          dateToMax={ dateToMaxInLocal }
          onDateFromChange={ handleDateFromChange }
          onDateToChange={ handleDateToChange }
        />
      </div>
    </div>
  );
};

export default DatePeriod;
