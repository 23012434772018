import * as actionTypes from './actionTypes';
import { IReducerState } from './types';

export const initialState: IReducerState = {
  isFetching: false,
  isSponsorFetching: false,
  isAccountFetching: false,
  isUnderwritersFetching: false,
  filtersPayload: {},
  appliedFilters: {},
  allocationFiltersPayload: {},
  filter: {
    transactionTypeFilterList: {
      allIds: [],
      byId: {},
    },
    subIndustryFilterList: {
      allIds: [],
      byId: {},
    },
    industryFilterList: {
      allIds: [],
      byId: {},
    },
    accountTypeFilterList: {
      allIds: [],
      byId: {},
    },
    accountFilterList: [],
    absSectorFilterList: {
      allIds: [],
      byId: {},
    },
    absSubSectorFilterList: {
      allIds: [],
      byId: {},
    },
    currencyFilterList: {
      allIds: [],
      byId: {},
    },
    ratingGroupFilterList: {
      allIds: [],
      byId: {},
    },
    ratingAgencyFilterList: {
      allIds: [],
      byId: {},
    },
    debtCouponTypeFilterList: {
      allIds: [],
      byId: {},
    },
    sponsorFilterList: [],
    underwriterFilterList: [],
    allocationFilterRange: {
      range: {
        min: 0,
        max: 0,
      },
      minValue: 0,
      maxValue: 0,
    },
    spreadFilterRange: {
      range: {
        min: 0,
        max: 0,
      },
      minValue: 0,
      maxValue: 0,
    },
    tenorWalYearsFilterRange: {
      range: {
        min: 0,
        max: 0,
      },
      minValue: 0,
      maxValue: 0,
    },
    yieldFilterRange: {
      range: {
        min: 0,
        max: 0,
      },
      minValue: 0,
      maxValue: 0,
    },
    debtUpsizedFilter: {
      canChangeResponse: false,
    },
  },
  firstLoginAt: null,
};

export const reducer = (state: IReducerState, action: any): IReducerState => {
  switch (action.type) {
    case actionTypes.GET_CROSS_DEAL_FILTERS:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.GET_CROSS_DEAL_FILTERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
        filter: {
          ...state.filter,
          ...action.payload.filter,
        },
      };
    case actionTypes.GET_CROSS_DEAL_FILTERS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.GET_ACCOUNT_FILTERS:
    case actionTypes.GET_MORE_ACCOUNT_FILTERS:
      return {
        ...state,
        isFetching: true,
        isAccountFetching: true,
      };
    case actionTypes.GET_SPONSOR_FILTERS:
    case actionTypes.GET_MORE_SPONSOR_FILTERS:
      return {
        ...state,
        isFetching: true,
        isSponsorFetching: true,
      };
    case actionTypes.GET_UNDERWRITER_FILTERS:
    case actionTypes.GET_MORE_UNDERWRITER_FILTERS:
      return {
        ...state,
        isFetching: true,
        isUnderwritersFetching: true,
      };
    case actionTypes.GET_ACCOUNT_FILTERS_ERROR:
    case actionTypes.GET_MORE_ACCOUNT_FILTERS_ERROR:
      return {
        ...state,
        isFetching: false,
        isAccountFetching: false,
      };
    case actionTypes.GET_SPONSOR_FILTERS_ERROR:
    case actionTypes.GET_MORE_SPONSOR_FILTERS_ERROR:
      return {
        ...state,
        isFetching: false,
        isSponsorFetching: false,
      };
    case actionTypes.GET_UNDERWRITER_FILTERS_ERROR:
    case actionTypes.GET_MORE_UNDERWRITER_FILTERS_ERROR:
      return {
        ...state,
        isFetching: false,
        isUnderwritersFetching: false,
      };
    case actionTypes.GET_ACCOUNT_FILTERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAccountFetching: false,
        filter: {
          ...state.filter,
          accountFilterList: action.payload,
        },
      };
    case actionTypes.GET_MORE_ACCOUNT_FILTERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAccountFetching: false,
        filter: {
          ...state.filter,
          accountFilterList: [
            ...state.filter.accountFilterList,
            ...action.payload,
          ],
        },
      };
    case actionTypes.GET_SPONSOR_FILTERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSponsorFetching: false,
        filter: {
          ...state.filter,
          sponsorFilterList: action.payload,
        },
      };
    case actionTypes.GET_MORE_SPONSOR_FILTERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSponsorFetching: false,
        filter: {
          ...state.filter,
          sponsorFilterList: [
            ...state.filter.sponsorFilterList,
            ...action.payload,
          ],
        },
      };
    case actionTypes.GET_UNDERWRITER_FILTERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isUnderwritersFetching: false,
        filter: {
          ...state.filter,
          underwriterFilterList: action.payload,
        },
      };
    case actionTypes.GET_MORE_UNDERWRITER_FILTERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isUnderwritersFetching: false,
        filter: {
          ...state.filter,
          underwriterFilterList: [
            ...state.filter.underwriterFilterList,
            ...action.payload,
          ],
        },
      };
    case actionTypes.CROSS_DEAL_FILTERS_PAYLOAD:
      return {
        ...state,
        filtersPayload: action.payload,
      };
    case actionTypes.CROSS_DEAL_FILTERS_APPLY:
      return {
        ...state,
        appliedFilters: action.payload,
      };
    case actionTypes.CROSS_DEAL_ALLOCATION_FILTERS_PAYLOAD:
      return {
        ...state,
        allocationFiltersPayload: action.payload,
      };
    default:
      return state;
  }
};
