// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types

const mimeTypeMapping = {
  '.csv': 'text/csv',
  '.txt': 'text/*',
  '.xls': 'application/vnd.ms-excel',
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.jpg': 'image/*',
  '.jpeg': 'image/*',
  '.png': 'image/*',
  '.svg': 'image/*',
  '.avi': 'video/*',
  '.flv': 'video/*',
  '.mkv': 'video/*',
  '.mov': 'video/*',
  '.mp4': 'video/*',
  '.wmv': 'video/*',
  '.pdf': 'application/pdf',
  '.mp3': 'audio/*',
};

const defaultMimeType = 'application/octet-stream';

const formatExtensionsByMimeType = (fileExtensionsString: string): {
  [key: string]: string[],
} | undefined => {
  const extensions = (fileExtensionsString ? fileExtensionsString.split(',') : null) || [];
  const mapping = extensions.reduce((result, extension) => {
    const mimeType = mimeTypeMapping[extension.trim()] || defaultMimeType;

    result[mimeType] = [
      ...(result[mimeType] || []),
      extension.trim(),
    ];

    return result;
  }, {});

  return Object.entries(mapping).length ? mapping : undefined;
};

export default formatExtensionsByMimeType;
