/**
 * Move an array element from one array position to another
 *
 * @param {Array} arr
 * @param {Number} oldIndex
 * @param {Number} newIndex
 * @return {Array}
 */
export default function moveElementInArray(arr, oldIndex, newIndex) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length;
    while ((k--) + 1) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
}
