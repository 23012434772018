import cn from 'classnames';
import { Field } from '@/ui/shared/components/Form/FinalForm';
import InputTags from '@/ui/shared/components/Form/InputTags';
import { useBulkAddDealManagersFormContext } from './context';

import styles from './bulkAddDealManagersModal.scss';
import { getPluralOrSingularLabel } from '@/Framework/dataHelpers/string/getPluralOrSingularLabel';

const normalizeChange = (value: string) => value.trim().replace(/[,;\s]\s?/g, ' ').split(' ');

const getMessageClass = (count: number, styles: Record<string, string>) => cn(styles.errorMessage, {
  [styles.show]: count > 0,
});

const DealManagersField = () => {
  const { countInvalidEmails, countExistEmails, onChangeDealManagers } = useBulkAddDealManagersFormContext();

  const invalidEmailsMessageClass = getMessageClass(countInvalidEmails, styles);
  const existEmailsMessageClass = getMessageClass(countExistEmails, styles);

  return (
    <Field name="dealManagers">
      { ({ input }) => (
        <div className={ styles.dealManagersFieldWrapper }>
          <InputTags
            dataTest="bulkAddDealManagersInputTags"
            tags={ input.value || [] }
            onChange={ (values) => onChangeDealManagers(values, input.onChange) }
            withErrors={ countInvalidEmails > 0 || countExistEmails > 0 }
            isNarrow
            normalizeChange={ normalizeChange }
          />
          <div className={ styles.errorMessagesWrapper }>
            <div className={ invalidEmailsMessageClass } data-test="bulkAddDealManagersInvalidEmailError">
              { getPluralOrSingularLabel(countInvalidEmails, 'Invalid emails entered', 'Invalid email entered') }
            </div>
            <div className={ existEmailsMessageClass } data-test="bulkAddDealManagersAlreadyExistEmailError">
              {
                getPluralOrSingularLabel(
                  countExistEmails,
                  'Users with the corporate emails are already in Deal Managers',
                  'User with the corporate email is already in Deal Managers',
                )
              }
            </div>
          </div>
        </div>
      ) }
    </Field>
  );
};

export default DealManagersField;
