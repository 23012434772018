import * as actionTypes from './actionTypes';

const initialState = {
  page: 1,
  perPage: 25,
  duplicateError: [],
  focusInputCode: {
    single: null,
    rotating: null,
    review: null,
  },
  isDialInCodeFetching: false,
  savingQueue: [],
};

export default function entryCodes(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ROADSHOW_ENTRY_CODE_DUPLICATE_VALIDATION_ERROR:
      return {
        ...state,
        duplicateError: action.payload,
      };
    case actionTypes.RESET_ROADSHOW_ENTRY_CODE_DUPLICATE_VALIDATION:
      return {
        ...state,
        duplicateError: [],
      };
    case actionTypes.SET_ROADSHOW_ENTRY_CODE_FOCUS_INPUT_CODE:
      return {
        ...state,
        focusInputCode: action.payload,
      };
    case actionTypes.CHANGE_ROADSHOW_ENTRY_CODE_ROTATING_PAGE:
      return {
        ...state,
        page: action.payload.page,
      };
    case actionTypes.CHANGE_ROADSHOW_ENTRY_CODE_ROTATING_ITEMS_PER_PAGE:
      return {
        ...state,
        perPage: action.payload.perPage,
        page: 1,
      };
    case actionTypes.SET_IS_DIAL_IN_CODE_FETCHING:
      return {
        ...state,
        isDialInCodeFetching: action.payload,
      };
    case actionTypes.ADD_ENTRY_CODE_TO_SAVING_QUEUE:
      return {
        ...state,
        savingQueue: state.savingQueue.concat(action.payload),
      };
    case actionTypes.REMOVE_ENTRY_CODE_FROM_SAVING_QUEUE:
      return {
        ...state,
        savingQueue: state.savingQueue.filter((id) => id !== action.payload),
      };
    case actionTypes.RESET_ROADSHOW_ENTRY_CODES_PAGINATION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
