export const filterEnhancedTaggingUnderwriters = (underwriters) => (
  underwriters
    .filter((underwriter) => {
      if (!Object.keys(underwriter).length) {
        return false;
      }
      return !!(underwriter.name || underwriter.lead);
    })
    .map((underwriter) => ({
      name: underwriter.name,
      lead: underwriter.lead || false,
    }))
);
