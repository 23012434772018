import subYears from 'date-fns/subYears';
import startOfDay from 'date-fns/startOfDay';
import isAfter from 'date-fns/isAfter';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import getZonedUTCDate from '@/Framework/DateTime/getZonedUTCDate';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import { GLOBAL_ANALYTICS_TIMEZONE } from '../config';
import parseDateString from '@/Framework/DateTime/parseDateString';

const getYearAgo = (timezone: string): Date => {
  const zonedTime = utcToZonedDate(timezone);
  const yearAgo = subYears(zonedTime, 1);
  const dayStartZoned = startOfDay(yearAgo);

  return getZonedUTCDate(timezone, dayStartZoned);
};

export const getDateFrom = (firstLoginAt: string | null): number => {
  const yearAgo = getYearAgo(GLOBAL_ANALYTICS_TIMEZONE);

  if (firstLoginAt) {
    const firstLoginAtDate = parseDateString(firstLoginAt, GLOBAL_ANALYTICS_TIMEZONE);

    if (isAfter(firstLoginAtDate, yearAgo)) {
      return getUnixTimestamp(firstLoginAtDate);
    }
  }

  return getUnixTimestamp(yearAgo);
};
