import * as actionTypes from '../data/actionTypes';

const initialState = {};

function addSubIndustryEntry(state, action) {
  const { payload } = action;
  let subindustryList = {};
  payload.forEach(
    (industry) => industry.subindustryList.forEach(
      (subIndustry) => {
        subindustryList[subIndustry.id] = {
          industryName: industry.name,
          subIndustryName: subIndustry.name,
          industryColor: industry.color,
          industryAbbreviation: industry.abbreviation,
        };
      },
    ),
  );

  return {
    ...state,
    ...subindustryList,
  };
}

export default function subIndustryById(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_INDUSTRY_HIERARCHY_LIST_SUCCESS:
      return addSubIndustryEntry(state, action);
    default:
      return state;
  }
}
