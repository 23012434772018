import React, { useState, useCallback } from 'react';
import AccountType from './AccountType';
import Accounts from './Accounts';
import ComparableLevel from './ComparableLevel';
import * as constants from '@/ui/shared/modules/Analytics/constants';
import { useInvestorTargetingContext } from '@/dealroadshow/application/dmPortal/analytics/InvestorTargetingContext';
import styles from '@/ui/shared/modules/Filters/FiltersPanel/sidebar.scss';

interface IProps {
  isZerocase: boolean,
  form: any,
  formValues: any,
  fieldComponent: React.ElementType,
}

function Sections({ isZerocase, form, formValues, fieldComponent: Field }: IProps) {
  const [hiddenSections, setHiddenSections] = useState([]);

  const { isVisibleDetails: showComparableFilter } = useInvestorTargetingContext();

  const handleSectionToggle = useCallback((activeKey) => {
    setHiddenSections((sections) => {
      if (sections.includes(activeKey)) {
        return sections.filter((section) => section !== activeKey);
      }

      return [...sections, activeKey];
    });
  }, []);

  if (isZerocase && !showComparableFilter) {
    return <div className={ styles.zerocase }>{ constants.ZEROCASE_LABEL }</div>;
  }

  return (
    <>
      { showComparableFilter && (
        <ComparableLevel
          // @ts-ignore
          form={ form }
          fieldComponent={ Field }
          sectionId="comparableLevel"
          sectionTitle="Comparable issuer level"
          isVisible={ !hiddenSections.includes('comparableLevel') }
          onSectionToggle={ handleSectionToggle }
          formValues={ {
            includeAccountIds: formValues?.includeAccountIds,
            includeInvestorIds: formValues?.includeInvestorIds,
          } }
        />
      ) }
      <AccountType
        form={ form }
        fieldComponent={ Field }
        sectionId="accountTypeList"
        sectionTitle="Account Type"
        isVisible={ !hiddenSections.includes('accountTypeList') }
        onSectionToggle={ handleSectionToggle }
        formValues={ formValues?.includeAccountTypeIds || [] }
      />
      <Accounts
        form={ form }
        fieldComponent={ Field }
        sectionId="accountsAndContactsList"
        sectionTitle="Accounts & Contacts"
        isVisible={ !hiddenSections.includes('accountsAndContactsList') }
        onSectionToggle={ handleSectionToggle }
        formValues={ {
          includeAccountIds: formValues?.includeAccountIds || [],
          includeInvestorIds: formValues?.includeInvestorIds || [],
        } }
      />
    </>
  );
}

export default Sections;
