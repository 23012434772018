import { connect } from 'react-redux';
import { UploadPreview } from '../components';
import * as constants from '../constants';
import { uploadDisclaimerBackgroundImage, uploadDisclaimerBackgroundCancel } from '../actions';

function mapStateToProps(state) {
  return {
    uploadFile: state.upload.disclaimerBackgroundImage,
    formFieldName: 'disclaimerBackgroundImage',
    uploadName: 'background image',
    allowedFileTypes: constants.UPLOAD_LOGO_ALLOWED_FILES,
    allowedFileText: constants.UPLOAD_BACKGROUND_ALLOWED_FILES_TEXT,
  };
}

const mapDispatchToProps = {
  onUpload: uploadDisclaimerBackgroundImage,
  onUploadCancel: uploadDisclaimerBackgroundCancel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadPreview);
