import { Dependencies } from 'constitute';
import { Subscription } from '@dealroadshow/socket-frontend-sdk';
import { Success } from '@dealroadshow/json-rpc-dispatcher';
import SocketClient from '@/dealroadshow/application/DI/Socket/Client';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import FileDownloadLinkGenerator from '@/dmPortal/application/DI/FileDownloadLinkGenerator/FileDownloadLinkGenerator';
import Request from '@/Framework/api/Rpc/Request';
import { IFetchCollectionPayload } from '@/Framework/State/useFetchCollection';
import { callbackWrapper } from '@/Framework/DI/Providers/Socket/helpers/callbackWrapper';

@Dependencies(RpcDispatcher, SocketClient, FileDownloadLinkGenerator)
class MeetingsRepository {
  private meetingsSubscribe;

  constructor(
    private rpc: typeof RpcDispatcher,
    private socket: typeof SocketClient,
    private fileDownloadLinkGenerator: typeof FileDownloadLinkGenerator,
  ) {}

  /**
   * Check does roadshow has meetings
   * @param {String} roadshowId
   * @returns {Promise<boolean>}
   */
  isRoadshowHasMeetings = async (roadshowId: string): Promise<boolean> => {
    let req = new Request('roadshow.request_meeting.is_roadshow_has_meeting_requests', { roadshowId });
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  };

  getMeetingsList = async (payload: IFetchCollectionPayload) => {
    const request = new Request('roadshow.request_meeting.get_meeting_requests', payload);
    const response = await this.rpc.call<Success>(request);

    return response.getResult().payload;
  };

  getQuestionCounter = async (roadshowId: string, search?: string) => {
    const request = new Request('roadshow.request_meeting.get_meeting_requests_counters', { roadshowId, search });
    const response = await this.rpc.call<Success>(request);

    return response.getResult().payload;
  };

  setDeclineMeeting = async (payload: { roadshowId: string, id: string, oldStatus: string }) => {
    const request = new Request('roadshow.request_meeting.decline_meeting_request', payload);
    const response = await this.rpc.call<Success>(request);

    return response.getResult().payload;
  };

  setScheduleMeeting = async (payload) => {
    const request = new Request('roadshow.request_meeting.schedule_meeting', payload);
    const response = await this.rpc.call<Success>(request);

    return response.getResult().payload;
  };

  addToCalendar = async (payload): Promise<string> => {
    const entityId = this.fileDownloadLinkGenerator.generateUniqueId();

    const request = new Request('roadshow.request_meeting.add_to_calendar', { ...payload, entityId });

    return this.fileDownloadLinkGenerator.getFileLink(request, entityId);
  };

  subscribeToMeetings = async ({
    roadshowId,
    ssid,
  }: { roadshowId: string, ssid: string }, {
    onNewMeetings,
    onChangeStatus,
  }: { onNewMeetings: () => void, onChangeStatus: (payload) => void }) => {
    this.meetingsSubscribe = await this.socket.subscribe(
      new Request('roadshow.request_meeting.manager.sub_unsub', { roadshowId }, { ssid }),
    );

    this.meetingsSubscribe.on('meeting.new', callbackWrapper(onNewMeetings));
    this.meetingsSubscribe.on('meeting.status_changed', callbackWrapper(onChangeStatus));
  };

  unsubscribeFromQuestions = () => {
    if (this.meetingsSubscribe instanceof Subscription) {
      return this.meetingsSubscribe.cancel();
    }
    return Promise.resolve();
  };
}

export default MeetingsRepository;
