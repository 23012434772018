import { v4 as uuid } from 'uuid';
import * as actionTypes from '../actionTypes';
import * as uploadTypes from '../uploadTypes';

const initialState = {
  initialFile: null,
  url: '',
  preview: {
    url: '',
  },
  process: {
    progress: 0,
    status: '',
    uuid: '',
  },
  uploadFile: null,
  callback: null,
  name: '',
  uploaded: false,
  canSave: true,
};

export default function logo(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPLOAD_LOGO_ADD:
      return {
        initialFile: null,
        url: '',
        preview: {
          url: '',
        },
        process: {
          progress: 0,
          status: uploadTypes.UPLOAD_START,
          uuid: action.payload.uuid,
        },
        uploadFile: action.payload.uploadFile,
        name: action.payload.uploadFile.name,
        callback: action.payload.callback,
        uploaded: false,
        canSave: false,
      };

    case actionTypes.UPLOAD_LOGO_ERROR:
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          process: {
            ...state.process,
            status: uploadTypes.UPLOAD_ERROR,
          },
          uploaded: false,
          canSave: false,
        };
      }
      return state;

    case actionTypes.UPLOAD_LOGO_CHUNK_SUCCESS:
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          process: {
            ...state.process,
            progress: parseInt(action.payload.progress),
            status: uploadTypes.UPLOAD_CHUNK_SUCCESS,
            uuid: action.payload.uuid,
          },
        };
      }
      return state;
    case actionTypes.UPLOAD_LOGO_SUCCESS:
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          process: {
            ...state.process,
            status: uploadTypes.UPLOAD_SUCCESS,
          },
          uploaded: true,
        };
      }
      return state;

    case actionTypes.UPLOAD_LOGO_PROCESSING:
      if (state.process.uuid === action.payload.uuid &&
          state.process.status !== uploadTypes.UPLOAD_DONE) {
        return {
          ...state,
          process: {
            ...state.process,
            progress: 0,
            status: uploadTypes.UPLOAD_PROCESSING_START,
          },
        };
      }
      return state;

    case actionTypes.UPLOAD_LOGO_DONE:
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          url: `/${ action.payload.data[0] }`,
          preview: {
            url: `/${ action.payload.data[0].replace('/logo', '/preview/logo') }`,
          },
          process: {
            ...state.process,
            progress: 100,
            status: uploadTypes.UPLOAD_DONE,
          },
          uploaded: true,
          canSave: true,
        };
      }
      return state;

    case actionTypes.UPLOAD_LOGO_CANCEL_SUCCESS:
      if (state.process.uuid === action.payload.uuid) {
        return initialState;
      }
      return state;

    case actionTypes.UPLOAD_CLEAR:
      return initialState;

    case actionTypes.UPLOAD_LOGO_INIT:
      return {
        initialFile: action.payload,
        preview: {
          url: action.payload.url.replace('/logo', '/preview/logo'),
        },
        process: {
          progress: 100,
          status: uploadTypes.UPLOAD_DONE,
          uuid: uuid(),
        },
        uploadFile: null,
        url: action.payload.url,
        callback: null,
        name: '',
        uploaded: true,
        canSave: true,
      };

    default:
      return state;
  }
}
