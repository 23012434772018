import { useCallback, useRef } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import {
  InvestorTargetingDetailsListSettingsPayload,
  InvestorTargetingGlobalFilterType,
  InvestorTargetingListFilterType,
  InvestorTargetingListPayload,
} from '@/dealroadshow/domain/vo/dmPortal/analytics/InvestorTargeting';
import InvestorTargetingRepository from '@/dealroadshow/infrastructure/repository/InvestorTargetingRepository';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { useInvestorTargetingPdf } from './useInvestorTargetingPdf';

interface IProps {
  roadshowId: string,
  globalFilters: InvestorTargetingGlobalFilterType,
  localFilters: InvestorTargetingListFilterType,
}

export const useInvestorTargetingDetailsList = ({ roadshowId, globalFilters, localFilters }: IProps) => {
  const { container } = useDIContext();
  const investorTargetingRepository = container.get<InvestorTargetingRepository>(InvestorTargetingRepository);

  const listPayload = useRef<InvestorTargetingListPayload>(null);

  const getTargetingList = useCallback(
    ({ page, perPage, sortBy, sortOrder, search }: InvestorTargetingDetailsListSettingsPayload) => {
      const payload: InvestorTargetingListPayload = {
        roadshowId,
        page,
        perPage,
        filter: localFilters,
        search,
        ...(sortBy && sortOrder && { sortBy, sortOrder }),
        ...globalFilters,
      };

      listPayload.current = payload;

      return investorTargetingRepository.getTargetingList(payload);
    },
    [roadshowId, localFilters, globalFilters],
  );

  const removeAccountFromInvestorTargetingList = useCallback(
    async (accountId: number) => {
      try {
        await investorTargetingRepository.removeAccountFromTargetingList({
          roadshowId,
          accountId,
        });
      } catch (e) {
        NotificationManager.error(getErrorMessage(e));
        throw e;
      }
    },
    [roadshowId],
  );

  return {
    removeAccountFromInvestorTargetingList,
    getTargetingList,
    ...useInvestorTargetingPdf(listPayload),
  };
};
