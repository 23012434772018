import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import isObject from 'lodash/isObject';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import uniq from 'lodash/uniq';
import { getCheckboxChildren } from '@/ui/shared/modules/Filters/Checkboxer/actions';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import { filterDealsBy } from '@/dmPortal/ui/components/GlobalAnalytics/common/Sidebar/Sections/Deals/constants';

// TODO: rewrite function parentIds and childrenIds are not be null
export function mergeCheckedIdsToParent(
  checkboxesState: { [key: string]: any },
  filtersState: { [key: string]: any },
  checkboxesStateId: string,
  filtersStateId: string,
) {
  let childrenIds = [];
  let parentIds = [];
  if ((filtersStateId in filtersState) && checkboxesStateId in checkboxesState) {
    const filtersByParent = groupBy(filtersState[filtersStateId]
      .filter((id) => !checkboxesState[id]?.disabled), (id) => checkboxesState[id]?.parent);
    let checkboxChildren = getCheckboxChildren(checkboxesState, checkboxesStateId);
    checkboxChildren = Object.keys(checkboxChildren)
      .filter((id) => checkboxChildren[id].parent !== checkboxesStateId);
    const checkboxesByParent = groupBy(checkboxChildren, (id) => checkboxesState[id]?.parent);
    parentIds = Object.keys(filtersByParent)
      .filter((parent) => {
        if (parent in filtersByParent && parent in checkboxesByParent) {
          return filtersByParent[parent].length === checkboxesByParent[parent].length;
        }
        return false;
      });
    childrenIds = filtersState[filtersStateId]
      .filter((id) => !checkboxesState[id].disabled)
      .filter((id) => !parentIds.includes(checkboxesState[id].parent));
  }
  return { parentIds, childrenIds };
}

function isShowDates(dateFrom: number, dateTo: number, dateFromMin: number = null): boolean {
  if (dateFrom && dateFromMin) {
    return isAfter(getDateFromUnixTime(dateFrom), getDateFromUnixTime(dateFromMin)) ||
           isBefore(dateTo || getUnixTimestamp(), getUnixTimestamp());
  }
  return false;
}

export function getFiltersByKeysWithValues(
  filtersState: { [key: string]: any },
  filtersKeys: Array<string>,
  dateFromMin: number = null,
  timeZone = null,
): { [key: string]: any } {
  let newState = { ...filtersState };
  if (Object.keys(newState).length && (filtersKeys && filtersKeys.length)) {
    return filtersKeys.filter((key) => (key in newState) && newState[key])
      .reduce((obj: { [key: string]: any }, key) => {
        const isStatusDefault = (key === 'includedStatus' && newState[key] === 'all');
        if (isStatusDefault) {
          delete newState.includedStatus;
        }
        const isCountryIds = (key === 'includedCountryIds') && !isEmpty(newState[key]);
        const isDates = isShowDates(newState.dateFrom || dateFromMin, newState.dateTo, dateFromMin);

        if (isCountryIds) {
          newState.includedRegionIds = uniq([...newState.includedRegionIds, ...newState[key]]);
          delete newState.includedCountryIds;
        }

        const isRange = isObject(newState[key]) && ('range' in newState[key]);
        const isRangeChanged =
          isRange &&
          (('min' in newState[key].range) &&
          ('max' in newState[key].range)) &&
          (newState[key].range.min !== newState[key].minValue || newState[key].range.max !== newState[key].maxValue);
        const isTimeRange = isObject(newState[key]) && ('tradeTime' in newState[key]);
        const { tradeTime, minValue, maxValue } = newState[key] || {};
        const isTimeRangeChanged =
          isTimeRange &&
          (('min' in tradeTime) &&
          ('max' in tradeTime)) &&
          (tradeTime.min !== minValue
           || tradeTime.max !== maxValue
          );

        const dealsKeys = Object.values(filterDealsBy);
        const isDeals = (dealsKeys.includes(key)) && !isEmpty(newState[key]);

        return {
          ...obj,
          ...(!isEmpty(newState[key]) && !isRange && !isDeals) && { [key]: newState[key] },
          ...(isRangeChanged) && { [key]: newState[key] },
          ...(isTimeRangeChanged) && { [key]: newState[key] },
          ...(isCountryIds) && { includedRegionIds: newState.includedRegionIds },
          ...(isDeals) && { includeDealIds: [...(obj.includeDealIds || []), ...newState[key]] },
          ...(isBoolean(newState[key])) && { [key]: newState[key] },
          ...(isDates) && { dates: {
              dateFrom: newState.dateFrom || dateFromMin,
              dateTo: newState.dateTo || getUnixTimestamp(),
              timeZone,
            } },
        };
      }, {});
  }
  return {};
}

export function getIndeterminateState(children, formValues): { [key: string | number]: any } {
  let activeChildren = children.filter((child) => !child?.disabled);
  let checkedChildren = children.filter((child) => formValues?.includes(child?.value));
  let activeCheckedChildren = checkedChildren.filter((child) => !child?.disabled);

  return {
    checked: (activeChildren.length > 0)
      ? (activeChildren.length === activeCheckedChildren.length)
      : ((checkedChildren.length > 0) && children.length === checkedChildren.length),

    indeterminate: (activeChildren.length > 0)
      ? ((activeCheckedChildren.length > 0) && (activeChildren.length !== activeCheckedChildren.length))
      : ((checkedChildren.length > 0) && children.length !== checkedChildren.length),
  };
}
