import * as actionTypes from '../data/actionTypes';

const initialState = {};

function addSubSectorEntry(state, action) {
  const { payload } = action;
  let subsectorList = {};
  payload.forEach(
    (sector) => sector.subsectorList.forEach(
      (subSector) => {
        subsectorList[subSector.id] = {
          sectorName: sector.name,
          subSectorName: subSector.name,
          sectorColor: sector.color,
          sectorAbbreviation: sector.abbreviation,
        };
      },
    ),
  );
  return {
    ...state,
    ...subsectorList,
  };
}

export default function subSectorById(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ABS_SECTOR_HIERARCHY_LIST_SUCCESS:
      return addSubSectorEntry(state, action);
    default:
      return state;
  }
}
