import { v4 as uuid } from 'uuid';
import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: false,
  limit: 5000,
  initialCollection: [],
  data: [],
  page: 1,
  perPage: 25,
  totalCount: 0,
  errors: [],
  warnings: [],
  input: '',
};

export default function blacklist(state = initialState, action) {
  let blacklist = [];
  switch (action.type) {
    case actionTypes.REQUEST_BLACKLIST_USERS_LIST:
      if (action.payload.emails) {
        blacklist = state.data.slice();
        action.payload.emails.forEach((email) => {
          let isExist = state.data.filter((user) => user.email === email);
          if (!isExist.length) {
            blacklist.push({
              uuid: uuid(),
              email: email.toLowerCase(),
            });
          }
        });
      }
      return {
        ...state,
        initialCollection: blacklist,
        data: blacklist,
        isFetching: true,
      };
    case actionTypes.REQUEST_BLACKLIST_USERS_LIST_SUCCESS:
      if (action.payload.data) {
        blacklist = state.data.slice();
        blacklist = blacklist.map((user) => {
          let userPayload = action.payload.data.filter((userItem) => userItem.email === user.email);
          if (userPayload.length) {
            return {
              ...user,
              firstName: userPayload[0].firstName,
              lastName: userPayload[0].lastName,
              companyName: userPayload[0].company ? userPayload[0].company.name : '',
            };
          }
          return user;
        });
        return {
          ...state,
          initialCollection: blacklist,
          data: blacklist,
          isFetching: false,
        };
      }
      return state;
    case actionTypes.REQUEST_BLACKLIST_USERS_LIST_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.BLACKLIST_DOMAINS_ADD:
      blacklist = state.data.slice();
      action.payload.domains.forEach((domain) => {
        blacklist.push({
          uuid: uuid(),
          domain: domain.toLowerCase(),
        });
      });
      return {
        ...state,
        initialCollection: blacklist,
        data: blacklist,
      };
    case actionTypes.BLACKLIST_USERS_AND_DOMAINS_INIT:
      blacklist = action.payload.blacklist.map((blacklist) => {
        let blacklistNew = {
          ...blacklist,
          uuid: uuid(),
        };
        if (blacklist.emailUserName) {
          blacklistNew = {
            ...blacklistNew,
            email: `${ blacklist.emailUserName }@${ blacklist.domain }`,
          };
        } else {
          blacklistNew = {
            ...blacklistNew,
            domain: blacklist.domain,
          };
        }
        return blacklistNew;
      });
      return {
        ...state,
        initialCollection: blacklist,
        data: blacklist,
      };
    case actionTypes.BLACKLIST_REMOVE:
      blacklist = state.data.filter((blacklist) => blacklist.uuid !== action.payload.uuid);
      return {
        ...state,
        initialCollection: blacklist,
        data: blacklist,
      };
    case actionTypes.REQUEST_APPLICATION_BLACKLIST:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_APPLICATION_BLACKLIST_SUCCESS:
    case actionTypes.REQUEST_APPLICATION_BLACKLIST_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.REQUEST_APPLICATION_BLACKLIST_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case actionTypes.BLACKLIST_VALIDATION_ERROR_AND_WARNING:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.BLACKLIST_CHANGE_INPUT:
      return {
        ...state,
        input: action.payload,
      };
    case actionTypes.BLACKLIST_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload.page,
      };
    case actionTypes.BLACKLIST_ITEMS_PER_PAGE_CHANGE:
      return {
        ...state,
        perPage: action.payload.perPage,
        page: 1,
      };
    case actionTypes.BLACKLIST_RESET:
      return initialState;
    default:
      return state;
  }
}
