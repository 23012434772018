import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import { addStatelessApplications } from '@/dmPortal/infrastructure/repository/helpers';

@Dependencies(RpcDispatcher)
class ApplicationRepository {
  constructor(rpcDispatcher) {
    /** @type {Dispatcher} */
    this.rpc = rpcDispatcher;
  }

  async getApplications() {
    let req = new Request(
      'dm_portal.queries.application.get_list',
    );
    let response = await this.rpc.request(req);
    let collection = response.getResult().payload;

    // addStatelessApplications() - Adding 2 cards to the Application page. Hardcode.
    // TODO: In the future: delete or transfer to the backend
    addStatelessApplications(collection);

    return collection;
  }

  async getApplication(tenant) {
    let req = new Request('dm_portal.queries.application.get_application', { tenant });
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }

  async enableApplication(tenant) {
    let req = new Request('dm_portal.application.enable', { tenant });
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }

  async disableApplication(tenant) {
    let req = new Request('dm_portal.application.disable', { tenant });
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }
}

export default ApplicationRepository;
