import { change } from 'redux-form';
import * as roadshowFormConstants from '../constants';

/**
 * @param {number} rowIndex
 * @param {Boolean} payload
 */
export function setCustomDisclaimer(rowIndex, payload) {
  return (dispatch) => {
    dispatch(change(roadshowFormConstants.ROADSHOW_FORM_NAME, `uploadDocumentsList[${ rowIndex }].customDisclaimer`, payload));
  };
}

/**
 * @param {number} rowIndex
 * @param {string} payload
 */
export function setDisclaimer(rowIndex, payload) {
  return (dispatch) => {
    dispatch(change(roadshowFormConstants.ROADSHOW_FORM_NAME, `uploadDocumentsList[${ rowIndex }].disclaimer`, payload));
  };
}

/**
 * @param {number} rowIndex
 * @param {string} payload
 */
export function setViewOnly(rowIndex, payload) {
  return (dispatch) => {
    dispatch(change(roadshowFormConstants.ROADSHOW_FORM_NAME, `uploadDocumentsList[${ rowIndex }].viewOnly`, payload));
  };
}
