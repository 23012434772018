import React, { useMemo } from 'react';
import SharedFilterGroupItem from '@/ui/shared/modules/Filters/components/FilterGroupItem';
import { useFiltersContext } from '@/dealroadshow/application/dmPortal/analytics/FiltersContext';

interface IProps {
  filterByChildrenName: string,
  filterByName: string,
  formValues: any,
  value: any,
  form: any,
  fieldComponent: any,
}

const FilterGroupItem = ({ value, formValues, filterByChildrenName, filterByName, form, fieldComponent }: IProps) => {
  const { investors, accounts } = useFiltersContext();

  const investorById = investors?.byId;
  const accountById = accounts?.byId[value];
  const isDisabled = !accountById.canChangeResponse;
  const nestedIds = accountById?.investorsIds;
  const groupTitle = accountById?.accountTitle;

  const { children, activeChildrenCount, checkedChildrenCount } = useMemo(() => {
    const children = nestedIds.map((investorId) => ({
      value: investorId,
      label: investorById[investorId].contactTitle
        ? investorById[investorId].contactTitle
        : investorById[investorId].contactEmail,
      disabled: !investorById[investorId].canChangeResponse,
    }));
    const activeChildrenCount = children.filter((child) => !child.disabled).length;
    const checkedChildrenCount = children.filter(
      (child) => !child.disabled && formValues[filterByChildrenName].includes(child.value),
    ).length;

    return {
      children,
      activeChildrenCount,
      checkedChildrenCount,
    };
  }, [nestedIds, investorById, formValues, filterByChildrenName]);

  return (
    <SharedFilterGroupItem
      groupTitle={ groupTitle }
      isDisabled={ isDisabled }
      nestedIds={ nestedIds }
      supportIndeterminate={ !!children.length }
      activeChildrenCount={ activeChildrenCount }
      checkedChildrenCount={ checkedChildrenCount }
      filterByName={ filterByName }
      form={ form }
      fieldComponent={ fieldComponent }
      formValues={ formValues }
      filterByChildrenName={ filterByChildrenName }
      value={ value }
    >
      { children }
    </SharedFilterGroupItem>
  );
};

export default FilterGroupItem;
