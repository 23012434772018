import React from 'react';
import cn from 'classnames';
import { Icon, IconType } from '@dealroadshow/uikit';
import { IInputTag } from '@/ui/shared/components/Form/InputTags/InputTags';

import styles from './tagsList.scss';

interface IProps {
  tags: IInputTag[],
  isDisabled: boolean,
  onRemoveTag: (index: number) => void,
}

const TagsList = ({
  tags,
  isDisabled,
  onRemoveTag,
}: IProps) => tags.map(({ value, isValid, customIcon: CustomIcon }, index) => {
  return (
    <span
      key={ value }
      className={ cn(styles.tagWrapper, {
        [styles.isNotValid]: !isValid,
      }) }
    >
      { CustomIcon && (<CustomIcon />) }
      { value }
      { !isDisabled && (
        <span
          className={ styles.removeTagIconWrapper }
          onClick={ () => onRemoveTag(index) }
        >
          <Icon
            type={ IconType.cancel }
            className={ styles.removeTagIcon }
          />
        </span>
      ) }
    </span>
  );
});

export default TagsList;
