export const tenantLabels = {
  allocate: 'Allocate',
  dealroadshow: 'Deal Roadshow',
  evercall: 'Evercall',
  dealvdr: 'DealVDR',
  investorset: 'InvestorSet',
  verisend: 'VeriSend',
  '17g5': '17g5.com',
};

export const filterDealsBy = {
  allocate: 'includeAllocationIds',
  dealroadshow: 'includeRoadshowIds',
  evercall: 'includeEvercallIds',
  dealvdr: 'includeDealvdrIds',
  investorset: 'includeInvestorSetIds',
  verisend: 'includeVeriSendIds',
  '17g5': 'include17g5Ids',
};

export const ITEMS_PER_PAGE = 20;
