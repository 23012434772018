import { useRef, useEffect } from 'react';

/**
 * WARNING! Try to avoid usage of this hook
 *
 * Save previous value to compare with the next one
 * @param {any} value - undefined after the first call
 * @return {any}
 */
const usePrevious = <T extends any>(value: T): T => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export default usePrevious;
