import React from 'react';
import { FixedSizeList as List, FixedSizeListProps, ListChildComponentProps } from 'react-window';

export interface IChildrenProps<T> extends ListChildComponentProps<T> {}

interface IProps<T> {
  data: T[],
  itemKey: keyof T | ((item: T) => string | number),
  children: React.ComponentType<IChildrenProps<T>>,
  width?: FixedSizeListProps<T>['width'],
  height: FixedSizeListProps<T>['height'],
  itemSize: FixedSizeListProps<T>['itemSize'],
  overscanCount?: FixedSizeListProps<T>['overscanCount'],
}

const VirtualList = <T extends Record<string, any>>(props: IProps<T>, ref: React.ForwardedRef<List<T>>) => {
  const {
    data,
    itemKey,
    width = '100%',
    height,
    itemSize,
    overscanCount = 0,
    children: Children,
  } = props;

  const getItemKey = (item: T) => {
    if (typeof itemKey === 'function') {
      return itemKey(item);
    }

    return item[itemKey];
  };

  return (
    <List
      ref={ ref }
      itemCount={ data.length }
      itemSize={ itemSize }
      width={ width }
      height={ height }
      overscanCount={ overscanCount }
      itemKey={ (index) => getItemKey(data[index]) }
    >
      { ({ index, ...restProps }) => (
        <Children
          { ...restProps }
          index={ index }
          data={ data[index] }
        />
      ) }
    </List>
  );
};

const ForwardedRefVirtualList = React.forwardRef(VirtualList) as <T extends Record<string, any>>(
  props: IProps<T> & { ref?: React.ForwardedRef<List<T>> }
) => React.JSX.Element;

export default ForwardedRefVirtualList;
