import range from 'lodash/range';
import {
  dateCellCallback,
  defaultOptionalCellCallback,
} from '@/allocate/ui/components/shared/Tables/cellCallbacks';
import {
  bookrunnersCellCallback,
  securityTypeCellCallback,
  subscriptionLevelCellCallback,
  totalProceedsCellCallback, totalSharesAtLaunchCellCallback,
} from '@/allocate/ui/components/shared/Tables/SecurityDetails/SecurityDetailsHeader/cellCallbacks';
import { IColumn } from '@dealroadshow/uikit';
import { AllocationsTypes } from '@/allocate/application/config';
import { IHeaderColumnsConfig } from '@/allocate/ui/components/shared/Tables/SecurityDetails/SecurityDetailsHeader/interfaces';
import cellStyles from '@/allocate/ui/components/shared/Tables/SecurityDetails/SecurityDetailsHeader/cellStyles.scss';

const columns = ({
   hasOrders,
   hasUnderwriters,
   rowsCount,
   totalProceeds,
 }: IHeaderColumnsConfig): IColumn[] => ([
  {
    name: 'issuerEntityName',
    title: 'Issuer Entity',
    tooltipContent: 'Issuer Entity Name',
    ...(hasUnderwriters && { width: 240 }),
  },
  {
    name: 'underwriters',
    title: 'Bookrunners',
    tooltipContent: 'Bookrunners',
    minWidth: 140,
    className: cellStyles.bookrunnersCell,
    isVisible: hasUnderwriters,
    rowSpan: rowsCount,
    hiddenRows: range(1, rowsCount),
    cellCallback: (cellProps) => bookrunnersCellCallback(cellProps, rowsCount),
  },
  {
    name: 'totalProceeds',
    title: 'Total Proceeds',
    tooltipContent: 'Total Proceeds',
    width: 140,
    cellCallback: (cellProps) => totalProceedsCellCallback(cellProps, rowsCount, totalProceeds),
  },
  {
    name: 'sharesAtLaunch',
    title: 'I@L (Shares)',
    tooltipContent: 'Indication at Launch (Shares)',
    width: 140,
    isVisible: hasOrders,
    cellCallback: totalSharesAtLaunchCellCallback,
  },
  {
    name: 'subscriptionLevel',
    title: 'SUBX',
    width: 80,
    tooltipContent: 'Subscription Level',
    isVisible: hasOrders,
    cellCallback: subscriptionLevelCellCallback,
  },
  {
    name: 'issuerEntityTicker',
    title: 'Ticker',
    tooltipContent: 'Issuer Ticker',
    width: 80,
    cellCallback: defaultOptionalCellCallback,
  },
  {
    name: 'issuePricingDate',
    title: 'Issue / Pricing Date',
    tooltipContent: 'Issue or Pricing Date',
    width: 140,
    cellCallback: dateCellCallback,
  },
  {
    name: 'securityType',
    title: 'Security Type',
    tooltipContent: 'Security Type Details',
    width: 140,
    cellCallback: (cellProps) => securityTypeCellCallback(cellProps, AllocationsTypes.EQUITY),
  },
]);

export default columns;
