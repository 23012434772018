import { change } from 'redux-form';
import DocumentsRepository from '@/dealroadshow/infrastructure/repository/DocumentsRepository';
import * as dealSettingsFormConstants
  from '@/condor/ui/components/DealRoadshows/Roadshow/modules/DealSettings/constants';
import * as actionTypes from '../actionTypes';
import { IUploadProcessing, ProcessingEvent, UploadEvent } from '@dealroadshow/file-uploader';

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadStart(payload) {
  return {
    type: actionTypes.UPLOAD_VIEWER_CUSTOM_DOCUMENT_ADD,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadChunkSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_VIEWER_CUSTOM_DOCUMENT_CHUNK_SUCCESS,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadError(payload) {
  return {
    type: actionTypes.UPLOAD_VIEWER_CUSTOM_DOCUMENT_ERROR,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_VIEWER_CUSTOM_DOCUMENT_SUCCESS,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadDone(payload) {
  return {
    type: actionTypes.UPLOAD_VIEWER_CUSTOM_DOCUMENT_DONE,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadProcessing(payload) {
  return {
    type: actionTypes.UPLOAD_VIEWER_CUSTOM_DOCUMENT_PROCESSING,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadCancelSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_VIEWER_CUSTOM_DOCUMENT_CANCEL_SUCCESS,
    payload,
  };
}

/**
 * @param {String} uuid
 */
export function uploadCustomViewerDocumentCancel(uuid) {
  return async (dispatch, getState) => {
    dispatch({
      type: actionTypes.UPLOAD_VIEWER_CUSTOM_DOCUMENT_CANCEL,
      payload: {
        uuid,
      },
    });

    if (getState().upload.viewerCustomFiles.files[uuid]
        && getState().upload.viewerCustomFiles.files[uuid].callback
        && typeof getState().upload.viewerCustomFiles.files[uuid].callback.cancel === 'function') {
      getState().upload.viewerCustomFiles.files[uuid].callback.cancel();
    }
    if (getState().upload.viewerCustomFiles.files[uuid]) {
      dispatch(uploadCancelSuccess({
        uuid: getState().upload.viewerCustomFiles.files[uuid].process.uuid,
      }));
    }
  };
}

/**
 * @param {Number} oldIndex
 * @param {Number} newIndex
 */
export function uploadCustomViewerDocumentOrder(oldIndex, newIndex) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_VIEWER_CUSTOM_DOCUMENT_ORDER,
      payload: {
        oldIndex,
        newIndex,
      },
    });
  };
}

/**
 * @param {Array.<File>} files
 */
export function uploadCustomViewerDocuments(files) {
  return async (dispatch, getState) => {
    const documentsRepository = getState().container.get(DocumentsRepository);
    await Promise.all(files.sort((prev, next) => prev.size - next.size).map(async (file) => {
      let upload: IUploadProcessing = await documentsRepository.upload(file);
      dispatch(uploadStart({
        uploadFile: file,
        callback: upload,
        uuid: upload.getUuid(),
      }));

      upload
        .on(UploadEvent.uploadChunkDone, (data) => dispatch(uploadChunkSuccess(data)))
        .on(UploadEvent.error, (data) => dispatch(uploadError(data)))
        .on(UploadEvent.uploadDone, (data) => dispatch(uploadSuccess(data)))
        .on(ProcessingEvent.processing, (data) => dispatch(uploadProcessing(data)))
        .on(ProcessingEvent.processingDone, (data) => dispatch(uploadDone(data)))
        .on(UploadEvent.cancel, (data) => dispatch(uploadCancelSuccess(data)));
    }));
  };
}

/**
 * @param {Array} payload
 */
export function initUploadCustomViewerDocuments(payload) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_VIEWER_CUSTOM_DOCUMENT_INIT,
      payload,
    });
  };
}

/**
 * @param {number} rowIndex
 * @param {Boolean} payload
 */
export function setCustomViewerCustomDisclaimer(rowIndex, payload) {
  return (dispatch) => {
    dispatch(change(dealSettingsFormConstants.DEAL_SETTINGS_FORM_NAME, `viewerCustomFiles[${ rowIndex }].customDisclaimer`, payload));
  };
}

/**
 * @param {number} rowIndex
 * @param {string} payload
 */
export function setCustomViewerDisclaimer(rowIndex, payload) {
  return (dispatch) => {
    dispatch(change(dealSettingsFormConstants.DEAL_SETTINGS_FORM_NAME, `viewerCustomFiles[${ rowIndex }].disclaimer`, payload));
  };
}

/**
 * @param {number} rowIndex
 * @param {string} payload
 */
export function setViewOnly(rowIndex, payload) {
  return (dispatch) => {
    dispatch(change(dealSettingsFormConstants.DEAL_SETTINGS_FORM_NAME, `viewerCustomFiles[${ rowIndex }].viewOnly`, payload));
  };
}
