export const GET_WHITELIST_RESENT_WELCOME = 'GET_WHITELIST_RESENT_WELCOME';
export const GET_WHITELIST_RESENT_WELCOME_SUCCESS = 'GET_WHITELIST_RESENT_WELCOME_SUCCESS';
export const GET_WHITELIST_RESENT_WELCOME_ERROR = 'GET_WHITELIST_RESENT_WELCOME_ERROR';

export const GET_WHITELIST_USERS_LIST = 'GET_WHITELIST_USERS_LIST';
export const GET_WHITELIST_USERS_LIST_SUCCESS = 'GET_WHITELIST_USERS_LIST_SUCCESS';
export const GET_WHITELIST_USERS_LIST_ERROR = 'GET_WHITELIST_USERS_LIST_ERROR';

export const WHITELIST_SORT_CHANGE = 'WHITELIST_SORT_CHANGE';
export const WHITELIST_PAGE_CHANGE = 'WHITELIST_PAGE_CHANGE';
export const WHITELIST_ITEMS_PER_PAGE_CHANGE = 'WHITELIST_ITEMS_PER_PAGE_CHANGE';

export const WHITELIST_RESET = 'WHITELIST_RESET';
