import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: false,
  limit: 5000,
  sortBy: 'name',
  sortOrder: '',
  page: 1,
  perPage: 25,
};

export default function whitelist(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_WHITELIST_USERS_LIST:
    case actionTypes.GET_WHITELIST_RESENT_WELCOME:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.GET_WHITELIST_USERS_LIST_SUCCESS:
    case actionTypes.GET_WHITELIST_USERS_LIST_ERROR:
    case actionTypes.GET_WHITELIST_RESENT_WELCOME_SUCCESS:
    case actionTypes.GET_WHITELIST_RESENT_WELCOME_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.WHITELIST_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload.page,
      };
    case actionTypes.WHITELIST_ITEMS_PER_PAGE_CHANGE:
      return {
        ...state,
        perPage: action.payload.perPage,
        page: 1,
      };
    case actionTypes.WHITELIST_SORT_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.WHITELIST_RESET:
      return initialState;
    default:
      return state;
  }
}
