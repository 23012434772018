import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import { withCache } from '@/Framework/withCache';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';

@Dependencies(RpcDispatcher)
class CurrencyRepository {
  constructor(rpcDispatcher) {
    /** @type {Dispatcher} */
    this.rpc = rpcDispatcher;
  }

  @withCache
  async getCurrencies() {
    let req = new Request('dm_portal.queries.currency.get_currencies');

    let response = await this.rpc.request(req);
    return response.getResult().payload;
  }
}

export default CurrencyRepository;
