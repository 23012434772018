import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, Field } from 'redux-form';
import * as dealSettingsFormConstants
  from '@/condor/ui/components/DealRoadshows/Roadshow/modules/DealSettings/constants';
import { UploadTable } from '../components';
import * as constants from '../constants';
import {
  uploadCustomViewerDocuments,
  uploadCustomViewerDocumentCancel,
  uploadCustomViewerDocumentOrder,
  setCustomViewerCustomDisclaimer,
  setCustomViewerDisclaimer,
  setViewOnly,
} from '../actions';

const selector = formValueSelector(dealSettingsFormConstants.DEAL_SETTINGS_FORM_NAME);

function mapStateToProps(state) {
  return {
    fieldComponent: Field,
    uploadFiles: state.upload.viewerCustomFiles,
    uploadList: selector(state, 'viewerCustomFiles'),
    formFieldName: 'viewerCustomFiles',
    columns: constants.UPLOAD_TABLE_COLUMNS,
    widths: constants.UPLOAD_TABLE_WIDTHS,
    supportsDisclaimer: true,
    allowedFileTypes: constants.UPLOAD_DOCUMENTS_ALLOWED_FILES,
    allowedFileText: constants.UPLOAD_DOCUMENTS_ALLOWED_FILES_TEXT,
    uploadName: 'viewer custom deal files',
    limit: 20,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onUpload: uploadCustomViewerDocuments,
    onUploadCancel: uploadCustomViewerDocumentCancel,
    onUploadOrder: uploadCustomViewerDocumentOrder,
    setCustomDisclaimer: setCustomViewerCustomDisclaimer,
    setDisclaimer: setCustomViewerDisclaimer,
    setViewOnly,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadTable);
