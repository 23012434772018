const compositeSortOptions = [
  'ordersAtWhisperSum-',
  'ordersAtGuidanceSum-',
  'ordersAtLaunchSum-',
  'allocationsSum-',
  'ordersAtLaunchFill-',
  'allocationsSumPercentage-',
  'percentageOfClass-',
];

const shouldDivideSortBy = (sortBy: string): boolean => compositeSortOptions.some(
  (option) => sortBy.includes(option),
);

export default shouldDivideSortBy;
