import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UploadPreview } from '../components';
import * as constants from '../constants';
import { uploadPresentation, uploadPresentationCancel } from '../actions';

function mapStateToProps(state) {
  return {
    uploadFile: state.upload.presentation,
    formFieldName: 'presentation',
    allowedFileTypes: constants.UPLOAD_PRESENTATION_ALLOWED_FILES,
    allowedFileText: constants.UPLOAD_PRESENTATION_ALLOWED_FILES_TEXT,
    uploadName: 'Presentation',
    canRemove: !state.upload.presentation.canSave,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onUpload: uploadPresentation,
    onUploadCancel: uploadPresentationCancel,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadPreview);
