import base from '@/Framework/url/baseUrl';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';

export const FINSIGHT_CARD = {
  coverImgUrl: base.getApiUrl('files/o/application-files/finsight_cover.png'),
  description: 'A new issue deal database and search engine for global fixed income securities across ABS, IG and HY corporates. Browse by region, industry, sector, sponsor, and issuer. Highly accessible (mobile friendly) and intuitively organized.',
  iconUrl: base.getApiUrl('files/o/application-files/finsight_icon.svg'),
  learnMoreUrl: finsightWebUrl.getUrl(),
  name: 'Finsight',
  tenant: 'finsight',
  isStateless: true,
};
