import React from 'react';
import cn from 'classnames';
import { Tooltip, Label } from '@dealroadshow/uikit';
import formatNumber from '@/Framework/dataHelpers/formatters/formatNumber';
import { replaceCurrencyWithSymbol } from '@/allocate/ui/helpers/currencySymbols';
import { formatSubscriptionLevel } from '@/allocate/ui/components/shared/Tables/helpers';
import { AllocationsTypes, ASSET_BACKED_SECURITY_DEBT_ID } from '@/allocate/application/config';
import { transactionTypeDisplayNames } from '@/dealroadshow/domain/TransactionTypes';
import sortUnderwriters from '@/allocate/ui/helpers/sortUnderwriters';
import styles from '@/allocate/ui/components/shared/Tables/SecurityDetails/SecurityDetailsHeader/cellStyles.scss';

export const bookrunnersCellCallback = (cellProps, rowsCount) => {
  const { cellData } = cellProps;

  if (cellData) {
    const isMultiLined = rowsCount > 1;
    const rowHeight = 18;
    const bookrunners = cellData.sort(sortUnderwriters).map((underwriter) => underwriter.name).join(', ');

    return (
      <Tooltip
        content={ bookrunners }
        containerClassName={ cn(styles.bookrunnersCellContent, {
          [styles.multiLined]: isMultiLined,
        }) }
        containerStyles={ isMultiLined ? {
          WebkitLineClamp: rowsCount,
          display: '-webkit-box',
          maxHeight: rowHeight * rowsCount,
        } : null }
      >
        { bookrunners }
      </Tooltip>
    );
  }

  return '';
};

export const totalOriginalFaceCellCallback = (cellProps) => {
  if (cellProps.cellData) {
    const { currencyDisplayName, totalOriginalFaceValue } = cellProps.cellData;

    return formatNumber(totalOriginalFaceValue, {
      symbol: currencyDisplayName,
      symbolOnLeft: true,
      spaceBetweenValueAndSymbol: true,
    });
  }
  return '-';
};

export const totalProceedsCellCallback = (cellProps, rowsCount, totalProceeds) => {
  const { cellData, rowIndex } = cellProps;

  if (cellData) {
    const { currencyDisplayName, totalProceedsValue } = cellData;
    const isLastRow = rowIndex === rowsCount - 1;
    const value = formatNumber(totalProceedsValue, {
      symbol: replaceCurrencyWithSymbol(currencyDisplayName),
      symbolOnLeft: true,
      spaceBetweenValueAndSymbol: true,
    });

    const tooltipContent = totalProceeds.map((row, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={ index } className={ styles.totalProceedsTooltipItem }>
        <span>{ row.currencyDisplayName }</span>
        { formatNumber(row.totalProceedsValue) }
      </div>
    ));

    if (isLastRow) {
      return (
        <Tooltip
          content={ tooltipContent }
        >
          { value }
        </Tooltip>
      );
    }

    return value;
  }

  return '-';
};

export const ordersAtLaunchCellCallback = (cellProps) => {
  if (cellProps.cellData) {
    const { currencyDisplayName, orderAtLaunchActualValue } = cellProps.cellData;

    return orderAtLaunchActualValue ? formatNumber(orderAtLaunchActualValue, {
      symbol: currencyDisplayName,
      symbolOnLeft: true,
      spaceBetweenValueAndSymbol: true,
    }) : '-';
  }

  return '-';
};

export const totalSharesAtLaunchCellCallback = (cellProps) => {
  const { cellData, cellName, row } = cellProps;

  if (cellData || cellData === 0) {
    return formatNumber(cellData);
  }

  if (cellName in row) {
    return '-';
  }

  return '';
};

export const subscriptionLevelCellCallback = (cellProps) => {
  const { cellData, cellName, row } = cellProps;

  if (cellName in row) {
    if (cellData) {
      let { subscriptionLevelValue } = cellData;
      return subscriptionLevelValue
        ? formatSubscriptionLevel(subscriptionLevelValue)
        : formatSubscriptionLevel(cellData);
    }

    return '-';
  }

  return '';
};

export const securityTypeCellCallback = (cellProps, allocationsType) => {
  const { cellData, cellName, row } = cellProps;
  if (!(cellName in row)) return '';
  const { debtType, equityType, sector, industry, transactionTypeId } = cellData;

  if (allocationsType === AllocationsTypes.DEBT) {
    if (debtType.id === ASSET_BACKED_SECURITY_DEBT_ID && sector) {
      const {
        name,
        color,
        abbreviation,
        subsectorList,
      } = sector;

      return (
        <div className={ styles.securityCell }>
          { debtType.abbreviation }
          <Tooltip content={ `${ name }: ${ subsectorList[0].name }` }>
            <Label
              className={ styles.label }
              backgroundColor={ color }
            >
              { abbreviation }
            </Label>
          </Tooltip>
        </div>
      );
    }

    if (debtType.id !== ASSET_BACKED_SECURITY_DEBT_ID && industry) {
      const {
        name,
        color,
        abbreviation,
        subindustryList,
      } = industry;

      return (
        <div className={ styles.securityCell }>
          { debtType.abbreviation }
          <Tooltip content={ `${ name }: ${ subindustryList[0].name }` }>
            <Label
              className={ styles.label }
              backgroundColor={ color }
            >
              { abbreviation }
            </Label>
          </Tooltip>
        </div>
      );
    }
  }

  if (allocationsType === AllocationsTypes.EQUITY && industry) {
    const {
      name,
      color,
      abbreviation,
      subindustryList,
    } = industry;

    return (
      <div className={ styles.securityCell }>
        { equityType.abbreviation }
        <Tooltip content={ `${ name }: ${ subindustryList[0].name }` }>
          <Label
            className={ styles.label }
            backgroundColor={ color }
          >
            { abbreviation }
          </Label>
        </Tooltip>
      </div>
    );
  }

  if (transactionTypeId) {
    return transactionTypeDisplayNames[transactionTypeId];
  }

  return '-';
};
