export const transactionTypes = {
  Convertible: '00143fb3-89f8-45f4-a7ad-b743359fbce9',
  BondHighYield: 'cfd5d541-6009-4c1d-b7d0-0c9fe12d9ac7',
  BondInvestmentGrade: 'd8b760fd-6c47-480b-bf42-718fe386ffc6',
  BondPrivatePlacement: 'bf7c5ea9-4dc3-471b-a4f6-75199f51d3d9',
  BondSecuritization: 'bcd17fba-f480-4298-b70e-68c1a2c85e91',
  NonDealRoadshow: 'b30e3448-a2b9-4c3a-abd5-9ce81e1061f7',
  OtherExcludedAnalytics: 'd349e34b-1ef8-4d7d-97c6-9e97386ddb4d',
  Other: '9b21a1d6-4f03-4a4b-bb7f-a1b6a681e5a3',
  OtherTestingTheWaters: '6ad6bd91-c32c-4a57-9976-19940c55095e',
  BondMunicipal: '149286b9-23e9-4232-8575-407c5534a449',
  EquityEarlyStage: '4679d496-7969-440e-9b35-01798043ade6',
  EquityPilotFishing: 'b4c24d81-e337-45dd-8eba-b94c446ed6c0',
  EquityWallCross: 'f1382607-36a1-41d0-a51b-fbb195cdd101',
  EquityFollowOn: 'c439f4f8-d20f-4a05-857c-39f58530bb0f',
  EquityGDR: '5d776500-c969-4e11-a570-7ffee22791a1',
  EquityIPO: 'fc7c7d9b-d434-4e99-8e52-5eb6841cd54b',
  EquityPDIE: 'dc90474f-45c3-4d73-b7a6-f22d19f566b2',
  EquityPrivate: 'f8151518-3843-4172-a69f-f2b0d926fc43',
  EquitySPAC: '8d3c6006-f377-4076-85a5-670467f3526c',
  LeveragedLoans: '4e6233b0-c1a6-44e6-900e-63887a082a49',
  SyndicatedLoan: '05d2b9cb-0541-4d16-84c1-db8d11505f32',
  MaCimTeaserDistribution: 'acce5923-b3f8-441e-b3e4-5b901e35ccc5',
  MaSiteTourVideoSiteTourVide: '1167bd38-5d43-4e86-a9a5-03f97744c5b6',
  MaFiresideChatManagementPresentation: 'a73b8758-eed8-4b21-ae1a-eb67a4b61703',
  MaOther: '03059a12-6814-46dc-be78-362a4ab7f3ed',
} as const;

export type TransactionType = (typeof transactionTypes)[keyof typeof transactionTypes];

/**
 * Get transaction type display name
 */
export const transactionTypeDisplayNames: Record<TransactionType, string> = {
  [transactionTypes.Convertible]: 'Convertible',
  [transactionTypes.BondHighYield]: 'High Yield',
  [transactionTypes.BondInvestmentGrade]: 'Investment Grade',
  [transactionTypes.BondPrivatePlacement]: 'Private Placement',
  [transactionTypes.BondSecuritization]: 'Securitization',
  [transactionTypes.NonDealRoadshow]: 'Non - Deal Roadshow',
  [transactionTypes.OtherExcludedAnalytics]: 'Other (Excluded from Analytics)',
  [transactionTypes.Other]: 'Other',
  [transactionTypes.OtherTestingTheWaters]: 'Testing the Waters',
  [transactionTypes.BondMunicipal]: 'Municipal',
  [transactionTypes.EquityEarlyStage]: 'Equity - Early Stage',
  [transactionTypes.EquityPilotFishing]: 'Equity - Pilot Fishing',
  [transactionTypes.EquityWallCross]: 'Equity - Wall Cross',
  [transactionTypes.EquityFollowOn]: 'Follow-On',
  [transactionTypes.EquityGDR]: 'Equity-GDR',
  [transactionTypes.EquityIPO]: 'Initial Public Offering',
  [transactionTypes.EquityPrivate]: 'Private',
  [transactionTypes.SyndicatedLoan]: 'Syndicated Loan',
  [transactionTypes.LeveragedLoans]: 'Leveraged Loans',
  [transactionTypes.EquitySPAC]: 'Special-Purpose Acquisition Company',
  [transactionTypes.EquityPDIE]: 'Pre-Deal Investor Education',
  [transactionTypes.MaCimTeaserDistribution]: 'CIM/Teaser Distribution',
  [transactionTypes.MaSiteTourVideoSiteTourVide]: 'SiteTour Video',
  [transactionTypes.MaFiresideChatManagementPresentation]: 'Fireside Chat/Management Presentation',
  [transactionTypes.MaOther]: 'M&A - Other',
};

export const isSectorAvailableForTransactionType = (transactionTypeId: string): boolean => {
  const sectorsAvailableForTransactionTypes: TransactionType[] = [
    transactionTypes.BondSecuritization,
    transactionTypes.NonDealRoadshow,
  ];

  return sectorsAvailableForTransactionTypes.includes(transactionTypeId as TransactionType);
};
