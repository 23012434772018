import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: false,
  collection: [],
};

export default function data(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ABS_SECTOR_HIERARCHY_LIST:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.GET_ABS_SECTOR_HIERARCHY_LIST_SUCCESS:
      return {
        ...state,
        collection: action.payload,
        isFetching: false,
      };
    case actionTypes.GET_ABS_SECTOR_HIERARCHY_LIST_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
