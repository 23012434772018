import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import FilterSection from '@/ui/shared/modules/Filters/components/FilterSection';
import FilterColumns from '@/ui/shared/modules/Filters/components/FilterColumns';
import { Checkbox } from '@dealroadshow/uikit';
import { getIndeterminateState } from '@/ui/shared/modules/Filters/helpers';
import FilterGroupItem from './FilterGroupItem';

const filterByName = 'includeTransactionTypeTenants';
const filterByChildrenName = 'includeTransactionTypeIds';

interface IProps {
  fieldComponent: React.ElementType,
  form: any,
  formValues: any,
  sectionId: string,
  sectionTitle: string,
  isVisible: boolean,
  onSectionToggle: (section: string) => void,
}

function TransactionType({
  form,
  formValues,
  fieldComponent: Field,
  sectionId,
  sectionTitle,
  isVisible,
  onSectionToggle,
}: IProps) {
  const { transactionTypeFilterList } = useGlobalAnalyticsFiltersContext();

  const [
    children,
    activeTransactionTypeIds,
  ] = useMemo(
    () => [
      transactionTypeFilterList?.allIds.map((transactionTypeId) => ({
        value: transactionTypeId,
        label: transactionTypeFilterList?.byId[`${ transactionTypeId }`].transactionTypeName,
        disabled: !transactionTypeFilterList?.byId[`${ transactionTypeId }`].canChangeResponse,
      })),
      transactionTypeFilterList?.allIds
        .filter((transactionTypeId) => transactionTypeFilterList?.byId[transactionTypeId].canChangeResponse),
    ],
    [transactionTypeFilterList?.allIds],
  );

  const transactionTypeIdsUnchecked = useMemo(
    () => formValues[filterByChildrenName]?.filter(
      (transactionTypeId) => !activeTransactionTypeIds.includes(transactionTypeId),
    ),
    [transactionTypeFilterList?.allIds],
  );

  const indeterminateState = useMemo(
    () => getIndeterminateState(
      children,
      formValues[filterByChildrenName]),
    [children, formValues]);

  const headerComponent = useMemo(() => {
    const handleFilterSectionChange = (event) => {
      const { checked } = event.target;
      if (checked) {
        form.change(filterByChildrenName, [...formValues[filterByChildrenName], ...activeTransactionTypeIds]);
      } else {
        form.change(filterByChildrenName, transactionTypeIdsUnchecked);
      }
    };
    return (
      <Checkbox
        name={ sectionId }
        label={ sectionTitle }
        supportIndeterminate
        onChange={ handleFilterSectionChange }
        disabled={ activeTransactionTypeIds.length === 0 }
        dataTest={ `${ sectionTitle }Checkbox` }
        { ...indeterminateState }
      />
    );
  }, [form, transactionTypeFilterList?.allIds, indeterminateState]);

  if (isEmpty(transactionTypeFilterList?.allIds)) {
    return null;
  }

  return (
    <FilterSection
      sectionId={ sectionId }
      headerComponent={ headerComponent }
      isVisible={ isVisible }
      onSectionToggle={ onSectionToggle }
    >
      <FilterColumns>
        { Object.entries(transactionTypeFilterList?.byTenant).map(([tenant, childrenIds]) => (
          <FilterGroupItem
            key={ tenant }
            form={ form }
            fieldComponent={ Field }
            formValues={ formValues }
            value={ tenant }
            filterByName={ filterByName }
            filterByChildrenName={ filterByChildrenName }
            childrenIds={ childrenIds }
          />
        )) }
      </FilterColumns>
    </FilterSection>
  );
}

export default TransactionType;
