import { Dependencies } from 'constitute';
import UploadDispatcher from '@/dmPortal/application/DI/FileUpload/UploadDispatcher';
import { getUnixTimestampInMilliseconds } from '@/Framework/DateTime/getUnixTimestamp';

@Dependencies(UploadDispatcher)
class SlideshowRepository {
  /**
   * @param {Dispatcher} uploadDispatcher
   */
  constructor(uploadDispatcher) {
    this.uploadDispatcher = uploadDispatcher;
  }

  /**
   * Upload presentation to backend
   *
   * @param {File} file
   * @return {Upload}
   */
  upload(file) {
    return this.uploadDispatcher.upload(file, { type: 'ROADSHOW_SLIDESHOW' });
  }

  /**
   * Upload presentation to backend
   *
   * @param {File} file
   * @return {Upload}
   */
  uploadSlide(file) {
    return this.uploadDispatcher.upload(file, { type: 'ROADSHOW_SLIDE' });
  }

  /**
   * Get slide svg content
   *
   * @param {string} slideUrl
   *
   * @return {Promise.<*>}
   */
  async getSlide(slideUrl, addTimestamp = false) {
    let slideContent;

    let requestParams = {
      credentials: 'include',
      headers: {
        'X-Content-Transfer-Encoding': 'base64',
      },
    };
    let timestamp = '';

    if (addTimestamp) {
      timestamp = `?t=${ getUnixTimestampInMilliseconds() }`;
    }

    let response = await fetch(`${ slideUrl }${ timestamp }`, requestParams);

    if (response.status >= 400) {
      throw response;
    }

    slideContent = await response.text();

    return slideContent;
  }
}

export default SlideshowRepository;
