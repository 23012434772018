import { useEffect, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import useRouter from '@/ui/shared/hooks/useNextRouter';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { DRS_REMAP_URL, getDrsAnalyticsUrl, getDrsUploadUrl, steps } from '@/allocate/application/Allocations/Upload/config';
import AllocationsAnalyticsRepository from '@/allocate/infrastructure/repository/AllocationsAnalyticsRepository';

const useDrsAllocations = () => {
  const { asPath: pathname, query: { roadshowId: id }, push } = useRouter();
  const roadshowId = id.toString();
  const { container } = useDIContext();
  const allocationsAnalyticsRepository = container.get<AllocationsAnalyticsRepository>(AllocationsAnalyticsRepository);

  const [isFetching, setIsFetching] = useState(true);

  const checkAnalytics = async () => {
    const isRemap = pathname.includes(DRS_REMAP_URL);

    try {
      const details = await allocationsAnalyticsRepository.getDealAllocationDetailsByRoadshowId({ roadshowId });

      if (details?.isUploaded && !isRemap) {
        await push(getDrsAnalyticsUrl(roadshowId));
      } else if (!details?.isUploaded) {
        await push(getDrsUploadUrl(steps.uploadExcel, roadshowId));
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    checkAnalytics();
  }, []);

  return {
    isFetching,
  };
};

export default useDrsAllocations;
