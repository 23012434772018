import { useState, useEffect } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import AllocationsAnalyticsRepository from '@/allocate/infrastructure/repository/AllocationsAnalyticsRepository';
import BlacklistRepository from '@/dealroadshow/infrastructure/repository/BlacklistRepository';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';

const usePermissions = () => {
  const { container } = useDIContext();
  const allocationsAnalyticsRepository = container.get<AllocationsAnalyticsRepository>(AllocationsAnalyticsRepository);
  const roadshowRepository = container.get<RoadshowRepository>(RoadshowRepository);
  const blacklistRepository = container.get<BlacklistRepository>(BlacklistRepository);

  const [isAdminAnalyticsOnly, setIsAdminAnalyticsOnly] = useState(false);
  const [isAdminBlacklisted, setIsAdminBlacklisted] = useState(false);
  const [isAdminBlacklistedFetched, setIsAdminBlacklistedFetched] = useState(false);

  /**
   * Check if admin is blacklisted
   */
  const checkIsAdminBlacklisted = async (): Promise<void> => {
    try {
      const response = await blacklistRepository.getApplicationIsBlacklisted();
      setIsAdminBlacklisted(response);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsAdminBlacklistedFetched(true);
    }
  };

  /**
   * Check if admin have analytics only permissions by dealAllocationId
   */
  const checkIsAdminAnalyticsOnlyByDealAllocationId = async (dealAllocationId: string): Promise<boolean> => {
    try {
      const response = await allocationsAnalyticsRepository.isAdminAnalyticsOnlyByDealAllocationId({
        dealAllocationId,
      });
      setIsAdminAnalyticsOnly(response);
      return response;
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
      return true;
    }
  };

  /**
   * Check if admin have analytics only permissions by roadshowId
   */
  const checkIsAdminAnalyticsOnlyByRoadshowId = async (roadshowId: string): Promise<boolean> => {
    try {
      const response = await roadshowRepository.isAdminAnalyticsOnlyByRoadshowId({ roadshowId });
      setIsAdminAnalyticsOnly(response);
      return response;
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
      return true;
    }
  };

  useEffect(() => {
    checkIsAdminBlacklisted();
  }, []);

  return {
    isAdminAnalyticsOnly,
    isAdminBlacklisted,
    isAdminBlacklistedFetched,
    checkIsAdminBlacklisted,
    checkIsAdminAnalyticsOnlyByDealAllocationId,
    checkIsAdminAnalyticsOnlyByRoadshowId,
    setIsAdminAnalyticsOnly,
  };
};

export default usePermissions;
