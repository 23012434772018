import Rows from './Rows';
import { UNDERWRITERS_FIELD_NAME } from './constants';
import styles from './underwriters.scss';

export interface ITitles {
  name?: string,
  lead?: string,
  label?: string,
  placeholder?: string,
}

interface IProps {
  fieldArrayComponent: React.ComponentType<any>,
  fieldComponent: React.ComponentType,
  companies: object,
  rowClassName?: string,
  wrapper?: string,
  disabled?: boolean,
  modalCall?: boolean,
  modalCallText?: string,
  modalShow?: (payload: any) => void,
  titles?: ITitles,
  clearOnBlur?: boolean,
  onSuggestionSelected?: (val: string, fieldName: string) => void,
}

const Underwriters = (
  {
    titles,
    fieldArrayComponent: FieldArray,
    fieldComponent: Field,
    companies,
    rowClassName,
    wrapper,
    disabled,
    modalCall,
    modalCallText,
    modalShow,
    clearOnBlur,
    onSuggestionSelected,
  }: IProps,
) => (
  <div className={ wrapper } data-test="underwriters">
    <div className={ styles.head }>
      <div className={ styles.underwriters }>
        { titles?.name || 'Underwriter / Advisor' }
      </div>
      <div className={ styles.lead }>
        { titles?.lead || 'Lead' }
      </div>
    </div>
    <FieldArray
      name={ UNDERWRITERS_FIELD_NAME }
    >
      { ({ fields }) => (
        <Rows
          fields={ fields }
          fieldComponent={ Field }
          companies={ companies }
          rowClassName={ rowClassName }
          disabled={ disabled }
          label={ titles?.label }
          placeholder={ titles?.placeholder }
          modalCall={ modalCall }
          modalCallText={ modalCallText }
          modalShow={ modalShow }
          clearOnBlur={ clearOnBlur }
          onSuggestionSelected={ onSuggestionSelected }
        />
      ) }
    </FieldArray>
  </div>
);

export default Underwriters;
