import React, { useEffect } from 'react';
import useRouter from '@/ui/shared/hooks/useNextRouter';
import DataroomTwoFactorAuthenticationContext, {
  useDataroomTwoFactorAuthenticationContext,
} from '@/dataroom/application/DataroomTwoFactorAuthenticationContext';
import {
  DataroomTwoFactorAuthenticationModal,
} from '@/dataroom/ui/components/dmPortal/DataroomTwoFactorAuthentication';

interface IProps {
  children?: React.ReactNode,
}

const DataroomTwoFactorAuthenticationAccessLayer = ({ children }: IProps) => {
  const { replace, asPath, query: { q, tenant } } = useRouter();

  const searchParams = new URLSearchParams(q?.toString());

  const showTwoFactor = searchParams.get('showTwoFactor');
  const dataroomName = searchParams.get('dataroomName');
  const dataroomUrl = searchParams.get('dataroomUrl');

  const {
    twoFactorAccessError,
    isModalOpen,
    setModalData,
    resetModalData,
    setTwoFactorAccessError,
    isSessionTwoFactorVerified,
  } = useDataroomTwoFactorAuthenticationContext();

  useEffect(() => {
    const isModalOpen = twoFactorAccessError || (
      showTwoFactor &&
      dataroomName &&
      dataroomUrl &&
      !isSessionTwoFactorVerified
    );

    const onSuccessCallback = () => {
      resetModalData();
      setTwoFactorAccessError(false);

      if (dataroomUrl) {
        window.location.href = dataroomUrl;
      }
    };

    const onCloseCallback = () => {
      replace((dataroomName && dataroomUrl) ? asPath : `/${ tenant }`);
      resetModalData();
      setTwoFactorAccessError(false);
    };

    setModalData({
      isModalOpen,
      entityName: dataroomName,
      onSuccessCallback,
      onCloseCallback,
    });
  }, [
    twoFactorAccessError,
    showTwoFactor,
    dataroomName,
    dataroomUrl,
    isSessionTwoFactorVerified,
  ]);

  return (
    <>
      { twoFactorAccessError ? null : children }
      { isModalOpen && <DataroomTwoFactorAuthenticationModal /> }
    </>
  );
};

export default (props) => (
  <DataroomTwoFactorAuthenticationContext>
    <DataroomTwoFactorAuthenticationAccessLayer { ...props } />
  </DataroomTwoFactorAuthenticationContext>
);
