import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import RenderRangeField from '../RenderRangeField';
import { normalizeMinValue, normalizeMaxValue } from '../normalizers';
import styles from '../../allocations.scss';

interface IProps {
  fieldComponent: React.ElementType,
  onChangeRange: (filterByName: string, initialValue: any) => void,
}

const filterByName = 'spreadSizeRange';

const Spread = ({
  fieldComponent: Field,
  onChangeRange,
}: IProps) => {
  const { spreadFilterRange, allocationFiltersPayload } = useGlobalAnalyticsFiltersContext();

  useEffect(() => {
    // @ts-ignore
    if (!allocationFiltersPayload?.spreadFrom && !allocationFiltersPayload?.spreadTo) {
      onChangeRange(filterByName, normalizeValue);
    }
  }, [spreadFilterRange?.range]);

  if (isEmpty(spreadFilterRange?.range)) {
    return null;
  }

  const initialValue = {
    range: {
      min: spreadFilterRange?.range.min,
      max: spreadFilterRange?.range.max,
    },
    minValue: spreadFilterRange?.range.min,
    maxValue: spreadFilterRange?.range.max,
  };

  const normalizeValue = {
    range: {
      min: normalizeMinValue(spreadFilterRange?.range.min),
      max: normalizeMaxValue(spreadFilterRange?.range.max),
    },
    minValue: normalizeMinValue(spreadFilterRange?.range.min),
    maxValue: normalizeMaxValue(spreadFilterRange?.range.max),
  };

  return (
    <>
      <div className={ styles.fieldLabel }>Pricing SPREAD (bps)</div>
      <Field
        name={ filterByName }
        disabled={ !spreadFilterRange.range.canChangeResponse }
        render={ RenderRangeField }
        minValue={ normalizeMinValue(initialValue.minValue) }
        maxValue={ normalizeMaxValue(initialValue.maxValue) }
        step={ 1 }
        parse={ (value) => ({
          range: {
            min: value?.min,
            max: value?.max,
          },
          minValue: value.minValue,
          maxValue: value.maxValue,
        }) }
      />
    </>
  );
};

export default Spread;
