import { useMemo } from 'react';
import { InvestorTargetingGlobalFilterType } from '@/dealroadshow/domain/vo/dmPortal/analytics/InvestorTargeting';
import { useFiltersContext, getIndeterminatedOrCheckedAccountsIds } from '../FiltersContext';

export const useInvestorTargetingGlobalFilters = ({ roadshowId }: { roadshowId: string }) => {
  const { appliedFilters, comparableLevel: comparableLevelOptions, investors, applyFilters } = useFiltersContext();

  const indeterminatedOrCheckedAccountsIds = useMemo(
    () => getIndeterminatedOrCheckedAccountsIds(appliedFilters, investors),
    [appliedFilters, investors],
  );
  const comparableLevel = appliedFilters.comparableLevel as string | undefined;
  const includeAccountTypeIds = appliedFilters.includeAccountTypeIds as string[];
  const comparableLevelName = comparableLevelOptions.find((option) => comparableLevel === option.value)?.name;
  const isSectorIndustryTypeSelected = comparableLevel === 'sector/industry';

  const clearAccountIds = () => {
    applyFilters(roadshowId, {
      ...appliedFilters,
      includeAccountIds: [],
      includeInvestorIds: [],
    });
  };

  const clearAccountTypeIds = () => {
    applyFilters(roadshowId, {
      ...appliedFilters,
      includeAccountTypeIds: [],
    });
  };

  const filters = useMemo<InvestorTargetingGlobalFilterType>(
    () => ({
      comparableLevel,
      includeAccountIds: indeterminatedOrCheckedAccountsIds,
      includeAccountTypeIds,
    }),
    [
      comparableLevel,
      // prevent filters object create to avoid useless fetching
      // it happends due to filters data and filters payload updating after any filters payload change
      JSON.stringify(indeterminatedOrCheckedAccountsIds),
      JSON.stringify(includeAccountTypeIds),
    ],
  );

  return {
    filters,
    clearAccountIds,
    clearAccountTypeIds,
    comparableLevelName,
    comparableLevel,
    isSectorIndustryTypeSelected,
  };
};
