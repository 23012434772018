import { connect } from 'react-redux';
import RoadshowAnalytics from './RoadshowAnalytics';
import { downloadExcel } from '@/dealroadshow/application/actions/analytics/downloadExcel/actions';
import { resetMetadata } from '@/dealroadshow/application/actions/analytics/metadata/actions';
import { resetRoadshowSettings, getApplicationIsBlacklisted } from '@/dealroadshow/application/actions/data/actions';

function mapStateToProps(state) {
  return {
    name: state.roadshow.analytics.metadata.name,
    isPublic: state.roadshow.analytics.metadata.isPublic,
    isMetadataFetching: state.roadshow.analytics.metadata.isFetching,
    isDownloadExcelFetching: state.roadshow.analytics.downloadExcel.isFetching,
    isAdminAnalyticsOnly: state.roadshow.analytics.metadata.isAdminAnalyticsOnly,
    isBlacklisted: state.roadshow.data.isBlacklisted,
    investorQuestionsEnabled: state.roadshow.data.itemSettings.investorQuestionsEnabled,
    requestMeetingEnabled: state.roadshow.data.itemSettings.requestMeetingEnabled,
  };
}

const mapDispatchToProps = {
  downloadExcel,
  resetMetadata,
  resetRoadshowSettings,
  getApplicationIsBlacklisted,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RoadshowAnalytics);
