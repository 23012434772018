import * as actionTypes from '../data/actionTypes';

const initialState = {};

function getAccountTypesName(state, action) {
  let collectionByName = {};
  action.payload.forEach((accountType) => {
    collectionByName[accountType.name] = accountType;
  });
  return collectionByName;
}

export default function byName(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ACCOUNT_TYPES_SUCCESS:
      return getAccountTypesName(state, action);
    default:
      return state;
  }
}
