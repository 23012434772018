import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import { withCache } from '@/Framework/withCache';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';

@Dependencies(RpcDispatcher)
class IndustryRepository {
  constructor(rpcDispatcher) {
    /** @type {Dispatcher} */
    this.rpc = rpcDispatcher;
  }

  @withCache
  async list() {
    let req = new Request('dm_portal.market.industry.get_industries');
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }
}

export default IndustryRepository;
