import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';

@Dependencies(RpcDispatcher)
class TransactionTypeRepository {
  constructor(rpcDispatcher) {
    /** @type {Dispatcher} */
    this.rpc = rpcDispatcher;
  }

  async getTransactionTypeList(tenant) {
    let req = new Request('dm_portal.market.transaction_type.get_list', {
      tenant,
    });

    let response = await this.rpc.request(req);
    return response.getResult().payload;
  }
}

export default TransactionTypeRepository;
