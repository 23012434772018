import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';

@Dependencies(JsonRpcDispatcherFactory)
class FollowRepository {
  constructor(private readonly rpc: typeof JsonRpcDispatcherFactory) {}

  follow = async (payload: { dataroomId: number }): Promise<void> => {
    const req = new Request('dataroom.restricted.follow', payload);
    await this.rpc().call<RpcSuccess>(req);
  };

  unfollow = async (payload: { dataroomId: number }): Promise<{ success: boolean }> => {
    const request = new Request('dataroom.unfollow', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult() as any;
  };
}

export default FollowRepository;
