import { useCallback } from 'react';
import InvestorTargetingRepository from '@/dealroadshow/infrastructure/repository/InvestorTargetingRepository';
import { useDIContext } from '@/Framework/DI/DIContext';

export const useInvestorTargetingTargetLists = ({
  roadshowId,
  comparableLevel,
}: {
  roadshowId: string,
  comparableLevel: string,
}) => {
  const { container } = useDIContext();

  const investorTargetingRepository = container.get<InvestorTargetingRepository>(InvestorTargetingRepository);

  const getAllocations = useCallback(
    () => investorTargetingRepository.getAllocations({
        roadshowId,
        comparableLevel,
      }),
    [roadshowId, comparableLevel],
  );

  const getRoadshows = useCallback(
    () => investorTargetingRepository.getRoadshows({
        roadshowId,
        comparableLevel,
      }),
    [roadshowId, comparableLevel],
  );

  return {
    getAllocations,
    getRoadshows,
  };
};
