import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as constants from '@/ui/shared/modules/upload/constants';
import { UploadOneFile } from '../components';
import { uploadPresentationCuePointsList, uploadPresentationCuePointsListCancel } from '../actions';

function mapStateToProps(state) {
  return {
    uploadFile: state.upload.presentationCuePoints,
    allowedFileTypes: constants.UPLOAD_PRESENTATION_CUE_POINTS_LIST_FILES,
    allowedFileText: constants.UPLOAD_PRESENTATION_CUE_POINTS_LIST_FILES_TEXT,
    formFieldName: 'cuePointsList',
    uploadName: 'CuePointsList',
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onUpload: uploadPresentationCuePointsList,
    onUploadCancel: uploadPresentationCuePointsListCancel,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadOneFile);
