import React from 'react';
import DebtTable from './DebtTable';
import EquityTable from './EquityTable';
import { IDebtSecurities, IEquitySecurities } from '@/allocate/domain/vo/Allocations/Analytics/SecutiryDetails';
import { AllocationsTypes } from '@/allocate/application/config';

interface IProps {
  type: AllocationsTypes,
  securityDetails: IDebtSecurities | IEquitySecurities,
}

export default ({ type, securityDetails }: IProps) => {
  if (type === AllocationsTypes.DEBT) {
    return <DebtTable securityDetails={ securityDetails as IDebtSecurities } />;
  }

  if (type === AllocationsTypes.EQUITY) {
    return <EquityTable securityDetails={ securityDetails as IEquitySecurities } />;
  }

  return null;
};
