export const ENHANCED_TAGGING_SECTION_NAME = 'enhancedTagging';
export const UNDERWRITERS_SECTION_NAME = 'underwriters';
export const SPONSOR_FIELD_NAME = 'sponsorName';
export const SUBINDUSTRY_FIELD_NAME = 'subindustryId';
export const SUBSECTOR_FIELD_NAME = 'subsectorId';
export const COUNTRY_FIELD_NAME = 'countryId';
export const DEALSIZE_FIELD_NAME = 'dealSize';
export const CURRENCY_FIELD_NAME = 'currencyId';

// Auto suggest modals
export const SUGGEST_COMPANY_NAME = 'Company';
export const SUGGEST_COMPANY_TITLE = 'Don’t see your Company?';
export const SUGGEST_COMPANY_TEXT = `FINSIGHT maintains a master list of unique institutional Companies to avoid
fragmentation and provide you with clean, consolidated analytics. If you don’t see an official FINSIGHT Company that we
can map your deal to, please suggest one here and we will promptly review and revert.`;
export const SUGGEST_COMPANY_LABEL = 'Suggested Company Name';
export const SUGGEST_UNDERWRITER_NAME = 'Underwriter';
export const SUGGEST_UNDERWRITER_TITLE = 'Don’t see your Underwriter?';
export const SUGGEST_UNDERWRITER_TEXT = `FINSIGHT maintains a master list of unique institutional Underwriters to avoid
fragmentation and provide you with clean, consolidated analytics. If you don’t see an official FINSIGHT Underwriter that
we can map your deal to, please suggest one here and we will promptly review and revert.`;
export const SUGGEST_UNDERWRITER_LABEL = 'Suggested Underwriter Name';
